import styled from "styled-components";
import { useTranslation } from "react-i18next";
import TitleLogo from "asset/photomag_web/img/icon/filled/insert-emoticon.svg";
import TextFields from "components/web/UI/TextFields";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import excelLogo from "asset/photomag_web/img/icon/insert-chart-outlined.svg"
import { useEffect, useState } from "react";
import { callApi } from "util/HttpService";
import * as ExcelUtil from 'util/ExcelUtil';
import SmtDtmSelectBox from "components/web/cmmn/SmtDtmSelectBox";
import { alert } from "components/web/cmmn/CmmnConfirm";

const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    input[type="checkbox"] {
        accent-color: #EF2929;
    }
    @media (max-width: 1600px) {
        .th, .tb {
            font-size: 14px !important;
        }
    }
    @media (min-width: 1601px) {
        .th, .tb {
            font-size: 16px !important;
        }
    }
    input[type="radio"] {
        accent-color: red;
    }
`;
const InputOptionWrap = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .wrap {
        display: flex;
        align-items: center;
        li {
            display: flex;
            align-items: center;
            h3 {
                font-size: 16px;
                color: #3b3b3b;
                font-weight: 500;
                min-width: 100px;
            }
            label {
                font-size: 16px;
                color: #3b3b3b;
                font-weight: 400;
                margin-left: 10px;
            }
            select {
                min-width: 236px;
            }
        }
    }
    & > ul {
        width: fit-content;
        display: flex;
        align-items: center;
    }
    .btn {
        max-width: 150px;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
`;
const MenuTitleWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    width: 100%;
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
    }
    .round-filled-small-green-btn:hover {
        color: var(--bs-btn-hover-color) !important;
        background-color: var(--bs-btn-hover-bg) !important;
        border-color: var(--bs-btn-hover-border-color) !important;
    }
`;
const TableWrap = styled.div`
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .round-filled-gray-btn:hover {
        color: var(--bs-btn-hover-color) !important;
    }
    .round-filled-gray-btn {
        font-size: 14px !important;
    }
    .tbody:hover .tb {
        background-color: #fafafa !important;
    }
    .tbody:active .tb, .tbody:focus .tb {
        background-color: #f5f5f5 !important;
    }
    .th {
        padding: 15px 0px !important;
    }
    .tb {
        padding: 15px 0px !important;           
    }
`;
export default function SmtMgt() {
    const defaultSearchData = {
        acmlYrmn: "",
        possCrdCnt: "",
    };

    const { t } = useTranslation();
    const brchClsfCd = localStorage.getItem("brchClsfCd");
    const locBrchId = localStorage.getItem("brchId");
    const [searchData, setSearchData] = useState(defaultSearchData);
    const [smtList, setSmtList] = useState([]);
    const [selectedData, setSelectedData] = useState({});

    const getSmtList = () => {
        let params;
        switch (brchClsfCd) {
            case "KSC":
                params = { ...searchData, brchClsfCd: brchClsfCd };
                break;
            case "FCC":
                params = { ...searchData, brchId: locBrchId, brchClsfCd: brchClsfCd };
                break;
            case "JMC":
                params = { ...searchData, brchId: locBrchId, brchClsfCd: brchClsfCd };
                break;
            default: break;
        }
        callApi("GET", params, "/web/api/svc-mgt/smt/list")
            .then(res => {
                console.log(res);
                setSmtList(res);
            })
            .catch(error => {
                console.log(error);
            })
    };

    useEffect(() => {
        getSmtList();
    }, []);

    const handleFormDataChange = (event) => {
        const { name, value } = event.target;
        if (name === "possCrdCnt") {
            const formattedValue = value.replace(/[^0-9]/g, '');
            setSearchData({ ...searchData, [name]: formattedValue });
        } else {
            setSearchData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const getFormattedValue = (value) => {
        if (!value) return '';
        return Number(value).toLocaleString();
    };

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnSearchBtn":
                getSmtList();
                break;
            case "cmmnExcelBtn":
                if (selectedData.brchId === "" || selectedData.acmlYrmn === "" || selectedData.natnCd === "") {
                    alert("", t("alert.smtDataSelect"), () => { });
                    return;
                }
                excelDownloadBtn();
                break;
            default: break;
        }
    };

    const excelDownloadBtn = async () => {
        // console.log(selectedData);
        const excelParams = { ...searchData, brchId: selectedData.brchId, acmlYrmn: selectedData.acmlYrmn, natnCd: selectedData.natnCd };
        ExcelUtil.downloadExcelFromServer(`/web/api/sal-hist/eph/excel-down`, excelParams, t("leftMenu.smtMgt"));
    };

    useEffect(() => {
        if (smtList.length > 0) {
            setSelectedData({
                idx: 0,
                brchId: smtList[0]?.brchId || "",
                acmlYrmn: smtList[0]?.acmlYrmn || "",
                natnCd: smtList[0]?.natnCd || ""
            });
        }
    }, [smtList]);

    const handleRadioChange = (idx, data) => {
        setSelectedData({
            idx: idx,
            brchId: data.brchId,
            acmlYrmn: data.acmlYrmn,
            natnCd: data.natnCd
        });
    };

    const getUnit = (natnCd) => {
        switch (natnCd) {
            case "KR": return "₩";
            case "JP": return "¥";
            case "EN": return "$";
            default: return "";
        }
    };

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title">
                    <div className="alignset">
                        <img src={TitleLogo} alt={t("leftMenu.smtMgt")} />
                        <h2>{t("leftMenu.smtMgt")}</h2>
                    </div>
                    <p>Home {">"} {t("leftMenu.svcMng")} {">"} {t("leftMenu.smtMgt")}</p>
                </div>
            </div>
            <DashBoardWrap>
                <div className="dashboard-box p-30">
                    <InputOptionWrap>
                        <ul>
                            <ul className="wrap">
                                <li className="mr-50">
                                    <h3>{t("smtMgtPage.acmlYrmn")}</h3>
                                    <SmtDtmSelectBox
                                        defaultVal={searchData.acmlYrmn}
                                        defaultOptTxt={t("smtMgtPage.entire")}
                                        tagName={"acmlYrmn"}
                                        onSelectChange={handleFormDataChange}
                                        isFirstSelect={false}
                                    />
                                </li>
                                <li>
                                    <h3>{t("smtMgtPage.creditHeld")}</h3>
                                    <TextFields
                                        type="text"
                                        name="possCrdCnt"
                                        value={getFormattedValue(searchData.possCrdCnt)}
                                        onChangeValue={handleFormDataChange}
                                    />
                                    <h3 style={{ marginLeft: "1rem" }}>{t("smtMgtPage.less")}</h3>
                                </li>
                            </ul>
                        </ul>
                        <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn"
                            value={t("common.searchLable")}
                            onClick={() => { handleCmmnBtnClick("cmmnSearchBtn") }}
                        />
                    </InputOptionWrap>
                </div>
                <div className="dashboard-box">
                    <MenuTitleWrap>
                        <h2>{t("brchMgtPage.total")} {smtList.length}{t("dashboardPage.occur")}</h2>
                        <ButtonUtil className="btn btn-success round-filled-small-green-btn excel-btn small-btn"
                            onClick={() => { handleCmmnBtnClick("cmmnExcelBtn") }}>
                            <img src={excelLogo} alt="excel_logo" />
                            {t("saleHistPage.excelDownload")}
                        </ButtonUtil>
                    </MenuTitleWrap>
                    <div className="p-30">
                        <TableWrap>
                            <div className="table scroll-wrap h-10">
                                <div className="thead">
                                    <div className="th w-05"></div>
                                    <div className="th w-075">{t("smtMgtPage.acmlYrmn")}</div>
                                    <div className="th w-175">{t("smtMgtPage.brch")}</div>
                                    <div className="th w-1">{t("smtMgtPage.filmingFee")}</div>
                                    <div className="th w-075">{t("smtMgtPage.numberOfPay")}</div>
                                    <div className="th w-075">{t("smtMgtPage.sale")}</div>
                                    <div className="th w-075">{t("smtMgtPage.totalFee")}</div>
                                    <div className="th w-1">{t("smtMgtPage.chargeCredit")}</div>
                                    <div className="th w-1">{t("smtMgtPage.useCredit")}</div>
                                    <div className="th w-1">{t("smtMgtPage.creditHeld")}</div>
                                    <div className="th w-075">{t("smtMgtPage.credit")}</div>
                                </div>
                                <div className="tbody-wrap">
                                    {smtList.map((data, idx) => (
                                        <div className="tbody" key={idx} onClick={() => handleRadioChange(idx, data)}>
                                            <div className="tb w-05">
                                                <input
                                                    type="radio"
                                                    name="selectedRadio"
                                                    checked={selectedData.idx === idx}
                                                    onChange={() => handleRadioChange(idx, data)}
                                                />
                                            </div>
                                            <div className="tb w-075">{data.acmlYrmn ? `${data.acmlYrmn.substring(0, 4)}-${data.acmlYrmn.substring(4, 6)}` : ''}</div>
                                            <div className="tb w-175">{data.brchNm}</div>
                                            <div className="tb w-1">{(data.plfmFee === null || data.plfmFee === undefined) ? '0' : `${getUnit(data.natnCd)}${data.plfmFee.toLocaleString()}`}</div>
                                            <div className="tb w-075">{(data.stlmCnt === null || data.stlmCnt === undefined) ? '0' : data.stlmCnt.toLocaleString()}</div>
                                            <div className="tb w-075">{(data.saleAmt === null || data.saleAmt === undefined) ? '0' : `${getUnit(data.natnCd)}${data.saleAmt.toLocaleString()}`}</div>
                                            <div className="tb w-075">{(data.totalFee === null || data.totalFee === undefined) ? '0' : `${getUnit(data.natnCd)}${data.totalFee.toLocaleString()}`}</div>
                                            <div className="tb w-1">{(data.flngCrdCnt === null || data.flngCrdCnt === undefined) ? '0' : data.flngCrdCnt.toLocaleString()}</div>
                                            <div className="tb w-1">{(data.useCrdCnt === null || data.useCrdCnt === undefined) ? '0' : data.useCrdCnt.toLocaleString()}</div>
                                            <div className="tb w-1"
                                                style={{
                                                    color: data.possCrdCnt <= 150 ? '#EF2929' : 'inherit',
                                                    fontWeight: data.possCrdCnt <= 150 ? 'bold' : 'normal'
                                                }}>
                                                {(data.possCrdCnt === null || data.possCrdCnt === undefined) ? '0' : data.possCrdCnt.toLocaleString()}
                                            </div>
                                            <div className="tb w-075">
                                                {data.possCrdCnt <= 150 &&
                                                    <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn"
                                                        value={t("brchMgtPage.charge")}
                                                        onClick={() => { }}
                                                        disabled={true}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </TableWrap>
                    </div>
                </div>
            </DashBoardWrap>

        </div>
    )

}