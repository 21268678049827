import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

const RadioBtnWrap = styled.ul`
    display: flex;
    align-items: center;
    li {
        display: flex;
        align-items: center;
        ${(props) => props.$isMobile && css`
            width: 50%
        `};
        &:first-child {
            margin-right: 20px;
        }
        label {
            font-size: 16px;
            color: ${({ theme }) => theme.colors.dark2};
            font-weight: 400;
            margin-left: 10px;
        }
        input[type="radio"] {
            accent-color: red;
        }
    }
`;
const ErrorMsg = styled.p`
    margin-left: 1rem;
    font-size: 0.9rem;
    color: #ef2929;
`;
function CmmnRadioBox(
    {
        className,
        errorClassName,
        dataArray,
        onSelectChange,
        disabled,
        defaultVal,
        tagName,
        id,
        isMobile,
        errorTxt,
    }
) {

    const [radioValue, setRadioValue] = useState(defaultVal || "");

    useEffect(() => {
        setRadioValue(defaultVal);
    }, [defaultVal]);

    const handleRadioClick = (e) => {
        const value = e.target.value;
        setRadioValue(value);
    };

    const handleRadioChange = (e) => {
        onSelectChange(e);
    };

    return (
        <>
        <RadioBtnWrap className={className} $isMobile={isMobile}>
            {dataArray.map((val, idx) => (
                <li key={idx}>
                    <input
                        type="radio"
                        name={tagName}
                        id={`${id}_${idx}`}
                        checked={radioValue === val.value}
                        onChange={(e) => { handleRadioClick(e); handleRadioChange(e); }}
                        value={val.value}
                        disabled={disabled}
                    />
                    <label htmlFor={`${id}_${idx}`}>{val.label}</label>
                </li>
            ))}
        </RadioBtnWrap>
        {errorTxt && <ErrorMsg className={errorClassName}>{errorTxt}</ErrorMsg>}
        </>
    )

}
export default CmmnRadioBox;