'use client'

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TextFields from "components/mobile/UI/TextFields";
import { LoginRequest } from "components/web/auth/LoginRequest";
import localStorage from "redux-persist/es/storage";
import CheckBox from "components/mobile/UI/CheckBox";
import logo from "asset/photomag_web/img/logo.svg"
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import * as apiSvc from "api/components/cmmn/CmmnApiCallService"
import CryptoJS from 'crypto-js';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const LoginWrap = styled.section`
    background-color: #F7F7F7;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .login_box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 30%;
        background-color: #fff;
        padding: 70px 50px;
        border-radius: 16px;
        box-shadow: 0 0 20px rgba(0,0,0,.1);
    }
    .logo_box {
        margin-bottom: 50px;
    }
    @media (max-width: 900px) {
        .login_box{
            width: 90%;
        }
    }
    @media (max-width: 600px) {
        body{
            background-color: #fff;
        }
        .login_box{
            box-shadow: none;
        }
    }
    .btnWrap {
        width: 100%;
        margin-top: 50px;
    }
`;
const CheckBoxWrap = styled(Form.Check)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;
/* localStorage 비밀번호 암호화 */
const secretKey = "zgPtZncNWBMDJRLp6IhdqC48sIuEt6E1";
const encryptData = (data) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
};

const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
};

export default function Login() {
    library.add(faEye, faEyeSlash);
    const { t } = useTranslation();
    const [userId, setUserId] = useState('');
    const [userPwd, setUserPwd] = useState('');
    const [isSaveId, setSaveId] = useState(false);
    const [isSavePwd, setSavePwd] = useState(false);
    const [showPwd, setShowPwd] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const locS_isSaveId = await localStorage.getItem("isSaveId");
                const locS_isSavePwd = await localStorage.getItem("isSavePwd");
                const locS_saveUserIdVal = await localStorage.getItem("saveUserIdVal");
                const locS_saveUserPwdVal = await localStorage.getItem("saveUserPwdVal");

                if (locS_isSaveId === "true") {
                    setSaveId(true);
                    setUserId(locS_saveUserIdVal);
                }
                if (locS_isSavePwd === "true") {
                    setSavePwd(true);
                    if (locS_saveUserPwdVal) {
                        const decryptedPwd = decryptData(locS_saveUserPwdVal);
                        setUserPwd(decryptedPwd);
                    }
                }

            } catch (error) {
                console.error("Error retrieving data from localStorage:", error);
            }
        };

        fetchData();
    }, []);

    const handleSaveIdClick = (e) => {
        const isChecked = e.target.checked;
        setSaveId(isChecked);

        if (!isChecked) {
            localStorage.removeItem("isSaveId");
            localStorage.removeItem("saveUserIdVal");
        } else {
            localStorage.setItem("isSaveId", "true");
            localStorage.setItem("saveUserIdVal", userId);
        }
    };

    const handleSavePwdClick = (e) => {
        const isChecked = e.target.checked;
        setSavePwd(isChecked);

        if (!isChecked) {
            localStorage.removeItem("isSavePwd");
            localStorage.removeItem("saveUserPwdVal");
        } else {
            localStorage.setItem("isSavePwd", "true");
            const encryptedPwd = encryptData(userPwd);
            localStorage.setItem("saveUserPwdVal", encryptedPwd);
        }
    };

    const handleInputPwdKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleLogin();
        }
    };

    const handleShowPwd = () => {
        setShowPwd(prevPWd => !prevPWd);
    }

    // 로그인 처리 함수
    const handleLogin = async () => {
        const result = await LoginRequest(userId, userPwd);

        if (!result) {
            return;
        }

        if (isSaveId) {
            localStorage.setItem("isSaveId", "true");
            localStorage.setItem("saveUserIdVal", userId);
        }
        if (isSavePwd) {
            localStorage.setItem("isSavePwd", "true");
            const encryptedPwd = encryptData(userPwd);
            localStorage.setItem("saveUserPwdVal", encryptedPwd);
        }

        await fetchBranchList();
        window.location.replace(`/dsh-brd/dsh-brd`);
    }

    const fetchBranchList = async () => {
        const brchClsfCd = await localStorage.getItem('brchClsfCd');
        const locBrchId = await localStorage.getItem('brchId');
        try {
            let response;
            switch (brchClsfCd) {
                case "KSC":
                    response = await apiSvc.GETHeaderBrchList({ brchClsfCd: brchClsfCd });
                    break;
                case "FCC":
                case "JMC":
                    response = await apiSvc.GETHeaderBrchList({ brchClsfCd: brchClsfCd, brchId: locBrchId });
                    break;
                default: break;
            }
            const firstBrchId = response[0]?.brchId;
            if (firstBrchId) {
                localStorage.setItem('changeBrchId', firstBrchId);
            }
        } catch (error) {
            console.error("Error fetching branch list:", error);
        }
    };

    return (
        <LoginWrap>
            <div className="login_box">
                <div className="logo_box">
                    <img src={logo} alt="login_logo" />
                </div>
                <TextFields
                    type="text"
                    label={t("login.loginId")}
                    className="loginId"
                    placeholder={t("login.inputId")}
                    name="userId"
                    id="userId"
                    value={userId}
                    onChangeValue={(e) => { setUserId(e.target.value) }}
                />
                <TextFields
                    type={showPwd ? "text" : "password"}
                    label={t("login.loginPwd")}
                    className="loginPw"
                    placeholder={t("login.inputPwd")}
                    name="userPwd"
                    id="userPwd"
                    value={userPwd}
                    onChangeValue={(e) => { setUserPwd(e.target.value) }}
                    onKeyDown={handleInputPwdKeyDown}
                    icon={showPwd ? faEye : faEyeSlash}
                    iconOnClick={handleShowPwd}
                />
                <CheckBoxWrap>
                    <CheckBox id="saveId"
                        label={t("login.saveId")}
                        checked={isSaveId}
                        onChange={handleSaveIdClick}
                    />
                    <CheckBox id="savePwd"
                        label={t("login.savePwd")}
                        checked={isSavePwd}
                        onChange={handleSavePwdClick}
                    />
                </CheckBoxWrap>
                <div className="btnWrap">
                    <ButtonUtil className="btn btn-danger round-filled-btn"
                        onClick={handleLogin}
                        value={t("login.loginBtn")}
                    />
                </div>
            </div>
        </LoginWrap>
    )
}