import { useEffect, useState } from "react";
import * as apiSvc from "api/components/cmmn/CmmnApiCallService"
import { Form } from "react-bootstrap";

export default function SmtDtmSelectBox(
    {
        defaultOptTxt
        , defaultVal
        , tagName
        , onSelectChange
        , className
        , disabled
        , readonly
        , isFirstSelect
    }
) {

    const [showOptions, setShowOptions] = useState(false);
    const [selectedVal, setSelectedVal] = useState(defaultOptTxt);
    const [smtList, setSmtList] = useState([]);

    const HandleShowOption = () => {
        //readonly 가 아닐때만 작동
        if (readonly !== true) {
            setShowOptions((current) => !current);
        }
    };
    const HandleMouseLeave = () => {
        setShowOptions(false);
    };

    useEffect(() => {
        apiSvc.GetSmtList()
            .then(res => {
                setSmtList(res);
                if (isFirstSelect && res.length > 0) {
                    setSelectedVal(res[0].acmlYrmn);
                    onSelectChange({ target: { name: tagName, value: res[0].acmlYrmn } });
                }
                if (res.length === 0) {
                    setSmtList([]);
                }
                if (defaultVal && res.some((item) => item.acmlYrmn === defaultVal)) {
                    setSelectedVal(defaultVal);
                } else {
                    setSelectedVal('');
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (defaultVal === 0 || defaultVal === '') {
            setSelectedVal(defaultOptTxt);
        } else {
            const selectedAcml = smtList.find((item) => item.acmlYrmn === defaultVal);
            if (selectedAcml) {
                setSelectedVal(selectedAcml.acmlYrmn);
            }
        }
    }, [defaultVal]);

    const handleSelectChange = (e) => {
        const selectedVal = e.target.value;
        setSelectedVal(selectedVal);
        const event = { target: { name: tagName, value: selectedVal } };
        onSelectChange(event);
    };

    return (
        <Form.Select
            name={tagName}
            value={selectedVal}
            onChange={handleSelectChange}
            disabled={disabled}
            className={className}
        >
            <option value="">{defaultOptTxt}</option>
            {smtList.map((smt, idx) => (
                <option key={idx} value={smt.acmlYrmn}>
                    {`${smt.acmlYrmn.substring(0, 4)}-${smt.acmlYrmn.substring(4, 6)}`}
                </option>
            ))}
        </Form.Select>
    )

}