import styled from "styled-components";
import TextFields from "../UI/TextFields";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import { Fragment, useEffect, useState } from "react";
import SelectBox from "../UI/SelectBox";
import { callApi } from "util/HttpService";
import { alert, modalConfirm } from "./CmmnConfirm";

const TableWrap = styled.div`  
    @media (max-width: 1200px) {
        overflow: scroll;
    }
    &.mt-40 {
        margin-top: 40px;
    }
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        margin-bottom: 15px;
    } 
`;
const InputTable = styled.div`
    // margin-bottom: 20px;
    border-top: 2px solid #EF2929;
    .input-table-th-small{
        max-width: 151px;
        min-width: 151px;
    }
    .input-table-wrap{
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
    .col-2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .dp-flex {
        display: flex;
    }
`;
const DlBox = styled.div`
    width: ${props => {
        const length = props.$length;
        if (length === 1) return "50%";
        if (length === 2) return "33.3%";
        if (length === 3) return "25%";
        if (length === 4) return "20%";
        if (length === 5) return "16.6%";
        return "100%";
    }};
    border-right: 1px solid rgba(0,0,0,.1);
    & > div > div {
        width: 100% !important;
    }
`;
const InputTableHead = styled.div`
    background-color: #F3F3F3;
    padding: 20px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    min-width: 191px;
    h3 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
`;
const InputTableBody = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    h4 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
    }
    h5 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        margin-left: 10px;
    }
    .form-select {
        max-width: 250px;
    }
    input[type="number"] {
        max-width: 250px;
        width: 250px;
    }
`;
const FrameTableHead = styled.div`
    background-color: #F3F3F3;
    padding: 20px;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 78px;
    min-width: 151px;
    &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
    h3 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
`;
const FrameTableBody = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 78px;
    min-width: 151px;
    &:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
    .form-select {
        width: 100%;
        max-width: 300px;
    }
    input[type="text"] {
        width: 250px;
    }
`;
const BtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate(-51%);
    .btn {
        width: 150px;
        max-width: 150px;
        &:first-child {
            margin-right: 10px;
        }
        &:nth-child(2) {
            margin-right: 10px;
            &:hover {
                color: var(--bs-btn-hover-color);
                background-color: var(--bs-btn-hover-bg);
                border-color: var(--bs-btn-hover-border-color);
            }
        }
        &:first-child:hover,
        &:last-child:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
    }
`;
export default function ProgramConfig({
    t,
    brchClsfCd,
    systemMode,
    onHide,
    kiskInfo,
    data,
    getKiskInfo,
}) {

    const [timeLimitSettings, setTimeLimitSettings] = useState({});
    const [printMarginSettings, setPrintMarginSettings] = useState({});
    const [framePaymentCntSettings, setFramePaymentCntSettings] = useState([]);
    const [filteredFrameArr, setFilteredFrameArr] = useState([]);

    useEffect(() => {
        if (kiskInfo && kiskInfo.eltmLmtnStupCn && kiskInfo.prntMrgnStupCn) {
            setTimeLimitSettings(kiskInfo.eltmLmtnStupCn);
            setPrintMarginSettings(kiskInfo.prntMrgnStupCn);
        }
    }, [kiskInfo]);

    const handleTimeLimitChange = (e, key) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setTimeLimitSettings(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handlePrintMarginChange = (e, key) => {
        const value = e.target.value;
        setPrintMarginSettings(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleFrameSettingsChange = (e, frameCd) => {
        const { name, value } = e.target;
        setFilteredFrameArr(prevState => {
            const newState = prevState.map(frame => {
                if (frame.frameCd === frameCd) {
                    return { ...frame, [name]: value };
                }
                return frame;
            });
            return newState;
        });
    };

    // 기본 제한 시간, 결제 대기 시간
    const basicLimitArray = Array.from({ length: 60 }, (_, i) => ({
        key: (i + 1) * 10,
        value: (i + 1) * 10,
    }));
    // 촬영 준비 시간
    const takeReadyLimitArray = Array.from({ length: 60 }, (_, i) => ({
        key: i + 1,
        value: i + 1,
    }));
    // 촬영 시간
    const takeLimitArray = Array.from({ length: 11 }, (_, i) => ({
        key: i + 1,
        value: i + 1,
    }));
    // 프레임 태그
    const frameTagArray = [
        { key: "HOT", value: "hot" },
        { key: "RECOMMEND", value: "recommend" },
    ];

    const handleCmmnBtnClick = async (btnNm) => {
        // timeLimitSettings, printMarginSettings, framePaymentCntSettings 각각 호출
        switch (btnNm) {
            case "cmmnInsertBtn":
                try {
                    const commonParams = { brchId: data.brchId, eqmtId: data.eqmtId, eqmtNo: data.eqmtNo, delYn: 'N' };

                    // timeLimitSettings
                    const timeLimitParams = { ...commonParams, eltmLmtnStupCn: timeLimitSettings };
                    await callApi("POST", timeLimitParams, "/web/api/dvc-mgt/edm/kisk-insert");
                    console.log("전송된 timeLimitSettings : ", timeLimitParams);

                    // printMarginSettings
                    const printMarginParams = { ...commonParams, prntMrgnStupCn: printMarginSettings };
                    await callApi("POST", printMarginParams, "/web/api/dvc-mgt/edm/kisk-insert");
                    console.log("전송된 printMarginSettings : ", printMarginParams);

                    // framePaymentCntSettings
                    const updatedFramePaymentCntSettings = framePaymentCntSettings.map(payment => {
                        const updatedFrame = filteredFrameArr.find(frame => frame.frameCd === payment.frameCd);
                        return updatedFrame ? { ...payment, ...updatedFrame } : payment;
                    });
                    const framePaymentParams = { ...commonParams, frmeStlmCn: updatedFramePaymentCntSettings };
                    await callApi("POST", framePaymentParams, "/web/api/dvc-mgt/edm/kisk-insert");
                    console.log("전송된 framePaymentCntSettings : ", framePaymentParams);

                    alert("", t("alert.save"), () => { getKiskInfo() });

                } catch (error) {
                    console.log(error);
                    alert("", t("alert.saveError"), () => { return });
                }
                break;
            case "cmmnResetBtn":
                try {
                    modalConfirm("", t("alert.resetConfirm"), () => {
                        setTimeLimitSettings(kiskInfo.eltmLmtnStupCn);
                        setPrintMarginSettings(kiskInfo.prntMrgnStupCn);
                        fetchData();
                        alert("", t("alert.initSuccessAlert"), () => { });
                    })    
                } catch (error) {
                    console.log(error);
                    alert("", t("alert.initFailAlert"), () => { });
                }
                break;
            default: break;
        };
    };

    const fetchData = async () => {
        if (kiskInfo && kiskInfo.frmeStupCn && kiskInfo.frmeStlmCn) {
            const frameSettings = kiskInfo.frmeStupCn;
            const tmpFramePaymentCntSettings = kiskInfo.frmeStlmCn;
            setFramePaymentCntSettings(tmpFramePaymentCntSettings);

            const filteredFrames = frameSettings.filter(frame =>
                Array.isArray(frame.systemModeArr)
                && frame.systemModeArr.includes(systemMode.value)
            );

            // framePaymentCntSettings에서 filteredFrames의 frameCd가 있는 항목만 추출하여 filteredFrames에 추가
            const frameCdSet = new Set(filteredFrames.map(frame => frame.frameCd));
            const filteredPaymentSettings = tmpFramePaymentCntSettings.filter(payment => frameCdSet.has(payment.frameCd));
            // console.log("filteredPaymentSettings : ", filteredPaymentSettings);

            // filteredFrames에 amtVal 등의 정보 추가
            const mergedFrames = await Promise.all(filteredFrames.map(async frame => {
                const paymentInfo = filteredPaymentSettings.find(payment => payment.frameCd === frame.frameCd);
                return { ...frame, ...paymentInfo };
            }));

            const finalFrames = mergedFrames.flat();
            // console.log("finalFrames : ", finalFrames);
            setFilteredFrameArr(finalFrames);
        }
    };

    useEffect(() => {
        fetchData();
    }, [kiskInfo]);

    // useEffect(() => {
    //     console.log("filteredFrameArr : ", filteredFrameArr);
    // }, [filteredFrameArr])

    return (
        <>
            <div className="p-30">
                <TableWrap>
                    <h2>{t("prgmLimitTimeSetting.title")}</h2>
                    <InputTable>
                        <div className="col-2">
                            <div className="input-table-wrap w-50">
                                <InputTableHead>
                                    <h3>{t("prgmLimitTimeSetting.lblDefLimitTimeSet")}</h3>
                                </InputTableHead>
                                <InputTableBody>
                                    <div className="dp-flex align-cen w-100">
                                        <SelectBox
                                            defaultOptTxt={t("saleHistPage.entire")}
                                            dataArray={basicLimitArray}
                                            onSelectChange={(e) => handleTimeLimitChange(e, 'basicLimit')}
                                            tagName="basicLimit"
                                            defaultVal={timeLimitSettings.basicLimit || 60}
                                        />
                                        <h5>{t("common.seconds")}</h5>
                                    </div>
                                </InputTableBody>
                            </div>
                            <div className="input-table-wrap w-50">
                                <InputTableHead>
                                    <h3>{t("prgmLimitTimeSetting.lblTakeReadyLimitTimeSet")}</h3>
                                </InputTableHead>
                                <InputTableBody>
                                    <div className="dp-flex align-cen w-100">
                                        <SelectBox
                                            defaultOptTxt={t("saleHistPage.entire")}
                                            dataArray={takeReadyLimitArray}
                                            onSelectChange={(e) => handleTimeLimitChange(e, 'takeReadyLimit')}
                                            tagName="takeReadyLimit"
                                            defaultVal={timeLimitSettings.takeReadyLimit || 60}
                                        />
                                        <h5>{t("common.seconds")}</h5>
                                    </div>
                                </InputTableBody>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="input-table-wrap w-50">
                                <InputTableHead>
                                    <h3>{t("prgmLimitTimeSetting.lblPaymentLimitTimeSet")}</h3>
                                </InputTableHead>
                                <InputTableBody>
                                    <div className="dp-flex align-cen w-100">
                                        <SelectBox
                                            defaultOptTxt={t("saleHistPage.entire")}
                                            dataArray={basicLimitArray}
                                            onSelectChange={(e) => handleTimeLimitChange(e, 'paymentLimit')}
                                            tagName="paymentLimit"
                                            defaultVal={timeLimitSettings.paymentLimit || 60}
                                        />
                                        <h5>{t("common.seconds")}</h5>
                                    </div>
                                </InputTableBody>
                            </div>
                            <div className="input-table-wrap w-50">
                                <InputTableHead>
                                    <h3>{t("prgmLimitTimeSetting.lblTakePhotoLimitTimeSet")}</h3>
                                </InputTableHead>
                                <InputTableBody>
                                    <div className="dp-flex align-cen w-100">
                                        <SelectBox
                                            defaultOptTxt={t("saleHistPage.entire")}
                                            dataArray={takeLimitArray}
                                            onSelectChange={(e) => handleTimeLimitChange(e, 'takeLimit')}
                                            tagName="takeLimit"
                                            defaultVal={timeLimitSettings.takeLimit || 60}
                                        />
                                        <h5>{t("common.seconds")}</h5>
                                    </div>
                                </InputTableBody>
                            </div>
                        </div>
                    </InputTable>
                </TableWrap>
                {brchClsfCd === "KSC" &&
                    <TableWrap className="mt-40">
                        <h2>{t("prgmPrintWhiteSpaceSetting.title")}</h2>
                        <InputTable>
                            <div className="col-2">
                                <div className="input-table-wrap w-50">
                                    <InputTableHead className="mh-88">
                                        <h3>{t("prgmPrintWhiteSpaceSetting.lblVFrameTopWs")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <div className="dp-flex align-cen w-100">
                                            <TextFields
                                                type="number"
                                                name="vTopMargin"
                                                onChangeValue={(e) => handlePrintMarginChange(e, 'vTopMargin')}
                                                value={printMarginSettings.vTopMargin || 0}
                                            />
                                            <h5>px</h5>
                                        </div>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap w-50">
                                    <InputTableHead className="mh-88">
                                        <h3>{t("prgmPrintWhiteSpaceSetting.lblHFrameTopWs")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <div className="dp-flex align-cen w-100">
                                            <TextFields
                                                type="number"
                                                name="hTopMargin"
                                                onChangeValue={(e) => handlePrintMarginChange(e, 'hTopMargin')}
                                                value={printMarginSettings.hTopMargin || 0}
                                            />
                                            <h5>px</h5>
                                        </div>
                                    </InputTableBody>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="input-table-wrap w-50">
                                    <InputTableHead className="mh-88">
                                        <h3>{t("prgmPrintWhiteSpaceSetting.lblVFrameLeftWs")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <div className="dp-flex align-cen w-100">
                                            <TextFields
                                                type="number"
                                                name="vLeftMargin"
                                                onChangeValue={(e) => handlePrintMarginChange(e, 'vLeftMargin')}
                                                value={printMarginSettings.vLeftMargin || 0}
                                            />
                                            <h5>px</h5>
                                        </div>
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap w-50">
                                    <InputTableHead className="mh-88">
                                        <h3>{t("prgmPrintWhiteSpaceSetting.lblHFrameLeftWs")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <div className="dp-flex align-cen w-100">
                                            <TextFields
                                                type="number"
                                                name="hLeftMargin"
                                                onChangeValue={(e) => handlePrintMarginChange(e, 'hLeftMargin')}
                                                value={printMarginSettings.hLeftMargin || 0}
                                            />
                                            <h5>px</h5>
                                        </div>
                                    </InputTableBody>
                                </div>
                            </div>
                        </InputTable>
                    </TableWrap>
                }
                <TableWrap className="mt-40">
                    <h2>{t("prgmFrameSetting.title")}</h2>
                    <InputTable className="dp-flex">
                        <DlBox $length={filteredFrameArr.length}>
                            <div className="input-table-wrap">
                                <FrameTableHead><h3>{t("prgmFrameSetting.frame")}</h3></FrameTableHead>
                            </div>
                            <div className="input-table-wrap">
                                <FrameTableHead><h3>{t("prgmFrameSetting.lblBaseAmt")}</h3></FrameTableHead>
                            </div>
                            <div className="input-table-wrap">
                                <FrameTableHead><h3>{t("prgmFrameSetting.lblAddAmt")}</h3></FrameTableHead>
                            </div>
                            <div className="input-table-wrap">
                                <FrameTableHead><h3>{t("prgmFrameSetting.lblPrintPhotCnt")}</h3></FrameTableHead>
                            </div>
                            <div className="input-table-wrap">
                                <FrameTableHead><h3>{t("prgmFrameSetting.lblPhotoTakeCnt")}</h3></FrameTableHead>
                            </div>
                            <div className="input-table-wrap">
                                <FrameTableHead><h3>{t("prgmFrameSetting.lblFrameTag")}</h3></FrameTableHead>
                            </div>
                        </DlBox>
                        {filteredFrameArr.map((frame, index) => (
                            <Fragment key={`fragment_key_${index}`}>
                                <DlBox $length={filteredFrameArr.length}>
                                    <div className="input-table-wrap">
                                        <FrameTableHead>
                                            <h3>{t(`prgmFrameSetting.lbl${frame.frameCd}FrameExpl`)}</h3>
                                        </FrameTableHead>
                                    </div>
                                    <div className="input-table-wrap">
                                        <FrameTableBody>
                                            <TextFields
                                                key={`inputKey_0_${index}`}
                                                type="number"
                                                name="amtVal"
                                                value={parseInt(frame.amtVal)}
                                                onChangeValue={(e) => handleFrameSettingsChange(e, frame.frameCd)}
                                                className="w-100"
                                                step="100"
                                            />
                                        </FrameTableBody>
                                    </div>
                                    <div className="input-table-wrap">
                                        <FrameTableBody>
                                            <TextFields
                                                key={`inputKey_1_${index}`}
                                                type="number"
                                                name="addAmtVal"
                                                value={parseInt(frame.addAmtVal)}
                                                onChangeValue={(e) => handleFrameSettingsChange(e, frame.frameCd)}
                                                className="w-100"
                                                step="100"
                                            />
                                        </FrameTableBody>
                                    </div>
                                    <div className="input-table-wrap">
                                        <FrameTableBody>
                                            <TextFields
                                                key={`inputKey_2_${index}`}
                                                type="number"
                                                name="basePhotoCnt"
                                                value={parseInt(frame.basePhotoCnt)}
                                                step={frame.frameInfo.cutYn === 'Y' ? 2 : 1}
                                                min={frame.frameInfo.cutYn === 'Y' ? 2 : 1}
                                                onChangeValue={(e) => handleFrameSettingsChange(e, frame.frameCd)}
                                                className="w-100"
                                            />
                                        </FrameTableBody>
                                    </div>
                                    <div className="input-table-wrap">
                                        <FrameTableBody>
                                            <TextFields
                                                key={`inputKey_3_${index}`}
                                                type="number"
                                                name="takePhotoCnt"
                                                value={parseInt(frame.takePhotoCnt)}
                                                onChangeValue={(e) => handleFrameSettingsChange(e, frame.frameCd)}
                                                className="w-100"
                                            />
                                        </FrameTableBody>
                                    </div>
                                    <div className="input-table-wrap">
                                        <FrameTableBody>
                                            <SelectBox
                                                defaultOptTxt={t("saleHistPage.entire")}
                                                dataArray={frameTagArray}
                                                onSelectChange={(e) => handleFrameSettingsChange(e, frame.frameCd)}
                                                tagName="frameTag"
                                                defaultVal={frame.frameTag || ""}
                                            />
                                        </FrameTableBody>
                                    </div>
                                </DlBox>
                            </Fragment>
                        ))}
                    </InputTable>
                </TableWrap>
            </div>
            <BtnWrap>
                <ButtonUtil className="btn btn-danger round-filled-small-btn"
                    value={t("common.lblInitBtn")}
                    onClick={() => { handleCmmnBtnClick("cmmnResetBtn") }}
                />
                <ButtonUtil className="btn btn-outline-light round-solid-medium-btn"
                    value={t("common.cancelLable")}
                    onClick={() => { onHide() }}
                />
                <ButtonUtil className="btn btn-danger round-filled-small-btn"
                    value={t("common.saveLable")}
                    onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                />
            </BtnWrap>
        </>
    )
}