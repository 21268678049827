import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store from 'Reducer/store';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { BrchProvider } from 'components/web/auth/BrchProvider';
import 'bootstrap/dist/css/bootstrap.css';
import { WindowSizeProivider } from 'context/WindowSizeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
      <React.StrictMode>
            <Provider store={store}>
                  <BrchProvider>
                        <BrowserRouter>
                              <WindowSizeProivider>
                                    <App />
                              </WindowSizeProivider>
                        </BrowserRouter>
                  </BrchProvider>
            </Provider>
      </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
