import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartBox = styled.div`
    width: 100%;
    height: 250px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const EqmtByTypeChart = React.memo(({ salesData, text }) => {
    const {t} = useTranslation();
    const backgroundColorList = ["#e50200","#fcc355","#3751cc","#1a89e3","#ccee88","#ee22ff","#000000","#bafaa9","#aabbcc"];
    const options = useMemo(() => ({
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    font: {
                        size: 11,
                    },
                    boxWidth: 6,
                    boxHeight: 6,
                },
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const dataIndex = context.dataIndex;
                        const salesItem = salesData[dataIndex];
                        return `${t('dashboardPage.sale')}: ${text} ${Intl.NumberFormat("ko-KR", {}).format(salesItem.eqmtAmt?salesItem.eqmtAmt:0)} (${salesItem.eqmtAmt?(salesItem.eqmtAmt / salesItem.totalAmt * 100).toFixed(2):0}%)`;
                    }
                }
            }
        },
    }), [salesData, t, text]);

    const data = useMemo(() => ({
        labels: salesData.map(data => data.eqmtNm),
        datasets: [
            {
                label: `${t("m_dashboardPage.sale")}`,
                data: salesData.map(data => data.eqmtAmt === undefined ? 1:data.eqmtAmt),
                backgroundColor: salesData.map((data,idx) => data.eqmtAmt === undefined ? "#B0B0B0":backgroundColorList[idx]),
                borderWidth: 0,
            },
        ],
    }), [backgroundColorList, salesData, t]);

    return (
        <ChartBox>
            <Doughnut options={options} data={data} />
        </ChartBox>
    );
});

export default EqmtByTypeChart;
