'use client'

import * as apiSvc from "api/components/cmmn/CmmnApiCallService"
import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"


export default function EqmtSelectBox(
    {
        brchId,
        defaultOptTxt,
        defaultVal,
        tagName,
        onSelectChange,
        className,
        readonly
    }
) {

    const [selectedVal, setSelectedVal] = useState(defaultVal || '');
    const [eqmtList, setEqmtList] = useState([]);

    useEffect(() => {
        apiSvc.GetEqmtAllList({ brchId })
            .then(res => {
                setEqmtList(res);
                if (defaultVal === 0 || defaultVal === '') {
                    setSelectedVal('');
                } else {
                    const selectedCd = res.find(data => data.eqmtId == defaultVal);
                    if (selectedCd) {
                        setSelectedVal(selectedCd.eqmtId);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, [defaultVal,brchId]);

    const handleSelectChange = (e) => {
        const selectedVal = e.target.value;
        setSelectedVal(selectedVal);
        const event = { target: { name: tagName, value: selectedVal } };
        onSelectChange(event);
    };


    return (
        <Form.Select
            name={tagName}
            value={selectedVal}
            onChange={handleSelectChange}
            disabled={readonly}
            className={className}
        >
            <option value="">{defaultOptTxt}</option>
            {eqmtList.map((eqmt) => (
                <option key={eqmt.eqmtNo} value={eqmt.eqmtNo}>
                    {eqmt.eqmtNm}
                </option>
            ))}
        </Form.Select>
    )

}