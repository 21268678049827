import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const WindowSizeContext = createContext();

export const WindowSizeProivider = ({ children }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleResize = useCallback(() => {
        setIsMobile(window.innerWidth <= 768);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    return (
        <WindowSizeContext.Provider value={isMobile}>
            {children}
        </WindowSizeContext.Provider>
    );
};

export const useWindowSize = () => {
    return useContext(WindowSizeContext);
}