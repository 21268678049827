// 반응형 디자인을 위한 픽셀 컨버팅 함수
const pixelToRem = (size) => `${size / 10}rem`;

const fontSizes = {
  title: pixelToRem(26),
  subtitle: pixelToRem(16),
  default: pixelToRem(15),
  sm: pixelToRem(14),
};

const colors = {
  primary: "#1a89e3",
  primaryLight: "#01bff3",
  primaryDark: "#1b26b3",
  secondary: "#e50200",
  border: "#e5e5e5",
  borderLight: "#efefef",
  borderDark: "#b5b5b5",
  black: "#222222",
  gray1: "#606060",
  gray2: "#888888",
  gray3: "#9f9f9f",
  gray4: "#eeeeee",
  gray5: "#f2f2f2",
  gray6: "#8b8b8b",
  gray7: "#d3d3d3",
  gray8: "#A6A6A6",
  yellow: "#FEB907",
  yellowLight: '#fff9c4',
  yellowDark: '#ffeb3b',
  red: "#EF2929",
  dark: "#2D2D2A",
  dark2: "#3B3B3B",
  white1: "#EEEEEE",
  green: "#00d45e",
  orange: "#FFA500",
};

const common = {
  flexCenter: `
    display: flex;
    justify-contents: center;
    align-items: center;
  `,
  flexCenterColumn: `
    display: flex;
    flex-direction: column;
    justify-contents: center;
    align-items: center;
  `,
};

const theme = {
  fontSizes,
  colors,
  common,
};

export default theme;
