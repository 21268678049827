'use client'

import { Fragment, useEffect, useState } from "react";
import styled from "styled-components"

const RadioBtnWrap = styled.li`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
    input[type="radio"] {
        display: none;
        &:checked + label {
            background-color: ${({ theme }) => theme.colors.red};
            color: #fff;
        }
    }
    label {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.gray6};
        background-color: #fff;
        padding: 7px 10px;
        border-radius: 100px;
        font-weight: 400;
        transition: all .3s ease-in-out;
        margin: 0px 10px 10px 0;
    }
`;
const WebRadioBtnWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 20px;
    input[type="radio"] {
        display: none;
        &:checked + label {
            background-color: ${({ theme }) => theme.colors.red};
            color: #fff;
            border-color: ${({ theme }) => theme.colors.red};
        }
        &:not(:checked) + label:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: ${({ theme }) => theme.colors.red} !important;
        }
    }
    label {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.gray6};
        border: 1px solid ${({ theme }) => theme.colors.gray7};
        background-color: #fff;
        padding: 7px 10px;
        border-radius: 100px;
        font-weight: 400;
        transition: all .3s ease-in-out;
        margin-right: 5px;
    }
`;

export default function CmmnRadioBtn(
    {
        className,
        dataArray,
        onSelectChange,
        disabled,
        defaultVal,
        tagName,
        isMobile
    }
) {

    const [radioValue, setRadioValue] = useState(defaultVal);

    useEffect(() => {
        setRadioValue(defaultVal);
    }, [defaultVal]);

    const handleRadioClick = (e) => {
        const value = e.target.value;
        setRadioValue(value);
    };

    const handleRadioChange = (e) => {
        onSelectChange(e);
    };

    const Wrapper = isMobile ? RadioBtnWrap : WebRadioBtnWrap;

    return (
        <Wrapper className={className}>
            {dataArray.map((btn, idx) => (
                <Fragment key={idx}>
                    <input
                        type="radio"
                        name={tagName}
                        id={idx}
                        checked={radioValue === btn.value}
                        onChange={(e) => { handleRadioClick(e); handleRadioChange(e); }}
                        value={btn.value}
                        disabled={disabled}
                    />
                    <label htmlFor={idx}>{btn.label}</label>
                </Fragment>
            ))}
        </Wrapper>
    )

}