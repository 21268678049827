'use client'

import { useEffect, useState } from "react"
import { Form } from "react-bootstrap";
import * as apiSvc from "api/components/cmmn/CmmnApiCallService"
import styled from "styled-components";
import { useBrch } from "../auth/BrchProvider";

const FormStyle = styled(Form.Select)`
    .readonly {
        background-color: var(--bs-secondary-bg);
    }
`

export default function HeaderBrchNm(
    {
        defaultOptTxt
        , defaultVal
        , tagName
        , onSelectChange
        , className
        , disabled
        , brchClsfCd
        , brchId
    }
) {
    const { changeBrchId } = useBrch();
    const [selectedVal, setSelectedVal] = useState(defaultOptTxt);
    const [brchAllList, setBrchAllList] = useState([]);

    useEffect(() => {
        apiSvc.GETHeaderBrchList({ brchId, brchClsfCd })
            .then(res => {
                setBrchAllList(res);

                if (!defaultVal && res.length > 0) {
                    const firstBrchId = res[0].brchId;
                    setSelectedVal(firstBrchId);
                    changeBrchId(firstBrchId, false);
                } else if (defaultVal) {
                    setSelectedVal(defaultVal);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, [brchClsfCd, brchId, defaultVal, changeBrchId]);

    const handleSelectChange = (e) => {
        const selectedVal = e.target.value;
        // console.log(selectedVal);
        if (selectedVal === '') {
            setSelectedVal('');
            return;
        }
        setSelectedVal(selectedVal);
        const event = { target: { name: tagName, value: selectedVal } };
        onSelectChange(event);
    };

    return (
        <FormStyle
            name={tagName}
            value={selectedVal}
            onChange={handleSelectChange}
            disabled={disabled}
            className={className}
        >
            <option value="">{defaultOptTxt}</option>
            {brchAllList.map((brch) => (
                <option key={brch.brchId} value={brch.brchId}>
                    {brch.brchNm}
                </option>
            ))}
        </FormStyle>
    );
}