import React, { useState,useCallback } from 'react';
import * as httpService from "util/HttpService";
import { alert } from "components/web/cmmn/CmmnConfirm";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const UploadForm = styled.div`
    .custom-file-upload {
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        background-color: #FF6C6C;
        color: white;
        border: none;
        border-radius: 4px;
        margin: 0px 10px;
        margin
    }
    .custom-file-upload:hover {
        background-color: #C90000;
    }
    .custom-upload-btn {
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
        background-color: #BDBDBD;
        color: white;
        border: none;
        border-radius: 4px;
    }
    .custom-upload-btn:hover {
        background-color: #8C8C8C;
    }

    .round-filled-gray-btn {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.gray6};
        border-radius: 80px;
        background-color: ${({ theme }) => theme.colors.gray5} !important;
        width: 20%;
        padding: 9px 10px;
        border-color: ${({ theme }) => theme.colors.gray5} !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .round-filled-gray-btn:hover {
        background-color: #8C8C8C;
    }

    .round-filled-small-btn {
        font-size: 16px;
        color: #fff;
        border-radius: 80px;
        background-color: ${({ theme }) => theme.colors.red};
        width: 20%;
        min-width: fit-content;
        padding: 9px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .round-filled-small-btn:hover {
        background-color: #C90000;
    }
`;

const FileUploadField = styled.div`
    display: flex;
    align-items: center;
    gap:5px;

    input {
        padding: 10px;
        font-size: 16px;
        color: #3b3b3b;
        border: 1px solid #D3D3D3;
        border-radius: 4px;
        height: 35px;
        width: 100%;
    }
    .readonly {
        background-color: var(--bs-secondary-bg);
    }
    input::placeholder {
        color: #868e96;
    }
    .w-100 {
        width: 100%;
    }
`;


export default function FrameUploadForm({id,code,type,sendData,dirName,prefix}) {
    const {t} = useTranslation();

    const [file,setFile] = useState(null);
    const [fileName,setFileName] = useState('');
    const [message, setMessage] = useState('');
    const [sendFileName,setSendFileName] = useState('default.png');
    
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if(selectedFile) {
            //setCnt(cnt+1);
            setFileName(selectedFile.name);
            setFile(event.target.files[0]);
        } else {
            setFileName('');
            setFile(null);
        }
    }

    var fileNameBuilder = "";
    //파일명 설정
    if(sendData.backgroundClsf === 'color'){
        fileNameBuilder = code+"_"+sendData.background+".png";
    }else if(sendData.backgroundClsf === 'event'){
        fileNameBuilder = type+"_"+code+"_"+sendData.background+".png";
    }

    const handleSubmit = async (event) => {
        console.log(sendData.brchId);
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', fileNameBuilder);
        formData.append('brchId',sendData.brchId);
        formData.append('eqmtNo',sendData.eqmtNo);
        formData.append('dirName',dirName);
        formData.append('prefix',prefix);

        if(sendData.backgroundClsf === "") {
            setMessage(t("frameUpdtPage.noSelectBgrdClsf"));
        }else if(sendData.background === ""){
            setMessage(t("frameUpdtPage.noSelectBgrd"));
        }else {
            try {
                httpService.callApi("POST",formData,'/web/api/frame/upload',{
                    headers:{
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then(res => {
                    console.log(res);
                    setMessage();
                    alert("확인",t("frameUpdtPage.uploadSuccess"),()=>{});
                })
                .catch(error => {
                    console.error(error);
                    setMessage('File upload failed.');
                    alert("확인",t("frameUpdtPage.uploadFailed"),()=>{});
                })
            } catch (error) {
                setMessage('File upload failed.');
            }
        }
    }

    return (
        <UploadForm>
            <form onSubmit={handleSubmit}>
                <FileUploadField>
                    <input type="text" value={fileName} readOnly placeholder='No file chosen'/>
                    <label htmlFor={id} className="round-filled-small-btn">
                        {t("frameUpdtPage.fileChoose")}
                    </label>
                    <input id={id} type="file" onChange={handleFileChange} style={{display:"none"}}/>
                    <button type="submit" className="round-filled-gray-btn">Upload</button>
                </FileUploadField>
            </form>
            {message && <p>{message}</p>}
        </UploadForm>
    )
}

    