'use client'

import Header from "components/web/layout/Header"
// import ScrollToTop from "components/web/layout/ScrollToTop"
import styled, { css } from "styled-components"
import RootLayoutComponents from "./RootLayoutComponents";
import Gnb from "./Gnb";

const BodyWrap = styled.div`
    ${(props) => !props.isLoginPage && css`
        background-color: #f7f7f7;
    `}
    .container-wrap {
        display: flex;
        align-items: flex-start;
        section {
            width: 82%;
            .padding-box {
                @media (max-width: 1200px) {
                    padding: 30px;
                }
                padding: 50px 50px 30px 50px;
                .title-wrap {
                    .title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #D3D3D3;
                        width: 100%;
                        p {
                            font-size: 14px;
                            color: #8B8B8B;
                            font-weight: 400;
                        }
                        img {
                            margin-right: 10px;
                        }
                        h2 {
                            font-size: 18px;
                            color: #3b3b3b;
                            font-weight: 500;
                        }
                        .alignset {
                            display: flex;
                            align-items: center;
                        }
                    }
                    .data-text {
                        text-align: center;
                        font-size: 16px;
                        color: #3b3b3b;
                        font-weight: bold;
                        margin: 15px 0;
                    }
                }
                .col-2{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }
                .col-2 > div{
                    width: 49.2%;
                }  
                .w-100{
                    width: 100%;
                }
                .mt-50{
                    margin-top: 50px;
                }
                .mt-30{
                    margin-top: 30px;
                }
                .mt-20{
                    margin-top: 20px;
                }
                .mr-5{
                    margin-right: 5px;
                }
                .mr-10{
                    margin-right: 10px;
                }
                .mr-20{
                    margin-right: 20px;
                }
                .mr-30{
                    margin-right: 30px;
                }
                .mr-35{
                    margin-right: 35px;
                }
                .mr-50{
                    margin-right: 50px;
                }
                .h-100{
                    height: 100vh !important;
                }
                .h-2row{
                    min-height: 145px !important;
                }
                .h-15row{
                    min-height: 110px !important;
                }
                .p-20{
                    padding: 20px;
                }
                .p-30{
                    padding: 30px;
                }
                .pr-20{
                    padding-right: 20px;
                }
                .pl-20{
                    padding-left: 20px;
                }
                .pb-0{
                    padding-bottom: 0 !important;
                }
                .m-0{
                    margin: 0 !important;
                }
                .b-none{
                    border: none !important;
                }
                .dp-flex{
                    display: flex;
                }
                .space-between{
                    justify-content: space-between;
                }
                .align-cen{
                    align-items: center;
                }
                .flex-end{
                    justify-content: flex-end;
                }
                .table .thead .th{
                    background-color: #F3F3F3;
                    font-size: 16px;
                    text-align: center;
                    font-weight: 500;
                    color: #3b3b3b;
                    // padding: 15px 20px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .table .tbody .tb{
                    background-color: #fff;
                    font-size: 16px;
                    text-align: center;
                    font-weight: 400;
                    color: #3b3b3b;
                    padding: 15px 20px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .table .thead .th-small{
                    padding: 15px 20px;
                    font-size: 14px;
                }
                .table .tbody .tb-small{
                    padding: 15px 20px;
                    font-size: 14px;
                }
                .table .thead{
                    display: flex;
                    align-items: center;
                    width: 100%;
                }
                .table .tbody{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    border-bottom: 1px solid rgba(0,0,0,.1);
                }
                .scroll-wrap{
                    overflow-y: scroll;
                }
                .scroll-wrap .thead{
                    position: sticky;
                    top: 0;
                }
                ..max-content {
                    width: max-content !important;
                }
                .h-10{
                    height: 500px;
                }
                .w-05{
                    width: 5%;
                }
                .w-075{
                    width: 7.5%;
                }
                .w-1{
                    width: 10%;
                }
                .w-15{
                    width: 15%;
                }
                .w-175{
                    width: 17.5%;
                }
                .w-2{
                    width: 20%;
                }
                .w-25{
                    width: 25%;
                }
                .w-3{
                    width: 30%;
                }
                .w-4{
                    width: 40%;
                }
                .w-5{
                    width: 50%;
                }
                .w-6{
                    width: 60%;
                }
                .w-7{
                    width: 70%;
                }
                .w-8{
                    width: 80%;
                }
                .w-9{
                    width: 90%;
                }
            }
        }
    }
    @media (max-width: 1200px) {
        .box-wrap li h2{
            font-size: 24px;
        }
        .device-box{
            width: 49%;
            margin-bottom: 20px;
        }
        .device-wrap{
            flex-wrap: wrap;
        }
    }
`;


export default function MainLayoutComponents({ children }) {

    const isLoggedIn = !!localStorage.getItem('accessToken');

    return (
        <>
            <RootLayoutComponents>

                <BodyWrap id="bodyWrap">
                    {isLoggedIn ?
                        <section className="container-wrap">

                            <Gnb />

                            <section>
                                <Header />

                                {children}
                            </section>

                        </section>
                        :
                        <>
                            {children}
                        </>
                    }
                </BodyWrap>

            </RootLayoutComponents>
        </>
    )
}
