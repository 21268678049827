'use client'

import * as apiSvc from "api/components/cmmn/CmmnApiCallService"
import { useEffect, useState } from 'react';
import styled from "styled-components";
import { callApi } from 'util/HttpService';
import logo from "asset/img/select.svg"

const SelectBox = styled.select`
    min-width:80px;
    height:34px;
    line-height:32px;
    padding:0 30px 0 15px;
    border:1px solid #cdd0db;
    font-size:15px;
    border-radius:0px;
    background:#fff url(${logo}) no-repeat right 10px top 50%;
    box-sizing:border-box; 
    background-size: 7px;
    &:disabled {
        color:#999 !important;
        background:#eee url(${logo}) no-repeat right 10px top 50%;
    }
`
const ErrorMsg = styled.p`
        color: ${({ theme }) => theme.colors.secondary};
        text-align: left;
        font-size: 14px;
        line-height: 1.2rem;
        width: 100%;
    `;


export default function CmmnCdSelectBox({
    cmmnCdId,
    defaultOptTxt,
    defaultVal,
    tagName,
    onSelectChange,
    className,
    readonly,
    errorTxt
}) {

    const [showOptions, setShowOptions] = useState(false);
    const [selectedVal, setSelectedVal] = useState(defaultVal || '');
    const [cmmnCdList, setCmmnCdList] = useState([]);


    const HandleShowOption = () => {
        //readonly 가 아닐때만 작동
        if (readonly !== true) {
            setShowOptions((current) => !current);
        }
    };
    const HandleMouseLeave = () => {
        setShowOptions(false);
    };

    useEffect(() => {
        apiSvc.GetCmmnCdList({ cmmnCdId })
            .then(res => {
                setCmmnCdList(res);
                if (defaultVal === 0 || defaultVal === '') {
                    setSelectedVal('');
                } else {
                    const selectedCd = res.find(data => data.cmmnCd == defaultVal);
                    if (selectedCd) {
                        setSelectedVal(selectedCd.cmmnCd);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, [defaultVal, cmmnCdId]);


    const HandleSelected = (txt) => {
        setSelectedVal(txt);
    };

    const handleSelectChange = (e) => {
        const selectedVal = e.target.value;
        console.log(selectedVal);
        setSelectedVal(selectedVal);
        const event = { target: { name: tagName, value: selectedVal } };
        onSelectChange(event);
    };


    return (
        
        <div style={{display:"flex", flexDirection:"column",width:"40%"}}>
        <select
            name={tagName}
            value={selectedVal}
            onChange={handleSelectChange}
            disabled={readonly}
            className={className}
        >
            <option value="">{defaultOptTxt}</option>
            {cmmnCdList.map((cmmn) => (
                <option key={cmmn.cmmnCd} value={cmmn.cmmnCd}>
                    {cmmn.cmmnCdNm}
                </option>
            ))}
        </select>
        {errorTxt && <ErrorMsg>{errorTxt}</ErrorMsg>}
        </div>
    );
}