import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "locales/i18n.ts";
export default function LangSelectBox({tagName,selectedVal,disabled,className}) {
    const { t } = useTranslation();
    const [language, setLanguage] = useState('ko');

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    const handleLangChange = (e) => {
        setLanguage(e.target.value);
    };

    return (
        <Form.Select
            name={tagName}
            value={selectedVal}
            onChange={handleLangChange}
            disabled={disabled}
            className={className}
        >
            <option value="">Language</option>
            <option key="ko" value="ko">한국어</option>
            <option key="jp" value="jp">日本語</option>
            <option key="en" value="en">English</option>
            
        </Form.Select>
    );
}
