import { CloseButton, Modal, Nav, Tab } from "react-bootstrap";
import styled from "styled-components";
import titleLogo from "asset/mobile_web/img/icon/filled/developer-board.svg";
import { useEffect, useRef, useState } from "react";
import SystemConfig from "./SystemConfig";
import ProgramConfig from "./ProgramConfig";
import SmartCorrectionConfig from "./SmartCorrectionConfig";
import { callApi } from "util/HttpService";
import { SyncLoader } from "react-spinners";
const LoadingStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        text-align: center;
        line-height: 150%;
    }
`;
const ModalWrap = styled(Modal)`
    z-index: 2100 !important;
    .modal-backdrop {
        z-index: 2000 !important;
    }
    @media (min-width: 1600px) {
        .modal-dialog {
            min-width: 1500px !important;
            width: 1500px !important; 
        }
        .modal-content {
            height: 853px !important;
        }
        .dashboard-box {
            height: 620px !important;
        }
        .table-lg {
            height: 510px !important;
        }
    }
    .modal-dialog {
        min-width: 1300px;
        width: 1300px;
        .dp-flex {
            display: flex;
        }
        .align-cen {
            align-items: center;
        }
        .mt-40 {
            margin-top: 40px;
        }
        .mh-88 {
            min-height: 88px !important;
        }
        .modal-content {
            padding: 30px;
            height: 733px; // 고정된 높이값 -> 주석처리하면 자동으로 높이 조절함
        }
        .modal-header {
            padding: 15px 0px 15px 0px;
            border-bottom: 1px solid rgba(0,0,0,.1);
            h2 {
                font-size: 18px;
                color: #3b3b3b;
                font-weight: 500;
            }
        }
        .modal-body {
            h3 {
                font-size: 16px;
                color: #3b3b3b;
                text-align: center;
                line-height: 150%;
            }
            .pr-20{
                padding-right: 20px;
            }
            .pl-20{
                padding-left: 20px;
            }
            .pb-0{
                padding-bottom: 0 !important;
            }
        }
        .nav-tabs {
            border: none;
            .nav-item {
                margin-right: 5px;
                &:last-child {
                    margin: 0;
                }
            .nav-link {
                border: none;
                font-size: 14px;
                background-color: ${({ theme }) => theme.colors.gray5};
                border: 1px solid ${({ theme }) => theme.colors.gray5};
                color: ${({ theme }) => theme.colors.gray6};
                border-radius: 100px;
                &.active {
                    border: 1px solid ${({ theme }) => theme.colors.red};
                    border-radius: 100px;
                    color: ${({ theme }) => theme.colors.red};
                    font-size: 14px;
                    background-color: var(--bs-nav-tabs-link-active-bg) !important;
                }
            }
        }
    }
    }
`;
const TitleWrap = styled.div`
    display: flex;
    align-items: center;
    img {
        margin-right: 10px;
    }
`;
const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        overflow-y: scroll;
        height: 500px;
        .p-30 {
            padding: 30px;
            min-height: 477px;
            @media(min-width: 1600px) {
                min-height: 567px;
            }
        }
        .table-wrap {
            h2 {
                font-size: 18px;
                color: #3b3b3b;
                font-weight: 500;
                margin-bottom: 15px;
            }
        }
    }
    @media (max-width: 1200px) {
        .table-wrap{
            overflow: scroll;
        }
    }
`;

export default function CmmnSysCnfModal({
    show,
    onHide,
    data,
    brchClsfCd,
    t,
}) {
    const tabs = [
        { key: "system", title: t("tabTitle.lblSystem") },
        { key: "program", title: t("tabTitle.lblProgram") },
        ...(brchClsfCd === "KSC" ? [{ key: "smart", title: t("tabTitle.lblAILab") }] : []),
    ];
    // 기기모드
    const systemModeArray = [
        { key: "STANDARD", value: "S1" },
        { key: "STANDARD-ENT", value: "S2" },
        { key: "HIGH-ANGLE", value: "H1" },
        { key: "HIGH-ANGLE Built-In", value: "H2" },
    ];

    const [activekey, setActivekey] = useState(tabs[0].key);
    const [systemMode, setSystemMode] = useState("");
    const [kiskInfo, setKiskInfo] = useState({});
    const [loading, setLoading] = useState(false);
    let timer = null;
    const modalRef = useRef(null);

    // 장치관리 설정 조회
    const getKiskInfo = () => {
        const fetchData = () => {
            callApi("GET", null, `/web/api/dvc-mgt/edm/kisk-info?eqmtNo=${data.eqmtNo}`)
                .then(res => {
                    if (Object.keys(res).length === 0) {
                        console.log("데이터 없음 5초 후 다시 호출");
                        setLoading(true);
                        timer = setTimeout(fetchData, 5000);
                    } else {
                        console.log("response : ", res);
                        
                        setKiskInfo(res);
                        setLoading(false);
                        const selectedMode = systemModeArray.find((mode) => mode.value === res.sysStupCn.systemMode);
                        if (selectedMode) {
                            setSystemMode(selectedMode);
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        };
        fetchData();
    };

    useEffect(() => {
        if (show) {
            setActivekey(tabs[0].key);
            getKiskInfo(timer);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [show]);
    // 탭 전환 시 맨 위로 이동
    const handleTabSelect = (k) => {
        setActivekey(k);
        if (modalRef.current) {
            modalRef.current.scrollTop = 0;
        }
    };

    return (
        <ModalWrap show={show} onHide={onHide} centered>
            <Modal.Header>
                <TitleWrap>
                    <img src={titleLogo} alt={titleLogo} />
                    <h2>{data && data !== undefined ? `${data.eqmtNm} ${t("deviceMgtPage.config")}` : ""}</h2>
                </TitleWrap>
                <CloseButton onClick={onHide} />
            </Modal.Header>
            <Modal.Body>
                <Tab.Container activeKey={activekey} onSelect={handleTabSelect}>
                    <Nav variant="tabs" as="ul">
                        {tabs.map((tab, idx) => (
                            <Nav.Item as="li" key={idx}>
                                <Nav.Link eventKey={tab.key} as="button">
                                    {tab.title}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Tab.Container>
                {loading ?
                    <LoadingStyle>
                        <div style={{height: "60px"}}>
                            <h2>Loading...</h2>
                        </div>
                        <div style={{height: "50px"}}>
                            <SyncLoader />
                        </div>
                    </LoadingStyle>
                    :
                    <DashBoardWrap>
                        <div className="dashboard-box" ref={modalRef}>
                            <Tab.Container activeKey={activekey} onSelect={handleTabSelect}>
                                <Tab.Content>
                                    {tabs.map((tab, index) => (
                                        <Tab.Pane key={index} eventKey={tab.key}>
                                            {tab.key === "system" && (
                                                <SystemConfig
                                                    t={t}
                                                    brchClsfCd={brchClsfCd}
                                                    systemModeArray={systemModeArray}
                                                    kiskInfo={kiskInfo}
                                                    data={data}
                                                    onHide={onHide}
                                                    getKiskInfo={getKiskInfo}
                                                />
                                            )}
                                            {tab.key === "program" && (
                                                <ProgramConfig
                                                    t={t}
                                                    brchClsfCd={brchClsfCd}
                                                    systemMode={systemMode}
                                                    kiskInfo={kiskInfo}
                                                    onHide={onHide}
                                                    data={data}
                                                    getKiskInfo={getKiskInfo}
                                                />
                                            )}
                                            {tab.key === "smart" && (
                                                <SmartCorrectionConfig
                                                    t={t}
                                                    brchClsfCd={brchClsfCd}
                                                    kiskInfo={kiskInfo}
                                                    onHide={onHide}
                                                    data={data}
                                                    getKiskInfo={getKiskInfo}
                                                />
                                            )}
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </DashBoardWrap>
                }
            </Modal.Body>
        </ModalWrap>
    )
}