import styled from "styled-components";
import CmmnRadioBox from "./CmmnRadio";
import { useEffect, useState } from "react";
import SelectBox from "components/web/UI/SelectBox";
import ButtonUtil from "../UI/ButtonUtil";
import { callApi } from "util/HttpService";
import { alert, modalConfirm } from "components/web/cmmn/CmmnConfirm";

const ListInputWrap = styled.ul`
    border-top: 2px solid #EF2929;
    height: 100%;
    .basic-input {
        padding: 10px;
        font-size: 16px;
        color: #3b3b3b;
        border: 1px solid #D3D3D3;
        border-radius: 4px;
        height: 35px;
    }
    &:focus {
        border-color: #FFA500 !important;
    }
    .modal-title {
        margin-bottom: 10px;
        background-color: #fff;
        padding: 15px 20px 0px 0px;
        h2 {
            font-size: 17px;
            font-weight: 600;
        }
    }
    .frame-input {
        div {
            width: 50% !important;
            input {
                width: 100% !important;
            }
        }
        select {
            width: 70% !important;
        }
    }
`;
const InputTitle = styled.div`
    height: 54px;
    padding: 15px 20px;
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h2 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
    }
`;
const UseYnContent = styled.div`
    background-color: #fff;
    padding: 10px 20px 10px 20px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 67.6px;
    input[type="text"]:focus {
        border-color: #FFA500;
    }
    h2 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        width: 30%;
    }
    ul {
        width: 70%;
        justify-content: space-around;
    }
`;
const InputContent = styled.div`
    background-color: #fff;
    padding: 10px 20px 10px 20px;
    & > div {
        margin-bottom: 10px;
    }
    h3 {
        font-size: 20px;
        color: #3b3b3b;
        font-weight: 500;
        margin-right: 10px;
    }
    h2 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        width: 30%;
    }
    h5 {
        margin-left: 7px;
    }
    input[type="text"]:focus {
        border-color: #FFA500;
    }
`;
const BtnWrap = styled.div`
    width: 100%;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
        width: 32%;
    }
`;
export default function MbSmartCorrectionConfig({
    t,
    brchClsfCd,
    onHide,
    kiskInfo,
    data,
    getKiskInfo,
}) {
    const [aiLabSettings, setAiLabSettings] = useState({});

    useEffect(() => {
        if (kiskInfo && kiskInfo.ailbStupCn) {
            setAiLabSettings(kiskInfo.ailbStupCn);
        }
    }, [kiskInfo]);

    useEffect(() => {
        console.log(aiLabSettings);
    }, [aiLabSettings]);

    const handleSelectChange = (e, section, key) => {
        const value = e.target.value;
        setAiLabSettings(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [key]: value
            }
        }));
    };

    const handleRadioChange = (value) => {
        setAiLabSettings(prevState => ({
            ...prevState,
            useYn: value
        }));
    };

    // 사용여부
    const useYnDataArr = [
        { value: "Y", label: t("userMgtPage.use") },
        { value: "N", label: t("userMgtPage.unused") },
    ];
    // Sharp 파라미터, Smooth 파라미터, White 파라미터
    const parameterArray = Array.from({ length: 10 }, (_, i) => {
        const value = (i + 1) / 10;
        return {
            key: value === 1 ? "1.0" : value,
            value: value === 1 ? "1.0" : value,
        };
    });

    const handleCmmnBtnClick = async (btnNm) => {
        switch (btnNm) {
            case "cmmnInsertBtn":
                try {
                    const commonParams = { brchId: data.brchId, eqmtId: data.eqmtId, eqmtNo: data.eqmtNo, delYn: 'N' };

                    // aiLabSettings
                    const aiLabParams = { ...commonParams, ailbStupCn: aiLabSettings };
                    await callApi("POST", aiLabParams, "/web/api/dvc-mgt/edm/kisk-insert");
                    console.log("전송된 aiLabSettings : ", aiLabParams);

                    alert("", t("alert.save"), () => { getKiskInfo() });

                } catch (error) {
                    console.log(error);
                    alert("", t("alert.saveError"), () => { return });
                }
                break;
            case "cmmnResetBtn":
                try {
                    modalConfirm("", t("alert.resetConfirm"), () => {
                        setAiLabSettings(kiskInfo.ailbStupCn);
                        alert("", t("alert.initSuccessAlert"), () => { });
                    })
                } catch (error) {
                    console.log(error);
                    alert("", t("alert.initFailAlert"), () => { });
                }
                break;
            default: break;
        };
    };

    return (
        <>
            <ListInputWrap>
                <li>
                    <InputTitle>
                        <h2>{t("aiLabBaseSetting.title")}</h2>
                    </InputTitle>
                    <UseYnContent>
                        <div className="dp-flex align-cen space-between w-100 frame-input">
                            <h2>{t("aiLabBaseSetting.lblUseYn")}</h2>
                            <CmmnRadioBox
                                dataArray={useYnDataArr}
                                defaultVal={aiLabSettings?.useYn || "N"}
                                tagName={"useYn"}
                                onSelectChange={(e) => handleRadioChange(e.target.value)}
                            />
                        </div>
                    </UseYnContent>
                </li>
                <li>
                    <InputTitle>
                        <h2>{t("aiLabFaceBeautySetting.title")}</h2>
                    </InputTitle>
                    <InputContent>
                        <div className="dp-flex align-cen space-between w-100 frame-input">
                            <h2>{t("aiLabFaceBeautySetting.lblSharp")}</h2>
                            <SelectBox
                                defaultOptTxt={t("saleHistPage.entire")}
                                dataArray={parameterArray}
                                onSelectChange={(e) => handleSelectChange(e, 'faceBeauty', 'sharp')}
                                tagName="sharp"
                                defaultVal={aiLabSettings?.faceBeauty?.sharp || 0}
                            />
                        </div>
                        <div className="dp-flex align-cen space-between w-100 frame-input">
                            <h2>{t("aiLabFaceBeautySetting.lblSmooth")}</h2>
                            <SelectBox
                                defaultOptTxt={t("saleHistPage.entire")}
                                dataArray={parameterArray}
                                onSelectChange={(e) => handleSelectChange(e, 'faceBeauty', 'smooth')}
                                tagName="smooth"
                                defaultVal={aiLabSettings?.faceBeauty?.smooth || 0}
                            />
                        </div>
                        <div className="dp-flex align-cen space-between w-100 frame-input">
                            <h2>{t("aiLabFaceBeautySetting.lblWhite")}</h2>
                            <SelectBox
                                defaultOptTxt={t("saleHistPage.entire")}
                                dataArray={parameterArray}
                                onSelectChange={(e) => handleSelectChange(e, 'faceBeauty', 'white')}
                                tagName="white"
                                defaultVal={aiLabSettings?.faceBeauty?.white || 0}
                            />
                        </div>
                    </InputContent>
                </li>
            </ListInputWrap>
            <BtnWrap>
                <ButtonUtil className="btn btn-danger round-filled-small-btn"
                    value={t("common.lblInitBtn")}
                    onClick={() => { handleCmmnBtnClick("cmmnResetBtn") }}
                />
                <ButtonUtil className="btn btn-outline-light round-solid-medium-btn"
                    value={t("common.cancelLable")}
                    onClick={() => { onHide() }}
                />
                <ButtonUtil className="btn btn-danger round-filled-small-btn"
                    value={t("common.saveLable")}
                    onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                />
            </BtnWrap>
        </>
    )
}