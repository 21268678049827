import styled from "styled-components";
import React, { cloneElement, useState } from "react";
import { Nav, Tab } from "react-bootstrap";

const DashBoardBox = styled.div`
    background-color: #fff;
    border-radius: 16px;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
`;
const Flex = styled.div`
    display: flex;
    align-items : center;
`;
const MenuTitleWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white1};
    width: 100%;
    height: 72px;
    h3 {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: 500;
        margin-right: 10px;
    }
    h5 {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.gray6};
        font-weight: 400;
    }
    .nav-tabs {
        border: none;
        .nav-item {
            margin-right: 5px;
            &:last-child {
                margin: 0;
            }
        .nav-link {
            border: none;
            font-size: 14px;
            background-color: ${({ theme }) => theme.colors.gray5};
            border: 1px solid ${({ theme }) => theme.colors.gray5};
            color: ${({ theme }) => theme.colors.gray6};
            border-radius: 100px;
            &.active {
                border: 1px solid ${({ theme }) => theme.colors.red};
                border-radius: 100px;
                color: ${({ theme }) => theme.colors.red};
                font-size: 14px;
                background-color: var(--bs-nav-tabs-link-active-bg) !important;
            }
        }
        }
    }
`;
const ContentWrap = styled.div`
    padding: 20px;
    .graph {
        width: 100%;
        height: 250px;
        background-color: ${({ theme }) => theme.colors.gray6};
    }
`;

export default function CmmnGraphBox({ title, tabs, graphComponents, subTitle }) {

    const [activekey, setActivekey] = useState(0);

    const handleSelect = (key) => {
        setActivekey(key);
    };

    return (
        <DashBoardBox>
            <MenuTitleWrap>
                <Flex>
                    <h3>{title}</h3>
                    <h5>{subTitle}</h5>
                </Flex>
                {tabs.length > 1 && (
                    <Tab.Container activeKey={activekey} onSelect={handleSelect}>
                        <Nav variant="tabs" as="ul">
                            {tabs.map((tab, idx) => (
                                <Nav.Item as="li" key={idx}>
                                    <Nav.Link eventKey={idx} as="button">
                                        {tab}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Tab.Container>
                )}
            </MenuTitleWrap>
            <ContentWrap>
                <Tab.Container activeKey={activekey} onSelect={handleSelect}>
                    <Tab.Content>
                        {tabs.map((tab, index) => (
                            <Tab.Pane key={index} eventKey={index}>
                                {/* 탭이 전환될 때 마다 그래프 리렌더링 */}
                                {cloneElement(graphComponents[index], { key: activekey })}
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Tab.Container>
            </ContentWrap>
        </DashBoardBox>
    )
}