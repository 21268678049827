'use client'

import React, { forwardRef, useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import ReactDatePicker from 'react-datepicker';
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/locale';
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

const DateWrap = styled.div`
    display: flex;
    align-items: center;
    .startDatePopper {
        transform: translate(9px, 47.5px) !important;
    }
    .endDatePopper {
        transform: translate(-179.5px, 47.5px) !important;
    }
    .slash {
        font-size: 20px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.dark2};
        margin: 0 10px;
    }
    .react-datepicker__triangle {
        display: none;
    }
    .react-datepicker__navigation {
        line-height: 0rem;
    }
    .react-datepicker__header {
        padding: 1.1rem 0;
        border-bottom: 1px solid #dedede;
        background-color: #fff;
        .react-datepicker__current-month {
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1.3rem;
        }
        .react-datepicker__day-names {
            margin-botton: -13px;
            margin-top: 4px;
            .react-datepicker__day-name {
                width: 2.5rem;
                line-height: 2.5rem;
                font-size: 1.1rem;
                color: #999;
            }
        }
    }
    .react-datepicker__month-container {
        float: inherit;
    }
    .react-datepicker__month {
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            line-height: 2.5rem;
            width: 2.5rem;
            font-size: 1.1rem;
        }
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background-color: ${({ theme }) => theme.colors.red};
    }
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range {
        background-color: #8172d4;
    }
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
        width: 6px;
        height: 6px;
        border-width: 2px 2px 0 0;
        border-color: #999;
        top: 8px;
    }
    .react-datepicker__day--outside-month {
        color: #999;
    }
`;
const webDateWrap = styled.div`
    display: flex;
    align-items: center;
    width: 380px;
    .slash {
        font-size: 20px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.dark2};
        margin: 0 10px;
    }
    .btn {
        width: auto !important;
        margin-left: 10px;
    }
    .react-datepicker__triangle {
        display: none;
    }
    .react-datepicker__navigation {
        line-height: 0rem;
    }
    .react-datepicker__header {
        padding: 1rem 0;
        border-bottom: 1px solid #dedede;
        background-color: #fff;
        .react-datepicker__current-month {
            font-size: 1.0rem;
            font-weight: 500;
            line-height: 1.3rem;
        }
        .react-datepicker__day-names {
            margin-top: 5px;
            .react-datepicker__day-name {
                width: 2rem;
                line-height: 1.5rem;
                font-size: 0.9rem;
                color: #999;
            }
        }
    }
    .react-datepicker__month-container {
        float: inherit;
    }
    .react-datepicker__month {
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            line-height: 2rem;
            width: 2rem;
            font-size: 0.9rem;
        }
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background-color: ${({ theme }) => theme.colors.red};
    }
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range {
        background-color: #8172d4;
    }
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
        width: 6px;
        height: 6px;
        border-width: 2px 2px 0 0;
        border-color: #999;
        top: 8px;
    }
    .react-datepicker__day--outside-month {
        color: #999;
    }
}
`;
const CustomFormControl = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;
const CustomInput = forwardRef(({ isMobile, ...props }, ref) => {
    return (
        <CustomFormControl>
            <InputGroup>
                <Form.Control {...props} ref={ref} readOnly={isMobile} />
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faCalendar} />
                </InputGroup.Text>
            </InputGroup>
        </CustomFormControl>
    );
});

function CustomDatePicker(
    {
        className,
        onSelectChange,
        defaultStartDate,
        defaultEndDate,
        isMobile,
        children,
    }
) {
    library.add(faCalendar);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [isStartDate, setIsStartDate] = useState(false);
    const [isEndDate, setIsEndDate] = useState(false);

    useEffect(() => {
        if (defaultStartDate === '') {
            setStartDate('');
        } else {
            setStartDate(defaultStartDate);
        }
    }, [defaultStartDate]);

    useEffect(() => {
        if (defaultEndDate === '') {
            setEndDate('');
        } else {
            setEndDate(defaultEndDate);
        }
    }, [defaultEndDate]);

    const handleSelectChange = (date, name) => {
        onSelectChange(date, name);
    }

    const Wrapper = isMobile ? DateWrap : webDateWrap;

    // useEffect(() => {
    //     console.log(isStartDate);
    // }, [isStartDate])

    return (
        <Wrapper className={className}>
            <InputGroup>
                <label htmlFor="startDate">
                    <ReactDatePicker
                        id="startDate"
                        selected={startDate}
                        onChange={(date) => {
                            setStartDate(date);
                            handleSelectChange(date, 'startDate');
                        }}
                        onInputClick={() => setIsStartDate(!isStartDate)}
                        onClickOutside={() => setIsStartDate(false)}
                        open={isStartDate}
                        // locale={ko}
                        dateFormat="yyyy-MM-dd"
                        dateFormatCalendar="yyyy-MM"
                        dropdownMode="select"
                        popperClassName="startDatePopper"
                        selectsStart
                        customInput={<CustomInput isMobile={isMobile} />}
                    />
                </label>
            </InputGroup>
            <span className="slash">-</span>
            <InputGroup>
                <label htmlFor="endDate">
                    <ReactDatePicker
                        id="endDate"
                        selected={endDate}
                        onChange={(date) => {
                            setEndDate(date);
                            handleSelectChange(date, 'endDate');
                        }}
                        onInputClick={() => setIsEndDate(!isEndDate)}
                        onClickOutside={() => setIsEndDate(false)}
                        open={isEndDate}
                        // locale={ko}
                        dateFormat="yyyy-MM-dd"
                        dateFormatCalendar="yyyy-MM"
                        dropdownMode="select"
                        popperClassName="endDatePopper"
                        selectsEnd
                        customInput={<CustomInput isMobile={isMobile} />}
                    />
                </label>
            </InputGroup>
            {children}
        </Wrapper>
    )
}
export default CustomDatePicker;