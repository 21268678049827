'use client'

import styled from "styled-components";
import navLogo from "asset/photomag_web/img/logo_white.svg"
import LeftMenu from "./LeftMenu2";

const SideNavWrap = styled.nav`
    height: 100vh;
    background-color: #161611;
    padding: 0 20px;
    width: 18%;
    box-shadow: 0 4px 10px rgba(0,0,0,.2);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 98;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1500px) {
        width: 20%;
    }
`;
const SideNavHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    .logo img {
        width: 130px;
    }
`;

function Gnb() {

    return (
        <SideNavWrap>
            <div>
                <SideNavHeader>
                    <div className="logo">
                        <img src={navLogo} alt="nav_logo" />
                    </div>
                </SideNavHeader>
                <LeftMenu />
            </div>
        </SideNavWrap>
    )


}
export default Gnb;