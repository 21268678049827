import axios from "axios";
import { alert } from "components/web/cmmn/CmmnConfirm";
import { jwtDecode } from "jwt-decode";
import i18n from "locales/i18n.ts";

export async function callApi(HTTPmethod, apiBody, uri, contentType) {
    const baseApiUrl = process.env.REACT_APP_API_BASE_URL;
    const defaultContentType = contentType ? contentType : "application/json";
    const userAgent = window.navigator.userAgent;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    try {

        let accessToken = localStorage.getItem("accessToken");

        if (!accessToken) {
            throw new Error('No token found');
        }

        const currentTimestamp = Date.now();
        const decodedToken = jwtDecode(accessToken);
        const expirationTimestamp = decodedToken.exp * 1000;

        if (currentTimestamp >= expirationTimestamp) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userSqno");
            localStorage.removeItem("userNm");
            localStorage.removeItem("brchId");
            
            // Redirect to login page
            alert("", i18n.t("alert.tokenMsg"), () => {
                window.location.replace(isMobile ? "/mobile/login" : "/login");
                return;
            })
        }

        const headers = {
            "Content-Type": defaultContentType,
            "Authorization": `Bearer ${accessToken}`
        };

        let requestConfig = {
            method: HTTPmethod,
            url: baseApiUrl + uri,
            headers: headers,
        };

        if (HTTPmethod === "GET" && apiBody) {
            requestConfig.params = apiBody;
        } else {
            requestConfig.data = apiBody;
        }

        const res = await axios(requestConfig);

        if (res.status === 404) {
            alert("", i18n.t("alert.noPage"), () => {});
        } else if (res.status === 401) {
            alert("", i18n.t("alert.noAthr"), () => {});
        } else if (res.status >= 200 && res.status < 300) {
            if (res.status != null) {
                return res.data;
            } else {
                if (res.data.data) {
                    const errorCode = res.data.data.errorCode;
                    const errorMessage = res.data.data.errorMessage;

                    console.log(errorCode);
                    console.log(errorMessage);
                }
            }
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function callLoginApi (HTTPmethod, apiBody, uri, contentType) {

    const defaultContentType = contentType?contentType:"application/json";

    try{
        const headers = {
            "Content-Type" : defaultContentType
        }

        let requestConfig = {
            method: HTTPmethod,
            url: uri,
            headers: headers,
            withCredentials: true,
        };
    
        // If the HTTP method is GET, convert apiBody to query parameters
        if (HTTPmethod === "GET" && apiBody) {
            requestConfig.params = apiBody;
        } else {
            requestConfig.data = apiBody;
        }
    
        return (await axios(requestConfig)
    
        .then(res => {
            if (res.status === 404) {
                alert("", i18n.t("alert.noPage"), () => {});
            }else if(res.status === 401){
                alert("", i18n.t("alert.noAthr"), () => {});
                
            }
            else if (res.status >= 200 && res.status < 300) {

                return res.data;

            }
        })
            .catch(error => {
                console.log(error);
                return;
               
            }));
    }catch(error){
        
    }
}
