import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartBox = styled.div`
    width: 100%;
    height: 250px;
    // padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const EqmtByTypeChart = React.memo(({ salesData, text, unit, label }) => {

    const {t} = useTranslation();
    const everyData = salesData.every(data => data.amount === 0);
    const unitTitle = unit === undefined ? '' : unit;
    const title = text === undefined ? '' : text;
    
    const options = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false, //부모의 크기에 맞춤
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    font: {
                        size: 12,
                    },
                    boxWidth: 6,
                    boxHeight: 6,
                },
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const dataIndex = context.dataIndex;
                        const salesItem = salesData[dataIndex];
                        return `${label}:${unitTitle} ${salesItem.amount.toLocaleString()}${title}(${salesItem.ratio}%)`;
                    }
                }
            }
        },
        animation: {
            animateScale: true, // 크기 확대 애니메이션
            animateRotate: true // 회전 애니메이션
        }
    }), [salesData, unitTitle, title, label]);

    const data = useMemo(() => {
        if (everyData) {
            return {
                labels: salesData.map(data => data.label),
                datasets: [
                    {
                        label: t("saleHistPage.sale"),
                        data: salesData.map(() => 1),
                        backgroundColor: salesData.map(() => "#B0B0B0"),
                        borderWidth: 0,
                    },
                ],
            };
        } else {
            return {
                labels: salesData.map(data => data.label),
                datasets: [
                    {
                        label: t("saleHistPage.sale"),
                        data: salesData.map(data => data.amount),
                        backgroundColor: salesData.map(data => data.backgroundColor),
                        borderWidth: 0,
                    },
                ],
            };
        }
    }, [salesData, everyData, t]);

    return (
        <ChartBox>
            <Doughnut options={options} data={data} />
        </ChartBox>
    );
});

export default EqmtByTypeChart;
