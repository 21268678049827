'use client'

import { alert } from './CmmnConfirm';
import TextFields from 'components/web/UI/TextFields';
import { useEffect, useState } from 'react';
import { callApi } from 'util/HttpService';
import { CloseButton, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import ButtonUtil from 'components/mobile/UI/ButtonUtil';

const ModalStyle = styled(Modal)`
    .modal-header {
        border-bottom: none;
        border-bottom: 1px solid rgba(0,0,0,.1);
        h2 {
            font-size: 18px;
            color: #3b3b3b;
            font-weight: 500;
        }
    }
    .modal-body {
        padding: 30px 0;
        h3 {
            font-size: 16px;
            color: #3b3b3b;
            text-align: center;
            line-height: 150%;
        }
        .pr-20{
            padding-right: 20px;
        }
        .pl-20{
            padding-left: 20px;
        }
        .pb-0{
            padding-bottom: 0 !important;
        }
    }
    .modal-btn {
        padding: 0 20px 20px 20px;
    } 
    .round-filled-small-btn:hover {
        color: var(--bs-btn-hover-color) !important;
        background-color: var(--bs-btn-hover-bg) !important;
        border-color: var(--bs-btn-hover-border-color) !important;
    }
`;
const InputTable = styled.div`
    margin-bottom: 20px;
    border-top: 2px solid #EF2929;
    .input-table-th {
        background-color: #F3F3F3;
        padding: 20px;
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 78px;
        h3 {
            font-size: 16px;
            color: #3b3b3b;
            font-weight: 500;
            line-height: 150%;
            text-align: center;
        }
    }
    .input-table-th-small{
        max-width: 138px;
        min-width: 138px;
    }
    .input-table-wrap{
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
    .input-table-body{
        display: flex;
        align-items: flex-start;
        padding: 15px;
        width: 100%;
        h4 {
            font-size: 16px;
            color: #3b3b3b;
            font-weight: 400;
        }
    }
    input[type="text"] {
        max-width: 160px;
        width: 160px;
    }
    .h-2row {
        min-height: 112px !important;
    }
    .dp-flex {
        display: flex;
    }
    .align-cen{
        align-items: center;
    }
    .h-113 {
        height: 113px;
    }
`;
const ModalBtnWrap = styled.div`
    padding: 0 20px 20px 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 48% !important;
    }
    .round-solid-medium-btn, .bottom-modal-close-btn {
        font-size: 14px !important;
        padding: 10px 20px !important;
    }
    .bottom-modal-close-btn:hover {
        color: var(--bs-btn-hover-color) !important;
        background-color: var(--bs-btn-hover-bg) !important;
        border-color: var(--bs-btn-hover-border-color) !important;
    }
`;
const CrdWrap = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 25px;
    height: 35px;
    &:last-child {
        width: 80px;
        height: 35px;
        display: flex;
        justify-content: flex-start;
    }
    h4 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
    }
`;
const ChargeInputWrap = styled.div`
    & > div:last-child {
        margin-top: 20px;
    }
    & > div:first-child {
        .btn:nth-child(2),
        .btn:nth-child(3),
        .btn:nth-child(4){
            margin-left: 10px;
        }
    }
`;

export default function CmmnCrdChargeModal(
    {
        show,
        onHide,
        data,
        t,
        getBrchCrdInfo,
        getBrchAllList = () => { },
        setStat,
        stat,
    }) { 

    const defaultBrchInfo = {
        brchId: null,
        flngCrdCnt: 0,
    }

    const [searchData, setSearchData] = useState(defaultBrchInfo);

    useEffect(() => {
        if (show) {
            setSearchData(defaultBrchInfo);
        }
    }, [show]);

    const handleButtonClick = (incrementValue) => {
        setSearchData(prevData => ({
            ...prevData,
            flngCrdCnt: prevData.flngCrdCnt + incrementValue
        }));
    };

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/,/g, '');
        setSearchData((prevData) => ({
            ...prevData,
            [name]: isNaN(numericValue) ? 0 : parseInt(numericValue, 10)
        }));
    };

    const formatNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnInsertBtn":
                const formattedFlngCrdCnt = searchData.flngCrdCnt.toString().replace(/,/g, '');
                callApi("POST", null, `/web/api/svc-mgt/brch/crd-insert?brchId=${data.brchId}&flngCrdCnt=${formattedFlngCrdCnt}`)
                    .then(res => {
                        onHide();
                        alert("", t("alert.crdPmtCpt"), () => {
                            getBrchCrdInfo();
                            getBrchAllList();
                            setStat(!stat);
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        onHide();
                        alert("", t("alert.crdPmtFail"), () => { });
                        return;
                    })
                break;
            case "cmmnListBtn":
                onHide();
                break;
            case "cmmnResetBtn":
                setSearchData(defaultBrchInfo);
                break;
            default: break;
        }
    }

    return (
        <ModalStyle show={show} onHide={onHide} centered>
            <Modal.Header>
                <h2>{`${t("brchMgtPage.credit")} ${t("brchMgtPage.charge")}`}</h2>
                <CloseButton onClick={onHide} />
            </Modal.Header>
            <Modal.Body>
                <div className="pr-20 pl-20">
                    <InputTable>
                        <div className='input-table-wrap'>
                            <div className='input-table-th input-table-th-small'>
                                <h3>{t("brchMgtPage.brch")}</h3>
                            </div>
                            <div className='input-table-body'>
                                <h4>{data?.brchNm || ""}({t("brchMgtPage.eqmtCount")} {data?.eqmtCount || 0}{t("brchMgtPage.unit")})</h4>
                            </div>
                        </div>
                        <div className='input-table-wrap'>
                            <div className='input-table-th input-table-th-small'>
                                <h3>{t("brchMgtPage.creditHeld")}</h3>
                            </div>
                            <div className='input-table-body'>
                                <TextFields
                                    type="text"
                                    name="possCrdCnt"
                                    onChangeValue={handleFormDataChange}
                                    value={data && data.possCrdCnt !== undefined ? data.possCrdCnt.toLocaleString() : "0"}
                                    className="readonly"
                                    disabled={true}
                                />
                                <CrdWrap>
                                    <h4>C</h4>
                                </CrdWrap>
                                <CrdWrap>
                                    {data && data.natnCd === "KR" &&
                                        <h4>(₩{data && data.possCrdCnt !== undefined ? (data.possCrdCnt * 100).toLocaleString() : "0"})</h4>
                                    }
                                    {data && data.natnCd === "JP" &&
                                        <h4>(¥{data && data.possCrdCnt !== undefined ? (data.possCrdCnt * 10).toLocaleString() : "0"})</h4>
                                    }
                                </CrdWrap>
                            </div>
                        </div>
                        <div className='input-table-wrap h-113'>
                            <div className='input-table-th input-table-th-small h-2row'>
                                <h3>{`${t("brchMgtPage.charge")}${t("brchMgtPage.credit")}`}</h3>
                            </div>
                            <div className='input-table-body'>
                                <ChargeInputWrap>
                                    <div className='dp-flex align-cen'>
                                        <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn max-content"
                                            value={"1,000"}
                                            onClick={() => { handleButtonClick(1000) }}
                                        />
                                        <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn max-content"
                                            value={"5,000"}
                                            onClick={() => { handleButtonClick(5000) }}
                                        />
                                        <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn max-content"
                                            value={"10,000"}
                                            onClick={() => { handleButtonClick(10000) }}
                                        />
                                        <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn mr-5"
                                            value={"Reset"}
                                            onClick={() => { handleCmmnBtnClick("cmmnResetBtn") }}
                                        />
                                    </div>
                                    <div className='dp-flex align-cen'>
                                        <TextFields
                                            type="text"
                                            name="flngCrdCnt"
                                            onChangeValue={handleFormDataChange}
                                            value={formatNumber(searchData.flngCrdCnt)}
                                            disabled={true}
                                            className="readonly"
                                        />
                                        <CrdWrap>
                                            <h4>C</h4>
                                        </CrdWrap>
                                        <CrdWrap>
                                            {data && data.natnCd === "KR" &&
                                                <h4>(₩{searchData.flngCrdCnt && searchData.flngCrdCnt !== undefined ? (searchData.flngCrdCnt * 100).toLocaleString() : 0})</h4>
                                            }
                                            {data && data.natnCd === "JP" &&
                                                <h4>(¥{searchData.flngCrdCnt && searchData.flngCrdCnt !== undefined ? (searchData.flngCrdCnt * 10).toLocaleString() : 0})</h4>
                                            }
                                        </CrdWrap>
                                    </div>
                                </ChargeInputWrap>
                            </div>
                        </div>
                    </InputTable>
                </div>
                <ModalBtnWrap>
                    <ButtonUtil className="btn btn-outline-light round-solid-medium-btn"
                        value={t("common.closeLable")}
                        onClick={() => { handleCmmnBtnClick("cmmnListBtn") }}
                    />
                    <ButtonUtil className="btn btn-danger round-filled-btn bottom-modal-close-btn"
                        value={t("saleHistPage.payment")}
                        onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                        disabled={searchData.flngCrdCnt === 0}
                    />
                </ModalBtnWrap>
            </Modal.Body>
        </ModalStyle>
    )

}