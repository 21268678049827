'use client'

import moment from "moment";
import 'moment/locale/ko';

export async function downloadExcelFromServer( uri,params,fileName) {
    const accessToken = localStorage.getItem("accessToken");
    const baseApiUrl = process.env.REACT_APP_API_BASE_URL;
    const headers = {
        
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
       
      };
    
    const requestOptions = {
    method: 'POST', 
    headers: headers,
    body: JSON.stringify({...params})
   
    };

  const excelFileName = fileName + "_" + moment().format('YYYYMMDDHHmmss') + ".xlsx";
    
  
    fetch(baseApiUrl+uri,requestOptions)
    .then(response =>{
        response.blob()
        .then(blob =>{
            // console.log(blob);
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = excelFileName;
            a.click();
        });
        

    });
  
    
  }