'use client'

import styled from "styled-components";
import { useState, useEffect } from "react";
import DeleteToken from "../auth/DeleteToken";
import { useBrch } from "components/web/auth/BrchProvider";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import starsLogo from "asset/photomag_web/img/icon/stars.svg"
import profileLogo from "asset/photomag_web/img/icon/profile.svg"
import LangSelectBox from "components/web/cmmn/LangSelectBox";
import { useTranslation } from "react-i18next";
import HeaderBrchNm from "../cmmn/HeaderBrchNm";
import CmmnCrdChargeModal from "../cmmn/CmmnCrdChargeModal";

const HeaderWrap = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px 50px;
    z-index: 5;
    box-shadow:  0 3px 10px rgba(0,0,0,.05);
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    .btn {
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
    }
    @media (max-width: 1200px) {
        padding: 15px 30px;
    }
    .top-nav-left-box {
        display: flex;
        align-items: center;
        button {
            padding: 7px 15px;
            &:first-of-type {
                margin-right: 10px;
            }
            // &:hover {
            //     color: #fff;
            // }
        }
        @keyframes blink-effect {
            50% {
                opacity: 0;
            }
        }
        .blink-effect {
            animation: blink-effect 1s step-end infinite;
        }
    }
    .top-nav-right-box{
        display: flex;
        align-items: center;
        width: 48%;
        .logout-btn {
            padding: 4px 0px;
        }
        .form-select {
            min-width: 150px;
        }
        .name-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 15px;
            img {
                width: 25px;
            }
            h3 {
                margin-left: 10px;
                color: ${({ theme }) => theme.colors.dark2};
                font-size: 16px;
                font-weight: 500;
                min-width: max-content;
                span {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
        .mr-5 {
            margin-right:5px;
        }
    }
`;

function Header() {
    const { t } = useTranslation();
    const { brchId, changeBrchId, crdInfo, getBrchCrdInfo, getBrchAllList, crdStat, setStat, stat } = useBrch();
    const brchClsfCd = localStorage.getItem("brchClsfCd");
    const locBrchId = localStorage.getItem("brchId");
    const userNm = localStorage.getItem("userNm");
    const userSqno = localStorage.getItem("userSqno");

    const [chargeModalOpen, setChargeModalOpen] = useState(false);
    const [chargeModalData, setChargeModalData] = useState(null);
    const openChargeModal = (data) => {
        setChargeModalOpen(true);
        setChargeModalData(data);
    };
    useEffect(() => {
        getBrchCrdInfo();
    }, [brchId]);

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        if (name === "brchId") {
            changeBrchId(value);
        }
    };

    const blinkEffect = (crdStat[0] == null || crdStat[0] === undefined || crdStat[0] <= 150) ? "blink-effect" : "";
    
    return (
        <HeaderWrap>
            <div className="top-nav-left-box">
                <ButtonUtil
                    className="btn btn-danger round-filled-small-btn"
                    onClick={() => { }}
                >
                    <img src={starsLogo} alt="stars_logo" className={blinkEffect} />
                    {t("header.creditHeld")}: {crdStat[0] !== undefined || crdStat[0] != null ? crdStat[0].toLocaleString() : 0}
                </ButtonUtil>
                <ButtonUtil
                    className="btn round-solid-small-btn btn-outline-secondary"
                    value={t("header.chargeIt")}
                    onClick={() => {openChargeModal(crdInfo)}}
                    disabled={true}
                />
            </div>
            <div className="top-nav-right-box">
                <LangSelectBox
                    className="form-select-md mr-5"
                    tagName={"language"}
                />
                {brchClsfCd === "KSC" &&
                    <HeaderBrchNm
                        defaultVal={brchId}
                        tagName={"brchId"}
                        defaultOptTxt={t("header.brchSelect")}
                        onSelectChange={handleFormDataChange}
                        brchClsfCd={"KSC"}
                    />
                }
                {brchClsfCd === "FCC" &&
                    <HeaderBrchNm
                        defaultVal={brchId}
                        tagName={"brchId"}
                        defaultOptTxt={t("header.brchSelect")}
                        onSelectChange={handleFormDataChange}
                        brchClsfCd={"FCC"}
                        brchId={locBrchId}
                    />
                }
                {brchClsfCd === "JMC" &&
                    <HeaderBrchNm
                        defaultVal={brchId}
                        tagName={"brchId"}
                        defaultOptTxt={t("header.brchSelect")}
                        onSelectChange={handleFormDataChange}
                        brchClsfCd={"JMC"}
                        brchId={locBrchId}
                        disabled={brchClsfCd === "JMC"}
                    />
                }
                <div className="name-box">
                    <img src={profileLogo} alt="profile_logo" />
                    <h3>{userNm}</h3>
                </div>
                <ButtonUtil className="btn round-solid-small-btn btn-outline-secondary logout-btn"
                    value={t("header.logout")}
                    onClick={() => { DeleteToken(userSqno) }}
                />
            </div>
            <CmmnCrdChargeModal
                show={chargeModalOpen}
                data={chargeModalData}
                onHide={() => setChargeModalOpen(false)}
                getBrchCrdInfo={getBrchCrdInfo}
                getBrchAllList={getBrchAllList}
                setStat={setStat}
                stat={stat}
                t={t}
            />
        </HeaderWrap>
    )
}

export default Header;