'use client'

import { Accordion } from "react-bootstrap";

export default function CmmnAccordionItem({ eventKey, header, children }) {

    return (
        <Accordion.Item eventKey={eventKey}>
            <Accordion.Header>{header}</Accordion.Header>
            <Accordion.Body>
                {children}
            </Accordion.Body>
        </Accordion.Item>
    );

}