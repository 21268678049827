'use client'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faXmark, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import TextFields from "components/mobile/UI/TextFields";
import { alert } from "components/web/cmmn/CmmnConfirm";
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components"
import { callApi } from "util/HttpService";
import * as Yup from 'yup';

const ModalWrap = styled.div`
    background-color: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    visibility: hidden;
    transform: translateY(100%);
    transition: transform 0.4s, visibility 0.4s;
    ${(props) =>
        props.$isVisible &&
        css`
            visibility: visible;
            transform: translateY(0);
        `
    }
`;
const ModalHeader = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D3D3D3;
    h2 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
    }
    .close-i {
        font-size: 18px;
    }
    button {
        background-color: #fff;
        i {
            font-size: 16px;
            color: #3b3b3b;
        }
    }
`;
const ModalContent = styled.div`
    padding: 20px;
    div:last-child {
        margin: 0 !important;
    }
`;
const ModalBtnWrap = styled.div`
    padding: 0 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 48% !important;
    }
`;
const DropBackground = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    z-index: 5;
`;

export default function CmmnPwChangeModal({
    isPwdModal,
    setIsPwdModal,
    t,
    onHide,
}) {
    
    library.add(faXmark, faEye, faEyeSlash);
    const userSqno = localStorage.getItem("userSqno");
    const gnbRef = useRef(null);
    const [showPwd, setShowPwd] = useState(false);
    const [showPwdCheck, setShowPwdCheck] = useState(false);

    const defaultFormData = {
        userPwd: "",
        userNewPwd: "",
    }

    const [formData, setFormData] = useState(defaultFormData);
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false); // 서브밋 여부
    const [isVisible, setIsVisible] = useState(false);

    const validationSchema = Yup.object().shape({
        userPwd: Yup.string().required(t("m_alert.nowPwdInput")),
        userNewPwd: Yup.string().required(t("m_alert.newPwdInput"))
    });

    useEffect(() => {
        if (isPwdModal) {
            setFormData(defaultFormData);
        }
    }, [isPwdModal]);
    useEffect(() => {
        if (isPwdModal) {
            setIsVisible(true);
            document.body.style.overflow = "hidden";
        } else {
            setIsVisible(false);
            document.body.style.overflow = "unset";
        }
        return () => {
            document.body.style.overflow = "unset";
        }
    }, [isPwdModal]);

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (value !== '') {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const handleCmmnBtnClick = async (btnNm) => {
        switch (btnNm) {
            case "cmmnUpdtBtn":
                const isValid = await callApi("POST", null, `/web/api/svc-mgt/user/mobile/pwdCheck?userSqno=${userSqno}&userPwd=${formData.userPwd}`);
                if (!isValid) {
                    alert("", t("m_alert.noMatchPwd"), () => { setIsPwdModal(true); });
                } else {
                    setSubmitted(true);
                    callApi("POST", { userNewPwd: formData.userNewPwd }, `/web/api/svc-mgt/user/mobile/update`)
                        .then((res) => {
                            if (res) {
                                console.log(res);
                                alert("", t("m_alert.pwdChange"), () => { 
                                    onHide();
                                    setFormData(defaultFormData);
                                });
                                document.body.style.overflow = "unset";
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
                break;
            default: break;
        }
    }
    const handleClickOutside = (event) => {
        if (gnbRef.current && !gnbRef.current.contains(event.target)) {
            onHide();
        }
    };
    // 부모 스크롤 이동 막기
    useEffect(() => {
        const preventScroll = (e) => {
            e.preventDefault();
        };

        if (isPwdModal) {
            document.addEventListener('touchmove', preventScroll, { passive: false });
        } else {
            document.removeEventListener('touchmove', preventScroll);
        }

        return () => {
            document.removeEventListener('touchmove', preventScroll);
        };
    }, [isPwdModal]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleShowPwd = () => {
        setShowPwd(prevPWd => !prevPWd);
    }

    const handleShowPwdCheck = () => {
        setShowPwdCheck(prevShowPwdCheck => !prevShowPwdCheck);
    }

    return (
        <>
            <ModalWrap ref={gnbRef} $isVisible={isVisible}>
                <ModalHeader>
                    <h2>{t("m_gnb.changePwd")}</h2>
                    <ButtonUtil className="close-btn bottom-modal-close-btn"
                        onClick={onHide}>
                        <FontAwesomeIcon icon={faXmark} className="close-i" color="black" />
                    </ButtonUtil>
                </ModalHeader>
                <ModalContent>
                    <TextFields
                        type={showPwd ? "text" : "password"}
                        label={t("m_gnb.nowPwd")}
                        className="loginPwChg"
                        placeholder={t("m_alert.nowPwdInput")}
                        name="userPwd"
                        value={formData.userPwd}
                        onChangeValue={(e) => { handleFormDataChange(e) }}
                        errors={errors.userPwd}
                        icon={showPwd ? faEye : faEyeSlash}
                        iconOnClick={handleShowPwd}
                        autoComplete="off"
                    />
                    {/* {errors.userPwd && <p style={{fontSize: "13px", color: "red"}}>{errors.userPwd}</p>} */}
                    <TextFields
                        type={showPwdCheck ? "text" : "password"}
                        label={t("m_gnb.newPwd")}
                        className="loginPwChg"
                        placeholder={t("m_alert.newPwdInput")}
                        name="userNewPwd"
                        value={formData.userNewPwd}
                        onChangeValue={(e) => { handleFormDataChange(e) }}
                        errors={errors.userNewPwd}
                        icon={showPwdCheck ? faEye : faEyeSlash}
                        iconOnClick={handleShowPwdCheck}
                        autoComplete="off"
                    />
                    {/* {errors.userNewPwd && <p style={{fontSize: "13px", color: "red"}}>{errors.userNewPwd}</p>} */}
                </ModalContent>
                <ModalBtnWrap>
                    <ButtonUtil className="btn btn-secondary round-filled-gray-btn"
                        value={t("m_gnb.cancel")}
                        onClick={onHide}
                    />
                    <ButtonUtil className="btn btn-danger round-filled-btn"
                        onClick={() => { handleCmmnBtnClick("cmmnUpdtBtn") }}
                        value={t("m_gnb.change")}
                    />
                </ModalBtnWrap>
            </ModalWrap>
            {isPwdModal && <DropBackground />}
        </>
    )
}