import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import dshLogo from "asset/mobile_web/img/icon/dashboard.svg";
import ephLogo from "asset/mobile_web/img/icon/monetization-on.svg";
import dvcMgtLogo from "asset/mobile_web/img/icon/developer-board.svg";
import cpnMgtLogo from "asset/mobile_web/img/icon/local-activity.svg";
import { useTranslation } from "react-i18next";

const SideMenu = styled.ul`
    li {
        display: flex;
        align-items: center;
        padding: 18px 20px;
        border-radius: 8px;
        margin-bottom: 10px !important;
        cursor: pointer;
        h2 {
            font-size: 16px;
            color: #fff;
            margin-left: 15px;
        }
        &.select {
            background-color: ${({ theme }) => theme.colors.red};
        }
    }
    a:first-child li img {
        width: 15px;
        margin: 2px;
    }
`;

export default function LeftMenu({ hideSideMenu }) {
    const {t} = useTranslation();
    const links = [
        {
            href: "/mobile/dsh-brd/dsh-brd",
            key: t("leftMenu.dshBrd"),
            img: dshLogo,
        },
        {
            href: "/mobile/sal-hist/sal-hist",
            key: t("leftMenu.salHist"),
            img: ephLogo,
        },
        {
            href: "/mobile/dvc-mgt/dvc-mgt",
            key: t("leftMenu.dvcMgt"),
            img: dvcMgtLogo,
        },
        // {
        //     href: "/mobile/cpn-mgt/cpn-mgt",
        //     key: t("leftMenu.cpnMgt"),
        //     img: cpnMgtLogo,
        // },
    ];

    const location = useLocation();
    const [selected, setSelected] = useState(location.pathname);

    useEffect(() => {
        setSelected(location.pathname);
    }, [location.pathname]);

    const handleClick = (href) => {
        setSelected(href);
        hideSideMenu();
    };

    return (
        <SideMenu>
            {links.map((val, index) => (
                <Link to={val.href} key={index} onClick={() => handleClick(val.href)}>
                    <li className={selected === val.href ? "select" : ""}>
                        <img src={val.img} alt={`${val.key}_logo`} />
                        <h2>{val.key}</h2>
                    </li>
                </Link>
            ))}
        </SideMenu>
    )

}