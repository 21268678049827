'use client'

import axios from "axios";
import { alert } from "components/web/cmmn/CmmnConfirm";
import i18n from "locales/i18n.ts";

export const LoginRequest = async (userId, userPwd) => {
    const baseApiUrl = process.env.REACT_APP_API_BASE_URL;
    try {
        const response = await axios.post(`${baseApiUrl}/web/api/auth/login`, {
            userId: userId,
            userPwd: userPwd,
        }, {
            withCredentials: true
        });

        if (response.status >= 200 && response.status < 300) {
            // console.log(response.status);
            const { userNm, userSqno, accessToken, brchId, brchClsfCd } = response.data;
            
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('userSqno', userSqno);
            localStorage.setItem('userNm', userNm);
            localStorage.setItem('brchId', brchId);
            localStorage.setItem('brchClsfCd', brchClsfCd);

            return response;
        } else {
            alert("", i18n.t("alert.loginError"), () => {});
            return null;
        }
    } catch (error) {
        if (error.response) {
            if (error.response.status === 401) {
                alert("", i18n.t("alert.noMatchIdOrPwd"), () => {});
            } else if (error.response.status === 404) {
                alert("", i18n.t("alert.noPage"), () => {});
            } else {
                alert("", i18n.t("alert.loginError"), () => {});
                console.error('로그인 중 오류 발생:', error);
            }
        } else {
            alert("", i18n.t("alert.loginError"), () => {});
            console.error('로그인 중 오류 발생:', error);
        }
        return null;
    }
};
