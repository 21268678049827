'use client'

import styled from "styled-components"
import { library } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonUtil from "../UI/ButtonUtil";
import { useEffect, useRef, useState } from "react";
import { callApi } from "util/HttpService";
import { alert } from "components/web/cmmn/CmmnConfirm";
import InputBox from "../UI/InputBox";

const ModalWrap = styled.div`
    background-color: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 6;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    // transition: opacity 0.6s, transform 0.6s, visibility 0.6s;
    // opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    // transform: ${({ isVisible }) => (isVisible ? 'translateY(0)' : 'translateY(100%)')};
    // visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;
const ModalHeader = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray7};
    h2 {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: 500;
    }
    button {
        background-color: #fff;
        svg {
            font-size: 16px;
            color: ${({ theme }) => theme.colors.dark2};
        }
    }
`;
const ModalContent = styled.div`
    padding: 20px;
    h3 {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: bold;
        width: 35%;
    }
    & > div {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:last-of-type {
            margin: 0;
        }
        & > div {
            display: flex;
            align-items: center;
        }
    }
    h4 {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: 400;
        width: 70%;
    }
    h5 {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.dark2};
        margin-left: 5px;
    }
    .input_box:last-of-type {
        margin: 0 !important;
    }
`;
const ModalBtnWrap = styled.div`
    padding: 0 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 48% !important;
    }
`;
const DropBackground = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
`;

export default function CmmnCreditModal({ 
    crdInfo, 
    setIsCrdModal, 
    t, 
    getBrchCrdInfo,
    stat,
    setStat,
    crdStat,
}) {
    console.log(crdStat);
    

    library.add(faXmark);
    const defaultBrchInfo = {
        brchId: crdInfo.brchId,
        flngCrdCnt: '',
    }
    const [searchData, setSearchData] = useState(defaultBrchInfo);
    // const [isVisible, setIsVisible] = useState(false);
    const gnbRef = useRef(null);

    // useEffect(() => {
    //     setIsVisible(true);
    // }, []);
    // const closeModal = () => {
    //     setIsVisible(false);
    // };
    // const handleTransitionEnd = () => {
    //     if (!isVisible) {
    //         setIsCrdModal(false);
    //     }
    // };

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = value.replace(/[^0-9,]/g, ""); //숫자와 콤마만 허용
        const numericValue = formattedValue.replace(/,/g, ""); // 콤마 제거하고 숫자만 남기기
        const formattedNumber = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");// 세 자리마다 콤마 찍기
        setSearchData((prevData) => ({
            ...prevData,
            [name]: formattedNumber,
        }));
    };

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnInsertBtn":
                const formattedFlngCrdCnt = searchData.flngCrdCnt.replace(/,/g, '');
                callApi("POST", null, `/web/api/svc-mgt/brch/crd-insert?brchId=${searchData.brchId}&flngCrdCnt=${formattedFlngCrdCnt}`)
                    .then(res => {
                        alert("", t("m_alert.crdPmtCpt"), () => { 
                            setIsCrdModal(false); 
                            getBrchCrdInfo();
                            setStat(!stat);
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        alert("", t("m_alert.crdPmtFail"), () => { });
                    });
                break;
            default: break;
        }
    }
    const handleClickOutside = (event) => {
        if (gnbRef.current && !gnbRef.current.contains(event.target)) {
            setIsCrdModal(false);
            // closeModal();
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <ModalWrap ref={gnbRef} /* isVisible={isVisible} onTransitionEnd={handleTransitionEnd} */>
                <ModalHeader>
                    <h2>{t("m_gnb.creditCharge")}</h2>
                    <ButtonUtil className="close-btn bottom-modal-close-btn"
                        onClick={() => setIsCrdModal(false)}>
                        <FontAwesomeIcon icon={faXmark} className="close-i" color="black" />
                    </ButtonUtil>
                </ModalHeader>
                <ModalContent>
                    <div>
                        <h3>{t("m_deviceMgtPage.brch")}</h3>
                        <h4>{crdInfo.brchNm}({t("m_gnb.eqmtCount")} {crdInfo.eqmtCount}{t("m_gnb.unit")})</h4>
                    </div>
                    <div>
                        <h3>{t("m_gnb.creditHeld")}</h3>
                        <div>
                            <InputBox
                                type="text"
                                className="input_box"
                                name="possCrdCnt"
                                value={crdStat && crdStat[0] != null ? crdStat[0].toLocaleString() : "0"}
                                disabled={true}
                            />
                            <h5>Creidts</h5>
                        </div>
                    </div>
                    <div>
                        <h3>{t("m_gnb.chargeCredit")}</h3>
                        <div>
                            <InputBox
                                type="text"
                                className="input_box"
                                name="flngCrdCnt"
                                value={searchData.flngCrdCnt}
                                onChangeValue={handleFormDataChange}
                            />
                            <h5>Creidts</h5>
                        </div>
                    </div>
                </ModalContent>
                <ModalBtnWrap>
                    <ButtonUtil className="btn btn-secondary round-filled-gray-btn bottom-modal-close-btn"
                        value={t("m_gnb.cancel")}
                        onClick={() => setIsCrdModal(false)}
                    />
                    <ButtonUtil className="btn btn-danger round-filled-btn bottom-modal-close-btn"
                        value={t("m_saleHistPage.payment")}
                        onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                    />
                </ModalBtnWrap>
            </ModalWrap>
            <DropBackground />
            {/* {isVisible && <DropBackground />} */}
        </>
    )

}