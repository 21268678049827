import { CategoryScale, Chart as ChartJS, Legend, LineController, LineElement, LinearScale, PointElement, Title, Tooltip, scales } from "chart.js";
import React, { useEffect, useState, useMemo } from "react";
import { Line } from 'react-chartjs-2';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend);

const ChartBox = styled.div`
    width: 100%;
    height: 250px;
    padding: 0rem;
    display: flex;
    align-items: top;
    justify-content: center;
`;

function DayMonthSaleChart({dateData,saleData}) {
    const {t} = useTranslation();

    const chartData = {
        
        labels: dateData,
        datasets: [
        {
            label: `${t("m_dashboardPage.sale")} ¥`,
            data: saleData,
            fill: false,
            borderColor: 'rgba(75, 192, 192, 1)',
            tension: 0.1,
            pointRadius: 6,
        },
        
        ],
    }
    
    const options = {
        maintainAspectRatio: false,
        scales: {
            x: {
                ticks: {
                    font: {
                        size: 10
                    },
                }
            }
        },
        plugins: {
            legend: {
                display: false, // 범례 숨김
            },
        },
    };
    

    return (
        <ChartBox>
            {chartData && <Line options={options} data={chartData} width={"550px"} height={"420px"} />}
        </ChartBox>
    )
}
export default DayMonthSaleChart;

export const MemorizedDayMonthSaleChart = React.memo(DayMonthSaleChart);