import titleLogo from "asset/mobile_web/img/icon/filled/developer-board.svg";
import deviceLogo from "asset/mobile_web/img/device.png"
import cameraImg from "asset/mobile_web/img/icon/filled/icon-btn/camera-alt.svg"
import coinImg from "asset/mobile_web/img/icon/filled/icon-btn/style.svg"
import prgmImg from "asset/mobile_web/img/icon/filled/icon-btn/blur-on.svg"
import styled from "styled-components";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import { Accordion } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import CmmnRadio from "components/mobile/cmmn/CmmnRadio";
import connectFailImg from "asset/photomag_web/img/연결실패.png";
import { useCallback, useEffect, useState } from "react";
import CmmnAccordionItem from "components/mobile/cmmn/CmmnAccordionItem";
import moment from "moment";
import { callApi } from "util/HttpService";
import CmmnRestartIcon from "components/mobile/cmmn/CmmnRestartIcon";
import { alert } from "components/web/cmmn/CmmnConfirm";
import InputBox from "components/mobile/UI/InputBox";
import { useWindowSize } from "context/WindowSizeContext";
import { useTranslation } from "react-i18next";
import { useBrch } from "components/web/auth/BrchProvider";
import CmmnMbSysCnfModal from "components/mobile/cmmn/CmmnMbSysCnfModal";

const DvcBoxWrap = styled.div`
    margin-top: 20px;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
    }
    .mt-30 {
        margin-top: 30px;
    }
    .w-10 {
        width: 10%;
    }
`;
const MenuTitleWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 20px;
    padding: 10px 20px 0px 20px;
    border-bottom: 1px solid #EEEEEE;
    width: 100%;
    border: none !important;
    padding-bottom: 0 !important;
    h3 {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: 500;
    }
    h5 {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.gray6};
        font-weight: 400;
    }
`;
const DeviceMtr = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    // padding: 20px;
    padding: 10px 20px 20px 20px;
    margin: 0 !important;
    .device-img {
        width: 75%;
        border-radius: 16px;
        position: relative;
        // background-image: url(${deviceLogo});
        background-position: center;
        background-size: cover;
        height: 150px;
        padding: 20px;
        border: 1px solid #EEEEEE;
        width: 100%;
        .state-box {
            padding: 5px 10px;
            color: #fff;
            font-size: 14px;
            border-radius: 100px;
            width: fit-content;
            background-color: ${({ theme }) => theme.colors.green};
        }
        .use {
            background-color: #00d45e;
        }
        .wating {
            background-color: #1f93ed;
        }
        .connect-fail {
            background-color: ${({ theme }) => theme.colors.gray8};
        }
    }
`;
const CustomAccordion = styled.div`
    .accordion-item {
        margin-bottom: 10px !important;
        border-radius: 8px;
        border: none !important;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        .accordion-button {
            font-size: 16px;            
            font-weight: 500;
            border-radius: 8px !important;
            box-shadow: none !important;
            &:not(.collapsed) {
                background-color: #fff !important;
            }
        }
        .accordion-body {
            border-top: 1px solid #eeeeee;
            padding: 20px;
        }
    }
`;
const InfoList = styled.ul`
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        h2 {
            font-size: 16px;
            color: ${({ theme }) => theme.colors.dark2};
            font-weight: 500;
            line-height: 150%;
            width: 50%;
        }
        h3 {
            font-size: 16px;
            color: ${({ theme }) => theme.colors.dark2};
            font-weight: 400;
            line-height: 150%;
            width: 50%;
            text-align: left;
        }
    }
`;
const PlusMinusBtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .circle-icon-btn {
        padding: 15px;
        border-radius: 100px;
        background-color: #fff;
        border: 1.5px solid ${({ theme }) => theme.colors.red};
        transition: all .3s ease-in-out;
        &:hover {
            background-color: ${({ theme }) => theme.colors.red};
        }
        &:hover svg {
            color: #fff;
        }
        svg {
            font-size: 18px;
            color: ${({ theme }) => theme.colors.red};
        }
    }
    .plus-minus-input {
        margin: 0 10px;
        input {
            text-align: center;
        }
        .input_box:last-of-type {
            margin: 0 !important;
        }
    }
`;
const SmallBtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .btn {
        font-size: 14px;
        padding: 7px 20px;
        // width: 49%;
        width: 100%;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
`;
const POLLING_INTERVAL = 10000;
export default function DvcMgt() {

    library.add(faPlus, faMinus);
    const { t } = useTranslation();
    const isMobile = useWindowSize();
    const { brchId } = useBrch();
    const brchClsfCd = localStorage.getItem("brchClsfCd");
    const defaultSearchData = {
        brchId: brchId,
        eqmtId: "",
        issuPnt: 500, //발급 포인트
        issuQty: 0, //발급 수량
        frcgPtgyClsfCd: "", //무료촬영구분코드
    }
    const defaultRestartData = {
        eqmtId: "",
        controlDeviceClsfCd: "",
    }
    const pointFreeArr = [
        { label: t("m_deviceMgtPage.pnt"), value: "PNT" },
        { label: t("m_deviceMgtPage.free"), value: "QTY" },
    ];
    const controlArr = [
        { label: t("m_deviceMgtPage.camera"), value: "CAMERA", img: cameraImg },
        { label: t("m_deviceMgtPage.paymentMachine"), value: "COIND", img: coinImg },
        { label: t("m_deviceMgtPage.program"), value: "PRGM", img: prgmImg },
    ];
    const [dvcList, setDvcList] = useState([]);
    const [searchData, setSearchData] = useState(defaultSearchData);
    const [restartData, setRestartData] = useState(defaultRestartData);
    const [applyingDevices, setApplyingDevices] = useState({});

    const [sysModalOpen, setSysModalOpen] = useState(false);
    const [sysModalData, setSysModalData] = useState(null);
    const openMbSysModal = (data) => {
        setSysModalOpen(true);
        setSysModalData(data);
        lockScroll();
    };
    const closeMbSysModal = () => {
        setSysModalOpen(false);
        openScroll();
    }
    const lockScroll = useCallback(() => {
        document.body.style.cssText = `
                position: fixed;
                top: -${window.scrollY}px;
                overflow-y: scroll;
                width: 100%;
            `;
    }, []);
    const openScroll = useCallback(() => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }, []);

    const getDvcList = () => {
        const now = moment();
        callApi("GET", { brchId: brchId }, "/web/api/dvc-mgt/edm/list")
            .then(res => {
                console.log(now.format("HH:mm:ss") + " 장치 재랜더링");
                setDvcList(prevList => {
                    const updatedList = res.map(dvc => {
                        const chngDttm = moment(dvc.chngDttm);
                        const isConnectionFailed = now.diff(chngDttm, 'seconds') > 20;
                        console.log("eqmtId:", dvc.eqmtId, "isConnectionFail:", isConnectionFailed);

                        const prevDvc = prevList.find(prevDvc => prevDvc.eqmtId === dvc.eqmtId) || {};

                        // if (dvc.slblPnt === 0 && dvc.slblFrcgPtgyQty === 0) {
                        //     setApplyingDevices(prev => ({ ...prev, [dvc.eqmtId]: false }));
                        // }

                        // applyingDevices === true면 비활성화 false면 활성화
                        const shouldDisableApplying =
                            dvc.prgmStatCd !== "WAITING" ||
                            dvc.slblPnt > 0 ||
                            dvc.slblFrcgPtgyQty > 0 ||
                            isConnectionFailed;

                        setApplyingDevices(prev => ({ ...prev, [dvc.eqmtId]: shouldDisableApplying }));

                        return {
                            ...dvc,
                            pntSelected: prevDvc.pntSelected || 'PNT',
                            pnt: prevDvc.pnt || 500,
                            qty: prevDvc.qty || 2,
                            isConnectionFailed: isConnectionFailed,
                        };
                    });
                    console.log(updatedList);
                    return updatedList;
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
    useEffect(() => {
        getDvcList();
        const interval = setInterval(getDvcList, POLLING_INTERVAL);
        return () => clearInterval(interval);
    }, []);

    const handlePntDataChange = (e, idx) => {
        const { value } = e.target;
        setDvcList(prevList => {
            const newList = [...prevList];
            newList[idx] = {
                ...newList[idx],
                pntSelected: value,
                pnt: value === "PNT" ? 500 : newList[idx].pnt,
                qty: value === "PNT" ? 2 : newList[idx].qty,
            };
            return newList;
        });
        setSearchData((prevData) => ({
            ...prevData,
            issuPnt: value === "PNT" ? 500 : 0,
            issuQty: value === "PNT" ? 0 : 2
        }));
    };

    const handleIncrease = (idx) => {
        setDvcList(prevList => {
            return prevList.map((item, index) => {
                if (index !== idx) return item;
                const updateItme = {
                    ...item,
                    pnt: item.pntSelected === "PNT" ? item.pnt + 100 : item.pnt,
                    qty: item.pntSelected === "PNT" ? item.qty : Math.min(item.qty + 2, 10)
                }
                setSearchData((prevData) => ({
                    ...prevData,
                    issuPnt: updateItme.pntSelected === "PNT" ? updateItme.pnt : prevData.issuPnt,
                    issuQty: updateItme.pntSelected === "PNT" ? prevData.issuQty : updateItme.qty
                }));
                return updateItme;
            });
        });
    };

    const handleDecrease = (idx) => {
        setDvcList(prevList => {
            return prevList.map((item, index) => {
                if (index !== idx) return item;
                const updateItme = {
                    ...item,
                    pnt: item.pntSelected === "PNT" ? Math.max(item.pnt - 100, 100) : item.pnt,
                    qty: item.pntSelected === "PNT" ? item.qty : Math.max(item.qty - 2, 2)
                }
                setSearchData((prevData) => ({
                    ...prevData,
                    issuPnt: updateItme.pntSelected === "PNT" ? updateItme.pnt : prevData.issuPnt,
                    issuQty: updateItme.pntSelected === "PNT" ? prevData.issuQty : updateItme.qty
                }));
                return updateItme;
            })
        })
    };

    const handleCmmnBtnClick = (btnNm, dvc, controlDeviceClsfCd) => {
        const searchParam = { ...searchData, eqmtNo: dvc.eqmtNo, frcgPtgyClsfCd: dvc.pntSelected };
        const restartParam = { ...restartData, eqmtNo: dvc.eqmtNo, controlDeviceClsfCd: controlDeviceClsfCd };

        // if (applyingDevices[dvc.eqmtId]) {
        //     alert("", "아직 실행중입니다.", () => { return; });
        // }
        // setApplyingDevices(prev => ({ ...prev, [dvc.eqmtId]: true }));
        switch (btnNm) {
            case "cmmnInsertBtn":
                // setApplyingDevices(prev => ({ ...prev, [dvc.eqmtId]: true }));
                if (searchParam.frcgPtgyClsfCd === "PNT") {
                    callApi("POST", searchParam, "/web/api/dvc-mgt/edm/pnt-update")
                        .then(res => {
                            alert("", t("m_alert.pntInsert"), () => { getDvcList() });
                        })
                        .catch(error => {
                            alert("", t("m_alert.pntInsertError"), () => { });
                        })
                } else {
                    callApi("POST", searchParam, "/web/api/dvc-mgt/edm/pnt-update")
                        .then(res => {
                            alert("", t("m_alert.qtyInsert"), () => { getDvcList() });
                        })
                        .catch(error => {
                            alert("", t("m_alert.qtyInsertError"), () => { });
                        })
                }
                break;
            case "cmmnDeleteBtn":
                if (searchParam.frcgPtgyClsfCd === "PNT") {
                    callApi("POST", searchParam, "/web/api/dvc-mgt/edm/pnt-delete")
                        .then(res => {
                            alert("", t("m_alert.pntDelete"), () => { getDvcList() });
                        })
                        .catch(error => {
                            alert("", t("m_alert.pntDeleteError"), () => { });
                        })
                } else {
                    callApi("POST", searchParam, "/web/api/dvc-mgt/edm/pnt-delete")
                        .then(res => {
                            alert("", t("m_alert.qtyDelete"), () => { getDvcList() });
                        })
                        .catch(error => {
                            alert("", t("m_alert.qtyDeleteError"), () => { });
                        })
                }
                break;
            case "cmmnRestartBtn":
                const eqmt = `${controlDeviceClsfCd}`;
                let eqmtNm;
                if (eqmt === "PRGM") {
                    eqmtNm = t("m_deviceMgtPage.restartProgram");
                } else if (eqmt === "COIND") {
                    eqmtNm = t("m_deviceMgtPage.restartPaymentDvc");
                } else if (eqmt === "CAMERA") {
                    eqmtNm = t("m_deviceMgtPage.restartCamera");
                }
                callApi("GET", restartParam, "/web/api/mqtt/send/control-device-restart")
                    .then(res => {
                        alert("", `${eqmtNm}`, () => { });
                    })
                    .catch(error => {
                        alert("", `${t("m_alert.dvcRestartError")}`, () => { });
                    })
                break;
            default: break;
        }
    };

    return (
        <>
            <section id="dvcWrap">
                <div className="title-wrap">
                    <div className="title" style={{ background: "none" }}>
                        <img src={titleLogo} alt={t("m_deviceMgtPage.dvcMgt")} />
                        <h2>{t("m_deviceMgtPage.dvcMgt")}</h2>
                    </div>
                </div>
                {dvcList.map((dvc, idx) => (
                    <DvcBoxWrap key={idx}>
                        <div className={`dashboard-box ${idx > 0 ? 'mt-30' : ''}`}>
                            <MenuTitleWrap>
                                <h3>{dvc.eqmtNm}</h3>
                                <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn w-10"
                                    value={t("m_deviceMgtPage.config")}
                                    onClick={() => { openMbSysModal(dvc) }}
                                />
                            </MenuTitleWrap>
                            <DeviceMtr>
                                {!dvc.isConnectionFailed ?
                                    <div className="device-img" style={{ backgroundImage: `url(${dvc.prgmCptrImgCn})` }}>
                                        <div className={`state-box ${dvc.prgmStatCd === "INUSE" ? "use" : "wating"}`}>
                                            {dvc.prgmStatCd === "INUSE" ? t("m_deviceMgtPage.inuse") : t("m_deviceMgtPage.waiting")}
                                        </div>
                                    </div>
                                    :
                                    <div className="device-img" style={{ backgroundImage: `url(${connectFailImg})` }}>
                                        <div className={"state-box connect-fail"} >
                                            {dvc.isConnectionFailed && t("m_deviceMgtPage.connectFailed")}
                                        </div>
                                    </div>
                                }
                            </DeviceMtr>
                        </div>
                        <CustomAccordion>
                            <Accordion defaultActiveKey="0">
                                <CmmnAccordionItem eventKey="0" header={t("m_deviceMgtPage.dvcInfo")}>
                                    <InfoList>
                                        <li>
                                            <h2>{t("m_deviceMgtPage.prgmStat")}</h2>
                                            {!dvc.isConnectionFailed ?
                                                <h3>{dvc.prgmStatCd === "INUSE" ? t("m_deviceMgtPage.inuse") : t("m_deviceMgtPage.waiting")}</h3>
                                                :
                                                <h3>{dvc.isConnectionFailed && t("m_deviceMgtPage.connectFailed")}</h3>
                                            }
                                        </li>
                                        <li>
                                            <h2>{t("m_deviceMgtPage.prntStat")}</h2>
                                            <h3>{(dvc.prntStatMsgCn == null || dvc.prntStatMsgCn == undefined) ? "-" : dvc.prntStatMsgCn}</h3>
                                        </li>
                                        <li>
                                            <h2>{t("m_deviceMgtPage.remPapr")}</h2>
                                            <h3>{(dvc.remPaprQty == null || dvc.remPaprQty == undefined) ? 0 : dvc.remPaprQty}{t("m_deviceMgtPage.sheet")}</h3>
                                        </li>
                                        <li>
                                            <h2>{t("m_deviceMgtPage.pntAndFrcgPtgy")}</h2>
                                            <h3>
                                                {(dvc.slblPnt == null || dvc.slblPnt == undefined) ? '0' : dvc.slblPnt.toLocaleString()}P/
                                                {(dvc.slblFrcgPtgyQty == null || dvc.slblFrcgPtgyQty == undefined) ? '0' : dvc.slblFrcgPtgyQty.toLocaleString()}{t("m_deviceMgtPage.page")}
                                            </h3>
                                        </li>
                                    </InfoList>
                                </CmmnAccordionItem>
                                <CmmnAccordionItem eventKey="1" header={t("m_deviceMgtPage.controlDevice")}>
                                    <CmmnRestartIcon
                                        dataArray={controlArr}
                                        handleClick={(controlDeviceClsfCd) => handleCmmnBtnClick("cmmnRestartBtn", dvc, controlDeviceClsfCd)}
                                    />
                                </CmmnAccordionItem>
                                <CmmnAccordionItem eventKey="2" header={t("m_deviceMgtPage.pntAndFrcg")}>
                                    <CmmnRadio
                                        id={`frcgPtgyClsfCd${idx}`}
                                        dataArray={pointFreeArr}
                                        defaultVal={dvc.pntSelected}
                                        tagName={`frcgPtgyClsfCd${idx}`}
                                        onSelectChange={(e) => { handlePntDataChange(e, idx) }}
                                        isMobile={isMobile}
                                    />
                                    <PlusMinusBtnWrap>
                                        <ButtonUtil className="circle-icon-btn"
                                            onClick={() => { handleDecrease(idx) }}>
                                            <FontAwesomeIcon icon={faMinus} />
                                        </ButtonUtil>
                                        <div className="plus-minus-input">
                                            <InputBox
                                                type="text"
                                                id={`input${idx}`}
                                                name={`input${idx}`}
                                                className="input_box"
                                                value={dvc.pntSelected === "PNT" ? `${dvc.pnt}P` : `${dvc.qty}장`}
                                                disabled={true}
                                            />
                                        </div>
                                        <ButtonUtil className="circle-icon-btn"
                                            onClick={() => { handleIncrease(idx) }}>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </ButtonUtil>
                                    </PlusMinusBtnWrap>
                                    <SmallBtnWrap>
                                        <ButtonUtil
                                            className="btn btn-danger round-filled-btn"
                                            value={t("m_deviceMgtPage.apply")}
                                            onClick={() => { handleCmmnBtnClick("cmmnInsertBtn", dvc) }}
                                            disabled={applyingDevices[dvc.eqmtId]}
                                        />
                                        {/* <ButtonUtil
                                        className="btn btn-light round-filled-gray-btn bottom-modal-close-btn"
                                        value={t("m_deviceMgtPage.clear")}
                                        onClick={() => { handleCmmnBtnClick("cmmnDeleteBtn", dvc) }}
                                    /> */}
                                    </SmallBtnWrap>
                                </CmmnAccordionItem>
                            </Accordion>
                        </CustomAccordion>
                    </DvcBoxWrap>
                ))}
            </section>
            <CmmnMbSysCnfModal
                show={sysModalOpen}
                data={sysModalData}
                onHide={closeMbSysModal}
                brchClsfCd={brchClsfCd}
                t={t}
            />
        </>
    )
}