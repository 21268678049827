import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import * as httpService from "util/HttpService";
import { todayDate, nowMonth, prvMonth, thisYear } from "util/DshBrdDateUtil";
import { Nav, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useBrch } from "components/web/auth/BrchProvider";

//img
import device from "asset/img/device.png";
import dashboardB from "asset/img/icon/filled/dashboard_b.svg";
import connectFailed from "asset/img/connect_failed.png";

//component
import MainTimer from "./component/MainTimer";
import SaleStat from "./component/SaleStat";
import EqmtHourlyChart from "pages/web/sal-hist/EqmtHourlyChart";
import EqmtAccuHourlyChart from "pages/web/sal-hist/EqmtAccuHourlyChart";
import CmmnGraphBox from "components/mobile/cmmn/CmmnGraphBox";
import { MemorizedDayMonthSaleChart } from "pages/web/dsh-brd/component/DayMonthSaleChart";
import SaleByEqmtChart from "pages/web/dsh-brd/component/SaleByEqmtChart";
import CrdStatChart from "./component/CrdStatChart";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import CmmnCrdChargeModal from "components/web/cmmn/CmmnCrdChargeModal";
import CmmnCrdHistModal from "components/web/cmmn/CmmnCrdHistModal";

const DashboardWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 0 0;
`;
const DashboardBox = styled.div`
  background-color: #fff;
  border-radius: 16px;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;
const MenuTitleWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #eeeeee;
    width: 100%;
    height: 72px;
    h3 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        margin-right: 10px;
    }
    h5 {
        font-size: 14px;
        color: #8b8b8b;
        font-weight: 400;
    }
    .nav-tabs {
        border: none;
        .nav-item {
            margin-right: 5px;
            &:last-child {
                margin: 0;
            }
        .nav-link {
            border: none;
            font-size: 14px;
            background-color: ${({ theme }) => theme.colors.gray5};
            border: 1px solid ${({ theme }) => theme.colors.gray5};
            color: ${({ theme }) => theme.colors.gray6};
            border-radius: 100px;
            &.active {
                border: 1px solid ${({ theme }) => theme.colors.red};
                border-radius: 100px;
                color: ${({ theme }) => theme.colors.red};
                font-size: 14px;
                background-color: var(--bs-nav-tabs-link-active-bg) !important;
            }
        }
        }
    }
    .more-btn{
        background-color: transparent;
        display: flex;
        align-items: center;
        color: #8B8B8B;
        font-size: 14px;
        transition: all .3s ease-in-out;
    }
    .more-btn i{
        font-size: 14px;
        margin-left: 5px;
    }
    .more-btn:hover{
        color: #EF2929;
        font-weight: bold;
    }
    .mr-5{
        margin-right: 5px;
    }
    .round-filled-small-btn:hover {
        color: var(--bs-btn-hover-color) !important;
        background-color: var(--bs-btn-hover-bg) !important;
        border-color: var(--bs-btn-hover-border-color) !important;
    }
`;
const ContentWrap = styled.div`
  padding: 20px;
  .graph-box{
     width: 100%;
     height: 250px;
     max-height: 250px;
     h4{
         text-align:center;
         font-weight:bold;
         font-size:15px;
         padding-bottom:8px;
     }
  }

`;
const DeviceWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap:16px;
  row-gap:60px;
  justify-content: flex-start;
  flex-wrap:wrap;
  padding: 20px;
  .device-box{
    width: 24%;
    box-shadow: 0 0 5px rgba(0,0,0,.1);
    padding: 20px;
    border-radius: 16px;
  }
  .device-box .device-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .device-box .device-title h3{
    font-size: 16px;
    color: #3b3b3b;
    font-weight: 600;
  }
  .device-box .device-title h5 {
    font-size: 14px;
    color: #8b8b8b;
  }
  .device-monitoring {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }
  .device-monitoring .device-img {
    width: 75%;
    border-radius: 16px;
    position: relative;
    background-image: url(${device});
    background-position: center;
    background-size: cover;
    height: 150px;
    padding: 20px;
    border: 1px solid #eeeeee;
  }
  .state-box {
    padding: 5px 10px;
    color: #fff;
    font-size: 14px;
    border-radius: 100px;
    width: fit-content;
  }
  .wating {
    background-color: #1f93ed;
  } 
  .disconnected {
    background-color: #A6A6A6;
  }
  .use {
    background-color: #00d45e;
  }
  .device-gauge-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 12px;
    width: 20%;
    height: 150px;
  }
  .device-gauge-bar .gauge {
    height: 13%;
    width: 100%;
    margin-bottom: 5px;
    background-color: #d9d9d9;
  }
  .green-gauge .filled {
    background-color: #00d45e;
  }
  .yellow-gauge .filled {
    background-color: #feb907;
  }
  .red-gauge .filled {
    background-color: red;
    animation: blink-effect 1s step-end infinite;
  }
  .charge-name-box h3 {
    font-size: 16px;
    color: #3b3b3b;
    font-weight: bold;
    width: 30%;
  }
  .charge-name-box > div {
    margin-bottom: 20px;
  }
  .charge-name-box > div:last-of-type {
    margin: 0;
  }
  .charge-name-box h4 {
    font-size: 16px;
    color: #3b3b3b;
    font-weight: 400;
    width: 70%;
  }
  .charge-name-box h5 {
    font-size: 14px;
    color: #3b3b3b;
    margin-left: 5px;
  }
  .filled {
    width: 100%;
    height: 100%;
  }
  @keyframes blink-effect {
    50% {
      opacity: 0;
    }
  }
`;



// const POLLING_INTERVAL = 10000;

export default function Dshbrd() {

    const {t} = useTranslation();
    const { brchId, crdInfo, getBrchCrdInfo, getCrdStat, crdStat, POLLING_INTERVAL, stat, setStat } = useBrch();
    useEffect(() => {
        console.log(localStorage);
    },[brchId]);

    /* 충전하기 팝업 */
    const [chargeModalOpen, setChargeModalOpen] = useState(false);
    const [chargeModalData, setChargeModalData] = useState(null);
    const openChargeModal = (data) => {
        setChargeModalOpen(true);
        setChargeModalData(data);
    };
    useEffect(() => {
        getBrchCrdInfo();
    }, []);
    /* 충전내역 팝업 */
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [historyModalData, setHistoryModalData] = useState(null);
    const openHistoryModal = (data) => {
        setHistoryModalOpen(true);
        setHistoryModalData(data);
    };
    
    /* 매출현황 */
    const defaultDate = new Date();
    const defaultNextDate = new Date(
        defaultDate.getFullYear(),
        defaultDate.getMonth(),
        defaultDate.getDate() + 1
    );
    const defaultSrchSalStat = {
        brchId: brchId,
        startDate: `${defaultDate.getFullYear()}-${String(defaultDate.getMonth() + 1).padStart(2, "0")}-${String(defaultDate.getDate()).padStart(2,"0")} 00:00:00`,
        endDate: `${defaultNextDate.getFullYear()}-${String(defaultNextDate.getMonth() + 1).padStart(2, "0")}-${String(defaultNextDate.getDate()).padStart(2,"0")} 00:00:00`,
    };
    const [srchSalStat, setSrchSalStat] = useState(defaultSrchSalStat);
    const defaultSalStat = {
        totStlmCnt: 0,
        totPhtQty: 0,
        totStlmAmt: 0,
        possCrdCnt: 0,
    };
    const [salStat, setSalStat] = useState(defaultSalStat);

    useEffect(() => {
        getSalStat();
    }, [srchSalStat]);

    const getSalStat = () => {
        const searchData = srchSalStat;
        httpService
        .callApi("POST", searchData, "/web/api/dsh-brd/get-sale-stat")
        .then((response) => {
            setSalStat(response);
        })
        .catch((error) => {
            console.error(error);
        });
    };

    const [saleStatClicked, setSaleStatClicked] = useState("today");
    const statHandleClick = (e) => {
        const btnNm = e.target.name;
        setSaleStatClicked(btnNm);
        const now = new Date();
        var srchDate;
        switch (btnNm) {
        case "today":
            srchDate = todayDate(now);
            setSrchSalStat((prv) => ({
            ...prv,
            startDate: srchDate[0],
            endDate: srchDate[1],
            }));
            break;
        case "nowMonth":
            srchDate = nowMonth(now);
            setSrchSalStat((prv) => ({
            ...prv,
            startDate: srchDate[0],
            endDate: srchDate[1],
            }));
            break;
        case "prvMonth":
            srchDate = prvMonth(now);
            setSrchSalStat((prv) => ({
            ...prv,
            startDate: srchDate[0],
            endDate: srchDate[1],
            }));
            break;

        default:
            break;
        }
    };

    const saleTabs = [
        { ko: t("dashboardPage.today"), en: "today" },
        { ko: t("dashboardPage.thisMonth"), en: "nowMonth" },
        { ko: t("dashboardPage.prvMonth"), en: "prvMonth" },
    ];
    const [saleActivekey, setActivekey] = useState("saleTab1");
    const [source,setSource] = useState("");
    const saleHandleSelect = (key) => {
        setActivekey(key);
        setSource('click');
    };
    /* 매출현황 끝 */

    /* 시간 매출 */
    const hourlyArray = () => {
        const hours = [];
        for (let i = 0; i < 24; i++) {
            const hour = i < 10 ? `0${i}` : `${i}`;
            hours.push(`${hour}`);
        }
        return hours;
    }
    const hourList = hourlyArray();
    const hourTab = [t("dashboardPage.time"), t("dashboardPage.timeAcc")];
    const [timeArray] = useState(hourlyArray);
    const [hourlySalesList, setHourlySalesList] = useState([[],[],[],[]]);

    const defaultHourlySalesSearchData = {
        brchId: brchId,
        eqmtId: "",
        startDate: `${defaultDate.getFullYear()}-${String(defaultDate.getMonth() + 1).padStart(2,'0')}-${String(defaultDate.getDate()).padStart(2,'0')}`,
        endDate: `${defaultDate.getFullYear()}-${String(defaultDate.getMonth() + 1).padStart(2,'0')}-${String(defaultDate.getDate()).padStart(2,'0')}`,
        hourList: hourList,
        division: "DSH",
    };
    const [hourlySalesSearchData] = useState(defaultHourlySalesSearchData);

    const getHourlySalesList = () => {
        httpService.callApi("POST",hourlySalesSearchData, `/web/api/sal-hist/eph/hour-sales`)
        .then(res => {
            setHourlySalesList(res);
        })
        .catch(error => {
            console.log(error);
        })
    };

    useEffect(() => {
        getHourlySalesList();
    },[hourlySalesSearchData]);


    const [hourlyTypeTabs,setHourlyTypeTabs] = useState([]);

    useEffect(() => {
        // 비동기 작업 등을 통해 변수를 생성합니다
        setHourlyTypeTabs([
            { tabTitle: "시간", graphComponent: <EqmtHourlyChart salesData={hourlySalesList} dateArray={timeArray} searchData={hourlySalesSearchData} /> },
            { tabTitle: "시간 누적", graphComponent: <EqmtAccuHourlyChart salesData={hourlySalesList} dateArray={timeArray} searchData={hourlySalesSearchData} /> }
        ]);
    }, [hourlySalesList]);
    // 이 부분이 첫렌더링시에 생성되는데 hourlySaleList, timeArray가 아직 빈배열인데 참조할려해서 문제가 생기는듯?
    /* 시간 매출 끝 */
    
    /* 일월매출 */
    const dayArray = () => {
        const today = new Date();
        const result = [];
        for (let i = 0; i < 30; i++) {
        const currentDate = new Date();
        currentDate.setDate(today.getDate() - i);
        const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // 월을 2자리로 변환
        const day = ('0' + currentDate.getDate()).slice(-2); // 일을 2자리로 변환
        result.push(`${month}-${day}`);
        }
        return result.reverse();
    };
    const monthArray = () => {
        var result = [];
        var month = defaultDate.getMonth()+1;
        for(var i=0; i<12; i++){
            var year = defaultDate.getFullYear();
            var newMonth = month - i;
            if(newMonth <= 0){
                newMonth += 12;
                year -= 1;
            }
            result.push(`${year}-${String(newMonth).padStart(2,'0')}`);
        }
        result = result.reverse();
        return result;
    }

    const defaultDateData = dayArray();
    const [dateData, setDateData] = useState(defaultDateData);
    const [saleData, setSaleData] = useState([]);

    const defaultDaySaleSearchData = {
        brchId: brchId,
        dayList: dateData.map((data) => defaultDate.getFullYear()+"-"+data),
        startDate: dateData.map((data) => defaultDate.getFullYear()+"-"+data)[0]+" 00:00:00",
        endDate:  `${defaultNextDate.getFullYear()}-${String(defaultNextDate.getMonth()+1).padStart(2,'0')}-${String(defaultNextDate.getDate()).padStart(2,'0')} 00:00:00`,
    };
    const [daySaleSearchData] = useState(defaultDaySaleSearchData);

    const defaultMonthSaleSearchData = {
        brchId: brchId,
        monthList: monthArray().map(data => data.replace("-","")),
    };
    const [monthSaleSearchData] = useState(defaultMonthSaleSearchData);

    useEffect(() => {
        getDaySaleData();
    },[]);

    // 일월별 매출액 조회
    const getDaySaleData = () => {
        httpService.callApi("POST",daySaleSearchData,"/web/api/dsh-brd/get-daily-sale")
        .then(res => {
            setSaleData(res);
        })
        .catch(error => {
            console.error(error);
        })
    };
    const getMonthSaleData = () => {
        httpService.callApi("POST",monthSaleSearchData,"/web/api/dsh-brd/get-monthly-sale")
        .then(res => {
            setSaleData(res);
        })
        .catch(error => {
            console.error(error);
        })
    };

    const dayMonthTabs = [{"ko":t("dashboardPage.daily"),"en":"day"}, {"ko":t("dashboardPage.monthly"),"en":"month"}, ];
    const [dayMonthClicked,setDayMonthClicked] = useState('day');
    const hanldeDayMonthChartClick = (e) => {
        const btnNm = e.target.name;
        setDayMonthClicked(btnNm);
        switch(btnNm){
            case 'day':
                setDateData(dayArray());
                getDaySaleData();
            break;
            case 'month':
                setDateData(monthArray());
                getMonthSaleData();
            break;
            default:
            break;
        }
    };
    const [dayMonthActiveKey, setDayMonthActiveKey] = useState("dayMonthTab1");
    const dayMonthHandleSelect = (key) => {
        setDayMonthActiveKey(key);
    };
    /* 일월매출 끝 */

    /* 장치매출 */
    const defaultSaleByEqmtSrchData = {
        srchTag: "eph", //조회 구분 태그 (오늘,이달 / 올해)
        brchId: brchId,
        startDate: `${defaultDate.getFullYear()}-${String(defaultDate.getMonth() + 1).padStart(2,'0')}-${String(defaultDate.getDate()).padStart(2,'0')} 00:00:00`,
        endDate: `${defaultNextDate.getFullYear()}-${String(defaultNextDate.getMonth() + 1).padStart(2,'0')}-${String(defaultNextDate.getDate()).padStart(2,'0')} 00:00:00`,
    }
    const [saleByEqmtSrchData, setSaleByEqmtSrchData] = useState(defaultSaleByEqmtSrchData);
    const defaultEqmtData = [];
    const [eqmtData,setEqmtData] = useState(defaultEqmtData);

    useEffect(() => {
        if(saleByEqmtSrchData.srchTag ==='eph'){
            getSaleByEqmt();
        }else{
            getSaleByEqmtYear();
        }
    },[saleByEqmtSrchData])

    const getSaleByEqmt = () => {
        httpService.callApi("POST",saleByEqmtSrchData,"/web/api/dsh-brd/get-sale-by-eqmt")
        .then(res => {
            if(res.length !== 0 && res[0].totalAmt !== 0){
                console.log(res);
                setEqmtData(res);
                setTotalSaleByEqmt(res[0].totalAmt);
            }else{
                //매출데이터 조회 실패시 기본 리스트 불러오기
                httpService.callApi("POST",saleByEqmtSrchData,"/web/api/dsh-brd/get-eqmt-list")
                .then(res => {
                    console.log(res);
                    setEqmtData(res);
                    setTotalSaleByEqmt(0);
                })
                .catch(error => {
                    console.error(error);
                })
                
            }
        })
        .catch(error => {
            console.error(error);
        })
    };
    const getSaleByEqmtYear = () => {
        httpService.callApi("POST",saleByEqmtSrchData,"/web/api/dsh-brd/get-sale-by-eqmt-year")
        .then(res => {
            if(res.length !== 0 && res[0].totalAmt !== 0){
                console.log(res);
                setEqmtData(res);
                setTotalSaleByEqmt(res[0].totalAmt);
            }else{
                //매출데이터 조회 실패시 기본 리스트 불러오기
                httpService.callApi("POST",saleByEqmtSrchData,"/web/api/dsh-brd/get-eqmt-list")
                .then(res => {
                    console.log(res);
                    setEqmtData(res);
                    setTotalSaleByEqmt(0);
                })
                .catch(error => {
                    console.error(error);
                })
            }
        })
        .catch(error => {
            console.error(error);
            
        })
    };

    const [saleByEqmtTitle,setSaleByEqmtTitle] = useState("");
    const [totalSaleByEqmt,setTotalSaleByEqmt] = useState(0);
    const [eqmtSaleClicked,setEqmtSaleClicked] = useState("today");
    useEffect(() => {
        setSaleByEqmtTitle(t(eqmtSaleClicked === "today" ? "dashboardPage.today" : eqmtSaleClicked === "thisMonth" ? "dashboardPage.thisMonth" : "dashboardPage.thisYear"));
        //setEqmtSaleClicked("today");
        //setEqmtSaleActiveKey("eqmtSaleTab1");
    },[t]);
    const eqmtSaleHandleClick = (e) => {
        const btnNm = e.target.name;
        setEqmtSaleClicked(btnNm);
        const now = new Date();
        var srchDate;
        switch(btnNm) {
            case 'today':
                setSaleByEqmtTitle(t("dashboardPage.today"));
                srchDate = todayDate(now);
                setSaleByEqmtSrchData((prv) => ({
                    ...prv,
                    srchTag: "eph",
                    startDate: srchDate[0],
                    endDate: srchDate[1],
                }));
            break;
            case 'thisMonth':
                setSaleByEqmtTitle(t("dashboardPage.thisMonth"));
                srchDate = nowMonth(now);
                setSaleByEqmtSrchData((prv) => ({
                    ...prv,
                    srchTag: "eph",
                    startDate: srchDate[0],
                    endDate: srchDate[1],
                }));
            break;
            case 'thisYear':
                setSaleByEqmtTitle(t("dashboardPage.thisYear"));
                srchDate = thisYear(now);
                setSaleByEqmtSrchData((prv) => ({
                    ...prv,
                    srchTag: "bmsa",
                    startDate: srchDate[0],
                    endDate: srchDate[1],
                }));
            break;
            default:
            break;
        }
    };

    const eqmtSaleTabs = [{"ko":t("dashboardPage.today"),"en":"today"}, {"ko":t("dashboardPage.thisMonth"),"en":"thisMonth"},{"ko":t("dashboardPage.thisYear"),"en":"thisYear"},];
    const [eqmtSaleActiveKey, setEqmtSaleActiveKey] = useState("eqmtSaleTab1");
    const eqmtSaleHandleSelect = (key) => {
        setEqmtSaleActiveKey(key);
    };
    /* 장치매출 끝 */

    /* 크레딧현황 */
    // const [crdStat,setCrdStat] = useState([]);

    useEffect(() => {
        getCrdStat();
    },[])
    // const getCrdStat = () => {
    //     httpService.callApi("GET",{brchId:brchId},"/web/api/dsh-brd/get-crd-stat")
    //     .then(res => {
    //         const crdStatValueArr = [res.possCrdCnt,res.tmnUseCrdCnt];
    //         setCrdStat(crdStatValueArr);
    //     })
    //     .catch(error => {
    //         console.error(error);
    //     })
    // }
    /* 크레딧현황 끝 */

    /* 모니터링 */
    const [eqmtList,setEqmtList] = useState([]);
    useEffect(() => {
        getEqmtList();
    },[])
    useEffect(() => {
        getEqmtList();
    },[t]);
    const getEqmtList = () => {
        httpService.callApi("GET",{brchId:brchId},"/web/api/dsh-brd/get-eqmt-list")
        .then(res => {
            const currentDate = new Date();
            res = res.map(data => {
                const localDate = new Date(data.chngDttm);
                const diffMillSec = Math.floor((currentDate - localDate) / 1000);
                console.log(diffMillSec);
                if(diffMillSec > 20){
                    data.prgmStatCd = "CONNECTFAIL";
                    data.prgmCptrImgCn = "DISCONNECTED";
                }
                return data;
            });
            res = res.map((data) => ({
                ...data, 
                prgmStatCdNm: t(data.prgmStatCd === "CONNECTFAIL" ? "dashboardPage.connectFailed" : data.prgmStatCd === "INUSE" ? "dashboardPage.inuse":"dashboardPage.waiting")
            }));
            setEqmtList(res);
        })
        .catch(error => {
            console.error(error);
        })
    };
    /* 모니터링 끝 */

    /* 실시간 데이터 갱신 POLLING 방식*/
    useEffect(() => {
        const interval = setInterval(getSalStat, POLLING_INTERVAL);
        return () => clearInterval(interval);
    },[srchSalStat,dayMonthClicked,eqmtSaleClicked]);

    useEffect(() => {
        const interval = setInterval(getHourlySalesList, POLLING_INTERVAL);
        return () => clearInterval(interval);
    },[srchSalStat,dayMonthClicked,eqmtSaleClicked]);


    useEffect(() => {
        var interval;
        if(dayMonthClicked === 'day'){
            interval = setInterval(getDaySaleData,POLLING_INTERVAL);
        }else if(dayMonthClicked === 'month') {
            interval = setInterval(getMonthSaleData,POLLING_INTERVAL);
        }
        return () => clearInterval(interval);
    },[srchSalStat,dayMonthClicked,eqmtSaleClicked]);


    useEffect(() => {
        var interval;
        if(eqmtSaleClicked === 'today' || eqmtSaleClicked === 'thisMonth'){
            interval = setInterval(getSaleByEqmt,POLLING_INTERVAL);
        }else if(eqmtSaleClicked === 'thisYear'){
            interval = setInterval(getSaleByEqmtYear,POLLING_INTERVAL);
        }
        return () => clearInterval(interval);
    },[srchSalStat,dayMonthClicked,eqmtSaleClicked]);

    useEffect(() => {
        const interval = setInterval(getCrdStat,POLLING_INTERVAL);
        return () => clearInterval(interval);
    },[srchSalStat,dayMonthClicked,eqmtSaleClicked]);

    useEffect(() => {
        const interval = setInterval(() => {
            getEqmtList();
            setSource('polling');
        },POLLING_INTERVAL);

        return () => clearInterval(interval);
    },[]);
    /* 실시간 데이터 갱신 끝 */

  return (
        <div className="padding-box">

          <div className="title-wrap">
            <div className="title space-between">
              <div className="dp-flex align-cen">
                <img src={dashboardB} alt="dashboardB" />
                <h2>{t("dashboardPage.dshBrd")}</h2>
              </div>
              <p>Home {">"} {t("dashboardPage.dshBrd")}</p>
            </div>
            <MainTimer />
          </div>

          <DashboardWrap>
            <DashboardBox>
              <MenuTitleWrap>
                <div className="dp-flex align-cen">
                <h3>{t("dashboardPage.saleStat")}</h3>
                <Tab.Container activeKey={saleActivekey} onSelect={saleHandleSelect}>
                  <Nav variant="tabs" as="ul">
                    {saleTabs.map((tab, idx) => (
                      <Nav.Item as="li" key={idx}>
                        <Nav.Link eventKey={`saleTab${idx + 1}`} as="button" className={saleStatClicked === tab.en ? "clicked" : ""} name={tab.en} onClick={statHandleClick} >
                          {tab.ko}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Tab.Container>
                </div>
                
              </MenuTitleWrap>
              <ContentWrap>
                <Tab.Container activeKey={saleActivekey} onSelect={saleHandleSelect}>
                    <Tab.Content>
                    {saleTabs.map((tab, index) => (
                        <Tab.Pane key={index} eventKey={`saleTab${index + 1}`}>
                            <SaleStat salStat={salStat} source={source}/>
                        </Tab.Pane>
                    ))}
                    </Tab.Content>
                </Tab.Container>
              </ContentWrap>
            </DashboardBox>

            <div className="col-2">
                {
                    hourlySalesList.every(data => data.length === 0) ? <></>
                    :
                    <CmmnGraphBox
                            title={t("dashboardPage.salesByHour")}
                            tabs={hourTab}
                            graphComponents={hourlyTypeTabs.map(tab => tab.graphComponent)}
                    />
                }
              
              <DashboardBox>
                <MenuTitleWrap>
                    <h3>{t("dashboardPage.dailyMonthlySale")}</h3>
                    <Tab.Container activeKey={dayMonthActiveKey} onSelect={dayMonthHandleSelect}>
                        <Nav variant="tabs" as="ul">
                            {dayMonthTabs.map((tab, idx) => (
                                <Nav.Item as="li" key={idx}>
                                <Nav.Link eventKey={`dayMonthTab${idx + 1}`} as="button" className={dayMonthClicked === tab.en ? 'clicked' : ''} name={tab.en} onClick={hanldeDayMonthChartClick}>
                                    {tab.ko}
                                </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Tab.Container>
                </MenuTitleWrap>
                <ContentWrap>
                    <Tab.Container activeKey={dayMonthActiveKey} onSelect={saleHandleSelect}>
                        <Tab.Content>
                        {dayMonthTabs.map((tab, index) => (
                            <Tab.Pane key={index} eventKey={`dayMonthTab${index + 1}`}>
                                <div className="tab-pane fade show active" id="tab1_content1" role="tabpanel" aria-labelledby="tab1_content1">
                                    
                                    <div className="graph-box"><MemorizedDayMonthSaleChart dateData={dateData} saleData={saleData} /></div>
                                </div>
                            </Tab.Pane>
                        ))}
                        </Tab.Content>
                    </Tab.Container>
                </ContentWrap>
              </DashboardBox>
            </div>

            <div className="col-2">
              <DashboardBox>
                <MenuTitleWrap>
                    <h3>{t("dashboardPage.salesByEqmt")}</h3>
                    <Tab.Container activeKey={eqmtSaleActiveKey} onSelect={eqmtSaleHandleSelect}>
                        <Nav variant="tabs" as="ul">
                        {eqmtSaleTabs.map((tab, idx) => (
                            <Nav.Item as="li" key={idx}>
                            <Nav.Link eventKey={`eqmtSaleTab${idx + 1}`} as="button" className={eqmtSaleClicked === tab.en ? 'clicked' : ''} name={tab.en} onClick={eqmtSaleHandleClick}>
                                {tab.ko}
                            </Nav.Link>
                            </Nav.Item>
                        ))}
                        </Nav>
                    </Tab.Container>
                </MenuTitleWrap>
                <ContentWrap>
                    <Tab.Container activeKey={eqmtSaleActiveKey} onSelect={saleHandleSelect}>
                        <Tab.Content>
                        {eqmtSaleTabs.map((tab, index) => (
                            <Tab.Pane key={index} eventKey={`eqmtSaleTab${index + 1}`}>
                                <div className="tab-pane fade show active" id="tab1_content1" role="tabpanel" aria-labelledby="tab1_content1">
                                    
                                    <div className="graph-box">
                                    <h4>{`${saleByEqmtTitle} ${t("dashboardPage.sale")} (￥ ${Intl.NumberFormat("ko-KR",{}).format(totalSaleByEqmt)})`}</h4>
                                    <SaleByEqmtChart salesData={eqmtData} text={"¥"} />
                                    </div>
                                </div>
                            </Tab.Pane>
                        ))}
                        </Tab.Content>
                    </Tab.Container>
                </ContentWrap>
              </DashboardBox>

              <DashboardBox>
                <MenuTitleWrap>
                  <h3>{t("dashboardPage.creditStat")}</h3>
                  <div className="dp-flex align-cen">
                    <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn max-content mr-5"
                        value={t("dashboardPage.chargeIt")} 
                        onClick={() => {openChargeModal(crdInfo)}}
                        disabled={true}
                    />
                    <CmmnCrdChargeModal
                        show={chargeModalOpen}
                        data={chargeModalData}
                        onHide={() => setChargeModalOpen(false)}
                        getBrchCrdInfo={getBrchCrdInfo}
                        t={t}
                        stat={stat}
                        setStat={setStat}
                    /> 
                    <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn max-content"
                        value={t("dashboardPage.chargeHistory")} 
                        onClick={() => { openHistoryModal(crdInfo)}}
                    />
                    <CmmnCrdHistModal
                        show={historyModalOpen}
                        data={historyModalData}
                        onHide={() => setHistoryModalOpen(false)}
                        title={t("dashboardPage.chargeHistory")}
                    />
                  </div>
                </MenuTitleWrap>
                <ContentWrap>
                    <div className="graph-box"><CrdStatChart crdData={crdStat}/></div>
                </ContentWrap>
              </DashboardBox>

            </div>

            <DashboardBox>
              <MenuTitleWrap>
                <h3>{t("dashboardPage.deviceMonitoring")}</h3>
              </MenuTitleWrap>
              <DeviceWrap>
                {/* 리스트 map */}
                {eqmtList.map((eqmt) => (
                    <div className="device-box" key={eqmt.eqmtId}>
                    <div className="device-title">
                        <h3>{eqmt.eqmtNm}</h3>
                        <h5>{t("dashboardPage.paper")} : {eqmt.remPaprQty}{t("dashboardPage.sheet")}</h5>
                    </div>
                    
                    <div className="device-monitoring">
                        <div className="device-img" style={eqmt.prgmCptrImgCn === "DISCONNECTED" ? {backgroundImage:`url(${connectFailed})`}:{backgroundImage:`url(${eqmt.prgmCptrImgCn})`}}>
                        <div className={eqmt.prgmStatCd === 'INUSE' ? "state-box use" : eqmt.prgmStatCd === 'WAITING' ? "state-box wating":"state-box disconnected"}>{eqmt.prgmStatCdNm}</div>
                        </div>

                        <ul className={eqmt.remPaprQty > 400 ? "device-gauge-bar green-gauge" : eqmt.remPaprQty > 100 ? "device-gauge-bar yellow-gauge":"device-gauge-bar red-gauge"}>
                        
                        
                        <li className="gauge">
                            <div className={eqmt.remPaprQty > 600 ? "filled":""}></div>
                        </li>
                        <li className="gauge">
                            <div className={eqmt.remPaprQty > 500 ? "filled":""}></div>
                        </li>
                        <li className="gauge">
                            <div className={eqmt.remPaprQty > 400 ? "filled":""}></div>
                        </li>
                        <li className="gauge">
                            <div className={eqmt.remPaprQty > 300 ? "filled":""}></div>
                        </li>
                        <li className="gauge">
                            <div className={eqmt.remPaprQty > 200 ? "filled":""}></div>
                        </li>
                        <li className="gauge">
                            <div className={eqmt.remPaprQty > 100 ? "filled":""}></div>
                        </li>
                        <li className="gauge">
                            <div className={eqmt.remPaprQty >= 0 ? "filled":""}></div>
                        </li>
                        </ul>
                    </div>

                    </div>  
                ))}
              </DeviceWrap>
            </DashboardBox>

          </DashboardWrap>
        </div>
  );
}
