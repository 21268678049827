'use client'

import TextFields from "components/web/UI/TextFields";
import BrchNmSelectBox from "components/web/cmmn/BrchNmSelectBox";
import { alert } from "components/web/cmmn/CmmnConfirm";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { callApi } from "util/HttpService";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import TitleLogo from "asset/photomag_web/img/icon/filled/insert-emoticon.svg"
import ButtonUtil from "components/mobile/UI/ButtonUtil";

const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        .p-30 {
            padding: 30px;
        }
        .table-wrap {
            h2 {
                font-size: 18px;
                color: #3b3b3b;
                font-weight: 500;
                margin-bottom: 15px;
            }
        }
    }
    @media (max-width: 1200px) {
        .table-wrap{
            overflow: scroll;
        }
    }
`;
const InputTable = styled.div`
    margin-bottom: 20px;
    border-top: 2px solid #EF2929;
    .input-table-wrap {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
`;
const InputTableHead = styled.div`
    background-color: #F3F3F3;
    padding: 20px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 78px;
    max-width: 208px;
    min-width: 208px;
    h3 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
`;
const InputTableBody = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    h4 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
    }
    .form-select {
        max-width: 250px;
    }
    input[type="text"], input[type="password"] {
        max-width: 250px;
        width: 250px;
    }
    &:nth-child(2) .btn {
        margin-left: 10px;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
`;
const BtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
        width: 150px;
        max-width: 150px;
        &:first-child {
            margin-right: 10px;
            &:hover {
                color: var(--bs-btn-hover-color);
                background-color: var(--bs-btn-hover-bg);
                border-color: var(--bs-btn-hover-border-color);
            }
        }
        &:last-child:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
    }
`;
export default function EqmtMgtDetail() {

    const { t } = useTranslation();
    const brchClsfCd = localStorage.getItem("brchClsfCd");
    const navigate = useNavigate();
    const { eqmtId } = useParams();
    const [eqmtNo, setEqmtNo] = useState(eqmtId);
    const location = useLocation();
    const { brchIdProps } = location.state || {};
    useEffect(() => {
        console.log(brchIdProps);
    }, [brchIdProps]);

    const defaultEqmtInfo = {
        brchId: "",
        eqmtNm: "",
        eqmtMacAddr: "",
        eqmtNo: "",
        delYn: "N",
    }

    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false); // 서브밋 여부
    const [eqmtInfo, setEqmtInfo] = useState(defaultEqmtInfo);

    const validationSchema = Yup.object().shape({
        brchId: Yup.string().required(t("alert.brchNmSelect")),
        eqmtNm: Yup.string().required(t("alert.eqmtNmInput")),
        eqmtMacAddr: Yup.string().required(t("alert.eqmtMacAddrInput"))
    });

    useEffect(() => {
        if (eqmtNo !== "") {
            callApi("GET", null, `/web/api/svc-mgt/ei/detail?eqmtNo=${eqmtNo}`)
                .then(res => {
                    setEqmtInfo(res);
                    setEqmtInfo((prevData) => ({
                        ...prevData,
                    }))
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }, [eqmtNo]);

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setEqmtInfo((prevData) => ({
            ...prevData,
            delYn: 'N',
            [name]: value,
        }));
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (value !== '') {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnUpdtBtn":
            case "cmmnInsertBtn":
                console.log(eqmtInfo);
                validationSchema.validate(eqmtInfo, { abortEarly: false })
                    .then(() => {
                        let reqCd = 'insert';
                        if (eqmtInfo.eqmtId > 0) {
                            reqCd = 'update';
                        }
                        setErrors({})
                        setSubmitted(true);
                        callApi("POST", eqmtInfo, `/web/api/svc-mgt/ei/${reqCd}`)
                            .then(res => {
                                if (res) {
                                    // console.log(res);
                                    alert("", t("alert.save"), () => { navigate("/svc-mgt/eqmt-mgt") });
                                }
                            })
                            .catch(error => {
                                alert("", t("alert.saveError"), () => { });
                            })
                    })
                    .catch((err) => {
                        const newErrors = {};
                        err.inner.forEach((error) => {
                            newErrors[error.path] = error.message;
                        });
                        setErrors(newErrors);
                    });
                break;
            case "cmmnListBtn":
                navigate("/svc-mgt/eqmt-mgt");
                break;
            default: break;
        }
    }

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title space-between">
                    <div className="dp-flex align-cen">
                        <img src={TitleLogo} alt={t("leftMenu.eqmtMgt")} />
                        <h2>{t("leftMenu.eqmtMgt")}</h2>
                    </div>
                    <p>Home {">"} {t("leftMenu.svcMng")} {">"} {t("leftMenu.eqmtMgt")}</p>
                </div>
            </div>
            <DashBoardWrap>
                <div className="dashboard-box">
                    <div className="p-30">
                        <div className="table-wrap">
                            <h2>
                                {eqmtId === "0" ?
                                    `${t("eqmtMgtPage.device")} ${t("common.regLable")}`
                                    :
                                    `${t("eqmtMgtPage.device")} ${t("common.updtLable")}`
                                }
                            </h2>
                            <InputTable>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("userMgtPage.brch")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        {eqmtNo === "0" ?
                                            <BrchNmSelectBox
                                                defaultVal={eqmtInfo.brchId}
                                                tagName={"brchId"}
                                                defaultOptTxt={t("common.selectLable")}
                                                onSelectChange={handleFormDataChange}
                                                errorTxt={errors.brchId}
                                                brchClsfCd={eqmtNo === "0" && brchClsfCd === "KSC" ? "JMC" : ""}
                                            />
                                            :
                                            <BrchNmSelectBox
                                                defaultVal={eqmtInfo.brchId}
                                                tagName={"brchId"}
                                                defaultOptTxt={t("common.selectLable")}
                                                onSelectChange={handleFormDataChange}
                                                errorTxt={errors.brchId}
                                                disabled={true}
                                                frncId={brchIdProps}
                                            />
                                        }
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("eqmtMgtPage.eqmtNm")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="text"
                                            value={eqmtInfo?.eqmtNm || ""}
                                            name="eqmtNm"
                                            onChangeValue={handleFormDataChange}
                                            errorTxt={errors.eqmtNm}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("eqmtMgtPage.eqmtMacAddr")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="text"
                                            value={eqmtInfo?.eqmtMacAddr || ""}
                                            name="eqmtMacAddr"
                                            onChangeValue={handleFormDataChange}
                                            errorTxt={errors.eqmtMacAddr}
                                        />
                                    </InputTableBody>
                                </div>
                                <div className="input-table-wrap">
                                    <InputTableHead>
                                        <h3>{t("eqmtMgtPage.eqmtNo")}</h3>
                                    </InputTableHead>
                                    <InputTableBody>
                                        <TextFields
                                            type="text"
                                            value={eqmtInfo?.eqmtNo || `${t("alert.dvcNumAuto")}`}
                                            name="eqmtNo"
                                            onChangeValue={handleFormDataChange}
                                            disabled={true}
                                            className="readonly"
                                        />
                                    </InputTableBody>
                                </div>
                                {eqmtNo === "0" ?
                                    <></>
                                    :
                                    <>
                                        <div className="col-2">
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.regUserNm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{eqmtInfo?.regUserNm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.chngUserNm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{eqmtInfo?.chngUserNm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.regDttm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{eqmtInfo?.convRegDttm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                            <div className="input-table-wrap w-50">
                                                <InputTableHead>
                                                    <h3>{t("userMgtPage.chngDttm")}</h3>
                                                </InputTableHead>
                                                <InputTableBody>
                                                    <h4>{eqmtInfo?.convChngDttm || ""}</h4>
                                                </InputTableBody>
                                            </div>
                                        </div>
                                    </>
                                }
                            </InputTable>
                        </div>
                        <BtnWrap>
                            <ButtonUtil className="btn btn-outline-light round-solid-medium-btn"
                                value={t("userMgtPage.userList")}
                                onClick={() => { handleCmmnBtnClick("cmmnListBtn") }}
                            />
                            <ButtonUtil className="btn btn-danger round-filled-small-btn"
                                value={t("userMgtPage.userSave")}
                                onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                            />
                        </BtnWrap>
                    </div>
                </div>
            </DashBoardWrap>
        </div>
    )
}