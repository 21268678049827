import { useEffect, useState } from "react";
import styled from "styled-components";
import ButtonUtil from "../UI/ButtonUtil";
import SelectBox from "components/web/UI/SelectBox";
import TextFields from "components/web/UI/TextFields";
import { callApi } from "util/HttpService";
import { alert, modalConfirm } from "components/web/cmmn/CmmnConfirm";

const ListInputWrap = styled.ul`
    border-top: 2px solid #EF2929;
    min-height: 340px;
    .basic-input {
        padding: 10px;
        font-size: 16px;
        color: #3b3b3b;
        border: 1px solid #D3D3D3;
        border-radius: 4px;
        height: 35px;
    }
    &:focus {
        border-color: #FFA500 !important;
    }
    li:last-child {
        // margin-bottom: 80px;
    }
    .frame-input {
        div {
            width: 50% !important;
            input {
                width: 100% !important;
            }
        }
        select {
            width: 50% !important;
        }
    }
`;
const InputTitle = styled.div`
    height: 54px;
    padding: 15px 20px;
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h2 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
    }
    .modal-title {
        margin-bottom: 10px;
        background-color: #fff !important;
        padding: 15px 20px 0px 0px !important;
        h2 {
            font-size: 17px;
            font-weight: 600;
    }
`;
const InputContent = styled.div`
    background-color: #fff;
    padding: 10px 20px 10px 20px;
    & > div {
        // margin-bottom: 10px;
    }
    h3 {
        font-size: 20px;
        color: #3b3b3b;
        font-weight: 500;
        margin-right: 10px;
    }
    h2 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        width: 50%;
    }
    h5 {
        margin-left: 7px;
    }
    input[type="text"]:focus {
        border-color: #FFA500;
    }
`;
const BtnWrap = styled.div`
    width: 100%;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
        width: 32%;
    }
`;
export default function MbSystemConfig({
    t,
    brchClsfCd,
    systemModeArray,
    data,
    kiskInfo,
    onHide,
    getKiskInfo,
}) {
    const [systemSettings, setSystemSettings] = useState({});
    useEffect(() => {
        if (kiskInfo && kiskInfo.sysStupCn) {
            setSystemSettings(kiskInfo.sysStupCn);
        }
    }, [kiskInfo]);

    useEffect(() => {
        console.log(systemSettings);
    }, [systemSettings]);

    const handleChange = (e, key) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setSystemSettings(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    // PC 종료 시간
    function generateTimeOptions() {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 10) {
                const formattedHour = String(hour).padStart(2, '0');
                const formattedMinute = String(minute).padStart(2, '0');
                options.push({
                    key: `${formattedHour}:${formattedMinute}`,
                    value: `${formattedHour}:${formattedMinute}`,
                });
            }
        }
        return options;
    };
    const pcShutdownTimeArray = generateTimeOptions();
    // 서비스 기본 언어
    const defaultLangArray = [
        { key: "한국어", value: "kr" },
        { key: "日本語", value: "jp" },
        { key: "English", value: "en" },
        { key: "简体中文", value: "cn" },
        { key: "แบบไทย", value: "th" },
        { key: "Tiếng Việt", value: "vi" },
        { key: "melayu", value: "ms" },
        { key: "Español", value: "es" },
        { key: "Français", value: "fr" },
        { key: "bahasa Indonesia", value: "id" },
    ];
    // Iot 보드 포트, 결제기 포트
    const comPortsArray = Array.from({ length: 256 }, (_, i) => ({
        key: `COM${i + 1}`,
        value: `COM${i + 1}`,
    }));
    // 동전투입기 선택
    const coinAcceptorArray = [
        { key: "JY", value: "JY" },
        { key: "VN", value: "VN" },
    ];
    // 프린트 출력 설정
    const printCopiesModeArray = [
        { key: "운영", value: "prod" },
        { key: "개발(1장출력)", value: "dev" },
    ];

    const handleCmmnBtnClick = async (btnNm) => {
        switch (btnNm) {
            case "cmmnInsertBtn":
                try {
                    const commonParams = { brchId: data.brchId, eqmtId: data.eqmtId, eqmtNo: data.eqmtNo, delYn: 'N' };

                    // systemSettings
                    const systemParams = { ...commonParams, sysStupCn: systemSettings };
                    await callApi("POST", systemParams, "/web/api/dvc-mgt/edm/kisk-insert");
                    console.log("전송된 systemParams : ", systemParams);

                    alert("", t("alert.save"), () => { getKiskInfo() });

                } catch (error) {
                    console.log(error);
                    alert("", t("alert.saveError"), () => { return });
                }
                break;
            case "cmmnResetBtn":
                try {
                    modalConfirm("", t("alert.resetConfirm"), () => {
                        setSystemSettings(kiskInfo.sysStupCn);
                        alert("", t("alert.initSuccessAlert"), () => { });
                    })
                } catch (error) {
                    console.log(error);
                    alert("", t("alert.initFailAlert"), () => { });
                }
                break;
            default: break;
        };
    };

    return (
        <>
            <ListInputWrap>
                <li>
                    <InputTitle className="modal-title">
                        <h2>1. {t("systemSetting.title")}</h2>
                    </InputTitle>
                    {brchClsfCd === "KSC" &&
                        <>
                            <InputTitle>
                                <h2>{t("systemSetting.lblSystemMode")}</h2>
                            </InputTitle>
                            <InputContent>
                                <SelectBox
                                    defaultOptTxt={t("saleHistPage.entire")}
                                    dataArray={systemModeArray}
                                    onSelectChange={(e) => handleChange(e, 'systemMode')}
                                    tagName="systemMode"
                                    defaultVal={systemSettings?.systemMode || 'S1'}
                                />
                            </InputContent>
                        </>
                    }
                </li>
                <li>
                    <InputTitle>
                        <h2>{t("systemSetting.lblPcShutDown")}</h2>
                    </InputTitle>
                    <InputContent>
                        <SelectBox
                            defaultOptTxt={t("saleHistPage.entire")}
                            dataArray={pcShutdownTimeArray}
                            onSelectChange={(e) => handleChange(e, 'pcShutdownTime')}
                            tagName="pcShutdownTime"
                            defaultVal={systemSettings?.pcShutdownTime || '17:00'}
                        />
                    </InputContent>
                </li>
                {brchClsfCd === "KSC" &&
                    <li>
                        <InputTitle>
                            <h2>{t("systemSetting.lblEqmtNo")}</h2>
                        </InputTitle>
                        <InputContent>
                            <TextFields
                                type="text"
                                name="eqmtNo"
                                onChangeValue={(e) => handleChange(e, 'eqmtNo')}
                                value={systemSettings?.eqmtNo || ""}
                                className="w-100"
                            />
                        </InputContent>
                    </li>
                }
                {brchClsfCd === "KSC" &&
                    <li>
                        <InputTitle>
                            <h2>{t("systemSetting.lblServiceDefaultLang")}</h2>
                        </InputTitle>
                        <InputContent>
                            <SelectBox
                                defaultOptTxt={t("saleHistPage.entire")}
                                dataArray={defaultLangArray}
                                onSelectChange={(e) => handleChange(e, 'defaultLang')}
                                tagName="defaultLang"
                                defaultVal={systemSettings?.defaultLang || 'jp'}
                            />
                        </InputContent>
                    </li>
                }
                {brchClsfCd === "KSC" &&
                    <li>
                        <InputTitle>
                            <h2>{t("systemSetting.lblIoTBoardComPort")}</h2>
                        </InputTitle>
                        <InputContent>
                            <SelectBox
                                defaultOptTxt={t("saleHistPage.entire")}
                                dataArray={comPortsArray}
                                onSelectChange={(e) => handleChange(e, 'iotComPort')}
                                tagName="iotComPort"
                                defaultVal={systemSettings.iotComPort || 'COM2'}
                            />
                        </InputContent>
                    </li>
                }
                {brchClsfCd === "KSC" &&
                    <li>
                        <InputTitle>
                            <h2>{t("systemSetting.lblCoinAcceptorComPort")}</h2>
                        </InputTitle>
                        <InputContent>
                            <SelectBox
                                defaultOptTxt={t("saleHistPage.entire")}
                                dataArray={comPortsArray}
                                onSelectChange={(e) => handleChange(e, 'coinAcceptorComPort')}
                                tagName="coinAcceptorComPort"
                                defaultVal={systemSettings.coinAcceptorComPort || 'COM2'}
                            />
                        </InputContent>
                    </li>
                }
                {brchClsfCd === "KSC" &&
                    <li>
                        <InputTitle>
                            <h2>{t("systemSetting.lblCoinAcceptorSelect")}</h2>
                        </InputTitle>
                        <InputContent>
                            <SelectBox
                                defaultOptTxt={t("saleHistPage.entire")}
                                dataArray={coinAcceptorArray}
                                onSelectChange={(e) => handleChange(e, 'coinAcceptor')}
                                tagName="coinAcceptor"
                                defaultVal={systemSettings.coinAcceptor || 'JY'}
                            />
                        </InputContent>
                    </li>
                }
                {brchClsfCd === "KSC" &&
                    <li>
                        <InputTitle>
                            <h2>{t("systemSetting.lblPrintCopiesModeSelect")}</h2>
                        </InputTitle>
                        <InputContent>
                            <SelectBox
                                defaultOptTxt={t("saleHistPage.entire")}
                                dataArray={printCopiesModeArray}
                                onSelectChange={(e) => handleChange(e, 'printCopiesMode')}
                                tagName="printCopiesMode"
                                defaultVal={systemSettings.printCopiesMode || 'prod'}
                            />
                        </InputContent>
                    </li>
                }
            </ListInputWrap>
            <BtnWrap>
                <ButtonUtil className="btn btn-danger round-filled-small-btn"
                    value={t("common.lblInitBtn")}
                    onClick={() => { handleCmmnBtnClick("cmmnResetBtn") }}
                />
                <ButtonUtil className="btn btn-outline-light round-solid-medium-btn"
                    value={t("common.cancelLable")}
                    onClick={() => { onHide() }}
                />
                <ButtonUtil className="btn btn-danger round-filled-small-btn"
                    value={t("common.saveLable")}
                    onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                />
            </BtnWrap>
        </>
    )
}