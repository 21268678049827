import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const TextField = styled.div`
    margin-bottom: 20px;
    width: 100%;
    position: relative;
    label {
        font-size: 16px;
        font-weight: 300;
    }
    &.loginId,
    &.loginPw {
        input {
            border: 1px solid ${({ theme }) => theme.colors.red};
            font-size: 16px;
            padding: 15px 20px;
            border-radius: 100px;
        }
    }
    &.loginPwChg {
        input {
            font-size: 16px;
            padding: 15px 20px;
            border-radius: 100px;
        }
    }
    .input-container {
        position: relative;
        display: flex;
        align-items: center;
    }
    .password-toggle-button {
        position: absolute;
        right: 21px;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        color: #6b6b6b;
        font-size: 21px;
    }
    .password-toggle-button:focus {
        outline: none;
    }
    @media(min-width: 1600px) {
        .password-toggle-button {
            right: 23px;
            font-size: 23px;
        }
    }
`;

function MobileTextFields({
    type,
    className,
    name,
    value,
    placeholder,
    readonly,
    disabled,
    errorTxt,
    onChangeValue,
    onKeyDown,
    id,
    label,
    icon,
    iconOnClick,
    autoComplete
}) {

    let classNames = [];
    if (className) {
        classNames = classNames.concat(className.split(" "));
    }
    if (errorTxt) {
        classNames.push("error");
    }
    const combinedClassName = classNames.join(" ");

    return (
        <>
            <TextField className={combinedClassName}>
                <label key={id} className="form-label">{label}</label>
                <div className="input-container">
                    <input
                        type={type}
                        placeholder={placeholder}
                        name={name}
                        value={value}
                        readOnly={readonly}
                        disabled={disabled}
                        onChange={onChangeValue}
                        onKeyDown={onKeyDown}
                        className="form-control"
                        autoComplete={autoComplete}
                    />
                    {value && icon && (
                        <button
                            type="button"
                            onClick={iconOnClick}
                            className="password-toggle-button"
                        >
                            <FontAwesomeIcon icon={icon} fixedWidth/>
                        </button>
                    )}
                </div>
            </TextField>
        </>
    )
}
export default MobileTextFields;