'use client'

import * as apiSvc from "api/components/cmmn/CmmnApiCallService"
import { useEffect, useState } from "react"
import { Form } from "react-bootstrap";
import styled from "styled-components";

const ErrorMsg = styled.p`
    margin-left: 1rem;
    font-size: 0.9rem;
    color: #ef2929;
`;
export default function BrchNmSelectBox(
    {
        defaultOptTxt
        , defaultVal
        , tagName
        , onSelectChange
        , readonly
        , className
        , disabled
        , errorTxt
        , brchClsfCd
        , frncId
    }
) {

    const [showOptions, setShowOptions] = useState(false);
    const [selectedVal, setSelectedVal] = useState(defaultOptTxt);
    const [brchAllList, setBrchAllList] = useState([]);


    const HandleShowOption = () => {
        //readonly 가 아닐때만 작동
        if (readonly !== true) {
            setShowOptions((current) => !current);
        }
    };
    const HandleMouseLeave = () => {
        setShowOptions(false);
    };

    useEffect(() => {
        apiSvc.GetBrchList({ brchClsfCd, frncId })
            .then(res => {
                setBrchAllList(res);
                if (defaultVal === 0 || defaultVal === '') {
                    setSelectedVal('');
                } else {
                    const selectedAthr = res.find(data => data.brchId == defaultVal);
                    if (selectedAthr) {
                        setSelectedVal(selectedAthr.brchId);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, [defaultVal]);

    const HandleSelected = (txt) => {
        setSelectedVal(txt);
    };

    const handleSelectChange = (e) => {
        const selectedVal = e.target.value;
        setSelectedVal(selectedVal);
        const event = { target: { name: tagName, value: selectedVal } };
        onSelectChange(event);
    };


    return (
        <>
            <Form.Select
                name={tagName}
                value={selectedVal}
                onChange={handleSelectChange}
                disabled={disabled}
                className={className}
            >
                <option value="">{defaultOptTxt}</option>
                {brchAllList.map((brch) => (
                    <option key={brch.brchId} value={brch.brchId}>
                        {brch.brchNm}
                    </option>
                ))}
            </Form.Select>
            {errorTxt && <ErrorMsg>{errorTxt}</ErrorMsg>}
        </>
    );

}