import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "asset/mobile_web/img/logo.svg";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LangSelectBox from "components/web/cmmn/LangSelectBox";
import { useTranslation } from "react-i18next";

const HeaderWrap = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 15px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    img {
        width: 130px;
    }
    button {
        background-color: #fff;
        .menu_icon {
            font-size: 20px;
            color: ${({ theme }) => theme.colors.dark2};
        }
    }
    .top-nav-right-box{
        display: flex;
        align-items: center;
        width: 50%;
        .form-select {
            width:150px;
            min-width: 100px;
        }
        .mr-5 {
            margin-right:5px;
        }
    }
`;

function Header({ showSideMenu }) {
    const { t } = useTranslation();
    library.add(faBars);

    return (
        <HeaderWrap>
            <Link to="/mobile/dsh-brd/dsh-brd">
                <img src={logo} alt="photomag_logo" />
            </Link>
            
            <div className="top-nav-right-box">
                <LangSelectBox
                        className="form-select-md mr-5"
                        tagName={"language"}
                />
                
                <button onClick={showSideMenu}>
                    <FontAwesomeIcon icon={faBars} className="menu_icon" />
                </button>
            </div>
        </HeaderWrap>
    )


}
export default Header;