import { BarController, CategoryScale, Chart as ChartJS, Legend, LineController, LineElement, LinearScale, PointElement, Title, Tooltip, scales } from "chart.js";
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import styled from "styled-components";
import { useTranslation } from "react-i18next";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, BarController, Title, Tooltip, Legend);

const ChartBox = styled.div`
    width: 100%;
    height: 250px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default function CrdStatChart({crdData}) {
    const {t} = useTranslation();
    const data = {
        labels: [t("m_dashboardPage.creditHeld"),t("m_dashboardPage.creditUse")],
        datasets: [
          {
            label: '',
            type: "bar",
            data: crdData,
            borderColor:"",
            backgroundColor: "",
            borderWidth: 1,
            fill: true,
            tension: 0.1,
            barPercentage: 0.5
          },
          
        ]
      };
    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        plugins: {
          legend: {
            //position: 'right',
            display:false
          },
          datalabels: {
            display:false
          },
          tooltip:{
            boxWidth: 15,
          },
          title: {
            display: false,
            text: 'Chart.js Horizontal Bar Chart'
          }
        },
        scales:{
            
        },
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        responsive: true,
        
      };

    return (
        <ChartBox>
            <Bar options={options} data={data} width={"550px"} height={"380px"} />
        </ChartBox>
    )

}