import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import titleLogo from "asset/mobile_web/img/icon/filled/developer-board.svg";
import ButtonUtil from "../UI/ButtonUtil";
import { Nav, Tab } from "react-bootstrap";
import MbSystemConfig from "./MbSystemConfig";
import MbProgramConfig from "components/mobile/cmmn/MbProgramConfig";
import MbSmartCorrectionConfig from "./MbSmartCorrectionConfig";
import { callApi } from "util/HttpService";
import { SyncLoader } from "react-spinners";
const LoadingStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(848px - 250px);
    width: 100%;
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        text-align: center;
        line-height: 150%;
    }
`;
const ModalWrap = styled.div`
    background-color: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    transform: translateY(100%);
    transition: transform 0.4s, visibility 0.4s;
    ${(props) =>
        props.$isVisible &&
        css`
            visibility: visible;
            transform: translateY(0);
        `
    }
    width: 100%;
    position: fixed;
    z-index: 1000;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    height: ${(props) => props.$viewportHeight}px;
    .dp-flex {
        display: flex;
    }
    .align-cen {
        align-items: center;
    }
    .space-between {
        justify-content: space-between;
    }
    .nav-tabs {
        border: none;
        display: flex;
        padding: 15px;
        flex-wrap: nowrap;
        .nav-item {
            margin-right: 5px;
            &:last-child {
                margin: 0;
            }
            .nav-link {
                border: none;
                font-size: 14px;
                background-color: ${({ theme }) => theme.colors.gray5};
                border: 1px solid ${({ theme }) => theme.colors.gray5};
                color: ${({ theme }) => theme.colors.gray6};
                border-radius: 100px;
                &.active {
                    border: 1px solid ${({ theme }) => theme.colors.red};
                    border-radius: 100px;
                    color: ${({ theme }) => theme.colors.red};
                    font-size: 14px;
                    background-color: var(--bs-nav-tabs-link-active-bg) !important;
                }
            }
        }
    }
    .tab-content {
        margin-top: 20px;
        .tab-pane {
            padding: 0px 20px 20px 20px;
        }
    }
`;
const ModalHeader = styled.div`
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray7};
    .img-box {
        display: flex;
        img {
            margin-right: 10px;
        }
    }
    h2 {
        position: relative;
        top: 0.9px;
        font-size: 16px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: 500;
    }
    button {
        background-color: #fff;
        svg {
            font-size: 16px;
            color: ${({ theme }) => theme.colors.dark2};
        }
    }
`;
const ModalContent = styled.div`
    width: 100%;
    height: calc(${(props) => props.$viewportHeight}px - 200px);
    overflow-y: auto;
    h3 {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: bold;
        width: 35%;
    }
    h4 {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.dark2};
        font-weight: 400;
        width: 70%;
    }
    h5 {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.dark2};
        margin-left: 5px;
    }
    .input_box:last-of-type {
        margin: 0 !important;
    }
`;
export default function CmmnMbSysCnfModal({
    show,
    onHide,
    data,
    brchClsfCd,
    t,
}) {
    library.add(faXmark);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    useEffect(() => {
        const updateViewportHeight = () => {
            setViewportHeight(window.innerHeight);
        };

        window.addEventListener('resize', updateViewportHeight);
        updateViewportHeight();

        return () => {
            window.removeEventListener('resize', updateViewportHeight);
        };
    }, []);
    useEffect(() => {
        const preventScroll = (e) => e.preventDefault();

        const handleKeyboardUp = () => {
            window.addEventListener('touchmove', preventScroll, { passive: false });
        };

        const handleKeyboardDown = () => {
            window.removeEventListener('touchmove', preventScroll);
        };

        window.addEventListener('keyboardDidShow', handleKeyboardUp);
        window.addEventListener('keyboardDidHide', handleKeyboardDown);

        return () => {
            window.removeEventListener('touchmove', preventScroll);
            window.removeEventListener('keyboardDidShow', handleKeyboardUp);
            window.removeEventListener('keyboardDidHide', handleKeyboardDown);
        };
    }, []);

    const tabs = [
        { key: "system", title: t("tabTitle.lblSystem") },
        { key: "program", title: t("tabTitle.lblProgram") },
        ...(brchClsfCd === "KSC" ? [{ key: "smart", title: t("tabTitle.lblAILab") }] : []),
    ];
    const systemModeArray = [
        { key: "STANDARD", value: "S1" },
        { key: "STANDARD-ENT", value: "S2" },
        { key: "HIGH-ANGLE", value: "H1" },
        { key: "HIGH-ANGLE Built-In", value: "H2" },
    ];
    const [activekey, setActivekey] = useState(tabs[0].key);
    const [isVisible, setIsVisible] = useState(false);
    const [systemMode, setSystemMode] = useState("");
    const [kiskInfo, setKiskInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const modalRef = useRef(null);
    const timerRef = useRef(null);

    const getKiskInfo = () => {
        const fetchData = () => {
            callApi("GET", null, `/web/api/dvc-mgt/edm/kisk-info?eqmtNo=${data.eqmtNo}`)
                .then(res => {
                    if (Object.keys(res).length === 0) {
                        console.log("데이터 없음 5초 후 다시 호출");
                        setLoading(true);
                        timerRef.current = setTimeout(fetchData, 5000);
                    } else {
                        console.log("res 데이터 있음 : ", res);

                        setKiskInfo(res);
                        setLoading(false);
                        const selectedMode = systemModeArray.find((mode) => mode.value === res.sysStupCn.systemMode);
                        if (selectedMode) {
                            setSystemMode(selectedMode);
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        };
        fetchData();
    };

    useEffect(() => {
        const handleResize = () => {
            if (show) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'unset';
            }
        };
        if (show) {
            document.body.style.overflow = 'hidden';
            window.addEventListener('resize', handleResize);
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            document.body.style.overflow = 'unset';
        };
    }, [show]);

    useEffect(() => {
        if (show) {
            setActivekey(tabs[0].key);
            setIsVisible(true);
            if (modalRef.current) {
                modalRef.current.scrollTop = 0;
            }
            document.body.style.overflow = "hidden";
            getKiskInfo();
        } else {
            setIsVisible(false);
            document.body.style.overflow = 'unset';
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
        }
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
            document.body.style.overflow = 'unset';
        };
    }, [show]);

    // 탭 전환 시 맨 위로 이동
    const handleTabSelect = (k) => {
        setActivekey(k);
        if (modalRef.current) {
            modalRef.current.scrollTop = 0;
        }
    };

    return (
        <ModalWrap $isVisible={isVisible} id="cnfModal" $viewportHeight={viewportHeight}>
            <ModalHeader>
                <div className="img-box">
                    <img src={titleLogo} alt={titleLogo} />
                    <h2>{data && data !== undefined ? `${data.eqmtNm} ${t("m_deviceMgtPage.config")}` : ""}</h2>
                </div>
                <ButtonUtil className="close-btn bottom-modal-close-btn"
                    onClick={onHide}>
                    <FontAwesomeIcon icon={faXmark} className="close-i" color="black" height="25" />
                </ButtonUtil>
            </ModalHeader>
            <Tab.Container activeKey={activekey} onSelect={handleTabSelect}>
                <Nav variant="tabs" as="ul">
                    {tabs.map((tab, idx) => (
                        <Nav.Item as="li" key={idx}>
                            <Nav.Link eventKey={tab.key} as="button">
                                {tab.title}
                            </Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
            </Tab.Container>
            {loading ?
                <LoadingStyle>
                    <div style={{ height: "60px" }}>
                        <h2>Loading...</h2>
                    </div>
                    <div style={{ height: "50px" }}>
                        <SyncLoader />
                    </div>
                </LoadingStyle>
                :
                <ModalContent $viewportHeight={viewportHeight} ref={modalRef}>
                    <Tab.Container activeKey={activekey} onSelect={handleTabSelect}>
                        <Tab.Content>
                            {tabs.map((tab, index) => (
                                <Tab.Pane key={index} eventKey={tab.key}>
                                    {tab.key === "system" && (
                                        <MbSystemConfig
                                            t={t}
                                            brchClsfCd={brchClsfCd}
                                            systemModeArray={systemModeArray}
                                            kiskInfo={kiskInfo}
                                            data={data}
                                            onHide={onHide}
                                            getKiskInfo={getKiskInfo}
                                        />
                                    )}
                                    {tab.key === "program" && (
                                        <MbProgramConfig
                                            t={t}
                                            brchClsfCd={brchClsfCd}
                                            systemMode={systemMode}
                                            kiskInfo={kiskInfo}
                                            onHide={onHide}
                                            data={data}
                                            getKiskInfo={getKiskInfo}
                                        />
                                    )}
                                    {tab.key === "smart" && (
                                        <MbSmartCorrectionConfig
                                            t={t}
                                            brchClsfCd={brchClsfCd}
                                            kiskInfo={kiskInfo}
                                            onHide={onHide}
                                            data={data}
                                            getKiskInfo={getKiskInfo}
                                        />
                                    )}
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Tab.Container>
                </ModalContent>
            }
        </ModalWrap>
    )

}