import styled from "styled-components";
import ButtonUtil from "../UI/ButtonUtil";
import { useTranslation } from "react-i18next";

const IconBtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 32%;
        background-color: #fff;
        border: 1px solid #eeeeee;
        border-radius: 8px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: all .3s ease-in-out;
        &:hover {
            border: 2px solid ${({ theme }) => theme.colors.red};
        }
        h4 {
            font-size: 16px;
            color: ${({ theme }) => theme.colors.dark2};
            font-weight: 400;
            text-align: center;
            line-height: 130%;
            margin-top: 5px;
        }
    }
`;

export default function CmmnRestartIcon(
    {
        dataArray,
        handleClick,
    }
) {

    const {t} = useTranslation();

    return (
        <IconBtnWrap>
            {dataArray.map((data, idx) => (
                <ButtonUtil key={idx}
                            onClick={() => handleClick(data.value)}
                >
                    <img src={data.img} alt={`${data.img}_logo`} />
                    <h4>{data.label}<br />{t("deviceMgtPage.restart")}</h4>
                </ButtonUtil>
            ))}
        </IconBtnWrap>
    )
}