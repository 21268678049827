import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from 'yup';
import TextFields from "components/web/UI/TextFields";
import * as httpService from "util/HttpService"
import { useTranslation } from 'react-i18next';
//image
import group from "asset/img/icon/filled/group.svg";

//component
import CustomCheckboxRenderer from "components/web/sys-mgt/CustomCheckboxRenderer";
import CmmnBtnContainer from "components/web/cmmn/CmmnBtnContainer";
import CustomCmmnCdSelectBox from "components/web/sys-mgt/CustomCmmnCdSelectBox"
import { confirm, alert } from "components/web/cmmn/CmmnConfirm";
import DefaultAthrCheckbox from "./component/DefaultAthrCheckBox";

const DashboardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    width: 100%;
    align-items: flex-start !important;
    > div{
        width:49.2%;
    }
`;

const DashboardBox = styled.div`
    background-color: #fff;
    border-radius: 16px;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    .table-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        h2{
            font-size: 18px;
            color: #3b3b3b;
            font-weight: 500;
        }
    }
    .p-30{
        padding: 30px;
    }
`;

const TableWrap = styled.div`
    .table .thead .th{
        background-color: #F3F3F3;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        color: #3b3b3b;
        padding: 15px 20px;
        height: 50px;
    }
    .table .tbody .tb{
        background-color: #fff;
        font-size: 16px;
        text-align: center;
        font-weight: 400;
        color: #3b3b3b;
        padding: 15px 20px;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .table .thead .th-small{
        padding: 15px 20px;
        font-size: 14px;
    }
    .table .tbody .tb-small{
        padding: 15px 20px;
        font-size: 14px;
    }
    .table .thead{
        display: flex;
        align-items: center;
        width: 100%;
    }
    .table .tbody{
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
    .scroll-wrap{
        overflow-y: scroll;
    }
    .h-10{
        height: 500px;
    } 
    .w-2{
        width: 20%;
    }
    .w-3{
        width: 30%;
    }
    .w-4{
        width: 40%;
    }   
    .input-table{
        margin-bottom: 20px;
    }
    .input-table-th{
        background-color: #F3F3F3;
        padding: 20px;
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 80px;
    }
    .input-table-th h3{
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
    .input-table-th-small{
        max-width: 138px;
        min-width: 138px;
    }
    .input-table-wrap{
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
    .input-table-body{
        display: flex;
        align-items: flex-start;
        padding: 20px;
        width: 100%;
    }

    .input-table-body h4{
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
    }
`;

const OptionBtnWrap = styled.div`
    display: flex;
    align-items: center;
    button:nth-child(2) {
        margin: 0 10px;
    }
    .round-solid-small-btn{
        font-size: 14px;
        color: #8B8B8B;
        border-radius: 100px;
        border: 1px solid #8B8B8B;
        width: 100%;
        padding: 7px 20px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const InputTable = styled.div`
    margin-bottom: 20px;
    margin: 0 !important;
    border-top: 2px solid #EF2929;
`;

const InputTableWrap = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,.1);
    .input-table-th{
        background-color: #F3F3F3;
        padding: 20px;
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 80px;
    }
    .input-table-th-small{
        max-width: 138px;
        min-width: 138px;
    }
    .input-table-body{
        display: flex;
        align-items: flex-start;
        padding: 10px 20px;
        width: 100%;
    }
    .w-6{
        width: 60%;
    }
    .w-10{
        width:100%;
    }
    .mr-20{
        margin-right: 20px;
    }
    .checkbox-wrap{
        display: flex;
        align-items: center;
    }
    .checkbox-wrap label{
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
        margin-left: 10px;
    }
    select{
        max-width: 195px;
    }
    textarea{
        height: 100px;
        width: 100%;
    }
    .h-2row{
        min-height: 145px !important;
    }
    .basic-input{
        padding: 10px;
        font-size: 16px;
        color: #3b3b3b;
        border: 1px solid #D3D3D3;
        border-radius: 4px;
        height: 35px;
    }
    .textarea-box{
        padding: 10px;
        font-size: 16px;
        color: #3b3b3b;
        border: 1px solid #D3D3D3;
        border-radius: 4px;
    }
    
`;

export default function AuthMgt() {
    const {t} = useTranslation();

    // 권한 목록 수
    const [listCount,setListCount] = useState(0);

    // 기본 권한 상세 정보
    const defaultAthrDtlInfo = {
        athrId: "",
        athrClsfCd: "",
        athrNm: "",
        athrExpl: "",
    };
    const [athrId, setAthrId] = useState(0); // 등록/저장 구분용
    const [athrDtlInfo, setAthrDtlInfo] = useState(defaultAthrDtlInfo);

    // 기본 권한 리스트
    const [athrList, setAthrList] = useState([]);
    
    useEffect(() => {
        getAthrList();
    },[]);

    useEffect(() => {
        getPrgmList();
    },[]);

    const getAthrList = () => {
        httpService.callApi("GET","","/web/api/athr-mgt/list")
        .then(response => {
            const content = response;
            setAthrList(content.t1);
            setListCount(content.t2);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    };

    // 프로그램 권한 관계 리스트
    const [defaultAthrPrgmInfo, setDefaultAthrPrgmInfo] = useState([]);
    const [athrPrgmRelList, setAthrPrgmRelList] = useState([]);
    //const [defaultRowData, setDefaultRowData] = useState([]);

    // 기본 프로그램 정보 불러오기
    const getPrgmList = () => {
        httpService.callApi("GET","","/web/api/athr-mgt/prgm-list")
        .then(response => {
            setDefaultAthrPrgmInfo([]);
            response.forEach(data => {
                setDefaultAthrPrgmInfo(defaultAthrPrgmInfo=>[
                    ...defaultAthrPrgmInfo,
                    {
                        athrId: "",
                        prgmId: data.prgmId,
                        prgmNm: data.prgmNm,
                        delAthrYn: "N",
                        iqryAthrYn: "N",
                        regAthrYn: "N",
                        updtAthrYn: "N",
                    }
                ])
            })
        })
        .catch(error => {
            console.error(error);
        });
    };

    // form data 변경처리
    const handleFormDataChange = (event) => {
        const {name, value} = event.target;
        setAthrDtlInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // validation 설정
    const validationSchema = Yup.object().shape({
        athrClsfCd: Yup.string().required(t("alert.insertClsfCd")),
        athrNm: Yup.string().required(t("alert.insertAuthNm")),
    });
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    // button click
    const handleBtnClick = (btnNm) => {
        
        switch(btnNm) {
            case 'cmmnRegBtn':
                validationSchema.validate(athrDtlInfo, { abortEarly: false })
                .then(() => {
                    setErrors({});
                    setSubmitted(true);
                    httpService.callApi("POST",athrDtlInfo,"/web/api/athr-mgt/athr-info-insert")
                    .then(response => {
                        const regAthrPrgmInfo = defaultAthrPrgmInfo.map(({prgmNm, ...rest}) => rest).map(data => ({
                            ...data,
                            athrId:response.athrId,
                        }));

                        httpService.callApi("POST",regAthrPrgmInfo,"/web/api/athr-mgt/athr-prgm-rel-insert")
                        .then(response => {
                            setAthrDtlInfo(defaultAthrDtlInfo);
                            setAthrId(0);
                            setDefaultAthrPrgmInfo([]);
                            getPrgmList();
                            // 프로그램권한관계 초기화
                            alert("알림",t("alert.reg"),() => {getAthrList()});
                        })
                        .catch(error => {
                            console.error(error);
                            alert("알림",t("alert.regError"),() => {getAthrList()});
                        });
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
                })
                .catch((err) => {
                    const newErrors = {};
                    err.inner.forEach((error) => {
                        newErrors[error.path] = error.message;
                        console.log(error.message);
                    });
                    setErrors(newErrors);
                })
            break;
            case 'cmmnUpdtBtn':
                validationSchema.validate(athrDtlInfo, { abortEarly: false })
                .then(() => {
                    setErrors({});
                    setSubmitted(true);
                    httpService.callApi("POST",athrDtlInfo,"/web/api/athr-mgt/athr-info-update")
                    .then(response => {
                        console.log(response);
                        httpService.callApi("POST",athrPrgmRelList,"/web/api/athr-mgt/athr-prgm-rel-update")
                        .then(response => {
                            setAthrDtlInfo(defaultAthrDtlInfo);
                            setAthrId(0);
                            // 프로그램권한관계 초기화
                            setDefaultAthrPrgmInfo([]);
                            setAthrPrgmRelList([]);
                            getPrgmList();
                            alert("알림",t("alert.update"), () => {getAthrList()});
                        })
                        .catch(error => {
                            console.error(error);
                            alert("알림",t("alert.updateError"), () => {getAthrList()});
                        })
                    })
                    .catch(error => {
                        console.error('Error fetching data', error);
                    });
                })
                .catch((err) => {
                    const newErrors = {};
                    err.inner.forEach((error) => {
                        newErrors[error.path] = error.message;
                    });
                    setErrors(newErrors);
                })
            break;
            case 'cmmnDelBtn':
                if( athrId === 0 ){
                    alert("알림", t("alert.deleteAthrCheck"),() => {});
                }else{
                    confirm("확인", t("alert.deleteConfirm"),() => {
                        httpService.callApi("GET",{athrId:athrId},"/web/api/athr-mgt/athr-del")
                        .then(response => {
                            setAthrDtlInfo(defaultAthrDtlInfo);
                            setAthrPrgmRelList(defaultAthrPrgmInfo);
                            setAthrId(0);
                            alert("알림",t("alert.delete"),() => {getAthrList()});
                        })
                        .catch(error => {
                            console.error("Error fetching data:", error);
                            alert("알림",t("alert.deleteError"),() => {getAthrList()});
                        });
                    });
                }
            break;
            default:
            break;
        }

    };

    useEffect(() => {
    },[athrPrgmRelList]);

    const onRowDobuleClicked = (val) => {
        const athrId = val.athrId;
        setAthrId(athrId);
        setErrors({});
        setSubmitted(true);
        setAthrPrgmRelList([]);
        getPrgmList();
        
        //권한 상세 조회
        httpService.callApi("GET",{athrId:athrId},"/web/api/athr-mgt/athr-dtl")
        .then(response => {
            setAthrDtlInfo(response);
            setAthrDtlInfo(prvData=>({
                ...prvData,
                athrClsfCd:response.athrClsfCd,
            }));
            athrDtlInfo.athrClsfCd = response.athrClsfCd;
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });

        // 권한&프로그램 관계조회
        httpService.callApi("GET",{athrId:athrId},"/web/api/athr-mgt/athr-prgm-rel")
        .then(response => {
            if(response.length !== 0){
                setAthrPrgmRelList(response);
            }else{
                setAthrPrgmRelList(defaultAthrPrgmInfo);
            }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    };


    const handleCheckBoxChange = (idx,value) => {
        const athrValue = value.newValue;
        const athrName = value.name;
        if(athrPrgmRelList.length > 0){
            athrPrgmRelList[idx][athrName] = athrValue;
        }else{
            defaultAthrPrgmInfo[idx][athrName] = athrValue;
        }
    }

    // const testoutput =() => {
    //     console.log(defaultAthrPrgmInfo);
    //     console.log(athrPrgmRelList);
    //     console.log(athrDtlInfo);
    // };

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title space-between">
                    <div className="dp-flex align-cen">
                        <img src={group} alt="group"/>
                        <h2>{t("authMgtPage.authMgt")}</h2>
                    </div>
                    <p>Home {">"} {t("authMgtPage.authMgt")}</p>
                </div>
            </div>
            <DashboardWrap>
                <DashboardBox>
                    <div className="table-title">
                        <h2>{t("authMgtPage.authList")} ({t("authMgtPage.total")} {listCount}{t("authMgtPage.case")})</h2>
                    </div>

                    <div className=" p-30">
                        <TableWrap>
                            <div className="table scroll-wrap h-10" id="table1">
                                <div className="thead">
                                    <div className="th w-2">{t("authMgtPage.num")}</div>
                                    <div className="th w-4">{t("authMgtPage.division")}</div>
                                    <div className="th w-3">{t("authMgtPage.authNm")}</div>
                                    <div className="th w-3">{t("authMgtPage.user")}</div>
                                </div>
                                <div className="tbody-wrap">
                                    {athrList?.map((val,index) => {
                                        return (
                                            <div className="tbody" key={val.athrId} onDoubleClick={() => onRowDobuleClicked(val)}>
                                                <div className="tb w-2">{index+1}</div>
                                                <div className="tb w-4">{val.athrClsfCdNm}</div>
                                                <div className="tb w-3">{val.athrNm}</div>
                                                <div className="tb w-3">{val.userCnt} {t("authMgtPage.people")}</div>
                                            </div>
                                        )
                                    })}
                                    
                                </div>
                            </div>
                        </TableWrap>
                    </div>
                </DashboardBox>

                <div className="column-wrap">
                    <DashboardBox>
                        <div className="table-title">
                            <h2>{t("authMgtPage.mgtMenuPermss")}</h2>
                            <OptionBtnWrap>
                                <CmmnBtnContainer onCmmnBtnClick={handleBtnClick} showBtnArr={athrId !== 0 ? ["updt","del"]: ["reg"]} className="btn round-solid-small-btn btn-outline-secondary logout-btn"/>
                               

                            </OptionBtnWrap>
                        </div>

                        <div className="p30">
                            <TableWrap>
                                <InputTable>
                                    <InputTableWrap>
                                        <div className="input-table-th input-table-th-small">
                                            <h3>{t("authMgtPage.division")}</h3>
                                        </div>
                                        <div className="input-table-body">
                                            <div className="dp-flex align-cen w-100">
                                                
                                                <CustomCmmnCdSelectBox
                                                        className={"form-select form-select-md w-10 mr-20"} 
                                                        cmmnCdId={5} 
                                                        defaultVal={athrDtlInfo.athrClsfCd}
                                                        defaultOptTxt={"선택"}
                                                        tagName={"athrClsfCd"}
                                                        onSelectChange={handleFormDataChange}
                                                        errorTxt={errors.athrClsfCd}
                                                        />
                                                <div className="checkbox-wrap">
                                                     <DefaultAthrCheckbox
                                                        id={"athrClsfCd"}
                                                        name={"athrClsfCd"}
                                                        tagName={"athrClsfCd"}
                                                        value={""}
                                                        label={t("authMgtPage.adminAuth")}
                                                        onSelectChange={handleFormDataChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </InputTableWrap>
                                    <InputTableWrap>
                                        <div className="input-table-th input-table-th-small">
                                            <h3>{t("authMgtPage.authNm")}</h3>
                                        </div>
                                        <div className="input-table-body">
                                            <TextFields type="text"
                                                                name="athrNm"
                                                                className="w250"
                                                                value={athrDtlInfo.athrNm}
                                                                onChangeValue={handleFormDataChange}
                                                                errorTxt={errors.athrNm}
                                                                />
                                        </div>
                                    </InputTableWrap>
                                </InputTable>

                                <InputTableWrap>
                                    <div className="input-table-th input-table-th-small h-2row">
                                        <h3>{t("authMgtPage.explan")}</h3>
                                    </div>
                                    <div className="input-table-body">
                                            <textarea 
                                                id="memo" 
                                                className="textarea-box" 
                                                name="athrExpl"
                                                value={athrDtlInfo.athrExpl}
                                                onChange={handleFormDataChange}
                                                placeholder={t("authMgtPage.enterExplan")}
                                                >
                                            </textarea> 
                                    </div>
                                </InputTableWrap>
                            </TableWrap>
                        </div>
                    </DashboardBox>
                    
                    <DashboardBox>
                        <div className=" p-30">
                            <TableWrap>
                                <div className="table scroll-wrap h-10" id="table1">
                                    <div className="thead">
                                        <div className="th w-3">LNB</div>
                                        <div className="th w-2">{t("common.searchLable")}</div>
                                        <div className="th w-2">{t("common.regLable")}</div>
                                        <div className="th w-2">{t("common.updtLable")}</div>
                                        <div className="th w-2">{t("common.delLable")}</div>
                                    </div>
                                    {athrPrgmRelList.length > 0 ?
                                        athrPrgmRelList.map((data,index) => {
                                            return (
                                                <div className="tbody-wrap" key={index}>
                                                    <div className="tbody">
                                                        <div className="tb w-3">{data.prgmNm}</div>
                                                        <div className="tb w-2"><CustomCheckboxRenderer key={`${index}-iqryAthr`} name={"iqryAthrYn"} value={data.iqryAthrYn} onChange={(newValue) => {handleCheckBoxChange(index,newValue)}}/></div>
                                                        <div className="tb w-2"><CustomCheckboxRenderer key={`${index}-regAthr`} name={"regAthrYn"} value={data.regAthrYn} onChange={(newValue) => {handleCheckBoxChange(index,newValue)}}/></div>
                                                        <div className="tb w-2"><CustomCheckboxRenderer key={`${index}-updtAthr`} name={"updtAthrYn"} value={data.updtAthrYn} onChange={(newValue) => {handleCheckBoxChange(index,newValue)}}/></div>
                                                        <div className="tb w-2"><CustomCheckboxRenderer key={`${index}-delAthr`} name={"delAthrYn"} value={data.delAthrYn} onChange={(newValue) => {handleCheckBoxChange(index,newValue)}}/></div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        defaultAthrPrgmInfo.map((data,index) => {
                                            return (
                                                <div className="tbody-wrap" key={index}>
                                                    <div className="tbody">
                                                        <div className="tb w-3">{data.prgmNm}</div>
                                                        <div className="tb w-2"><CustomCheckboxRenderer key={`${index}-iqryAthrDefault`} name={"iqryAthrYn"} value={data.iqryAthrYn} onChange={(newValue) => {handleCheckBoxChange(index,newValue)}}/></div>
                                                        <div className="tb w-2"><CustomCheckboxRenderer key={`${index}-regAthrDefault`} name={"regAthrYn"} value={data.regAthrYn} onChange={(newValue) => {handleCheckBoxChange(index,newValue)}}/></div>
                                                        <div className="tb w-2"><CustomCheckboxRenderer key={`${index}-updtAthrDefault`} name={"updtAthrYn"} value={data.updtAthrYn} onChange={(newValue) => {handleCheckBoxChange(index,newValue)}}/></div>
                                                        <div className="tb w-2"><CustomCheckboxRenderer key={`${index}-delAthrDefault`} name={"delAthrYn"} value={data.delAthrYn} onChange={(newValue) => {handleCheckBoxChange(index,newValue)}}/></div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    
                                </div>
                            </TableWrap>
                        </div>
                    </DashboardBox>
                </div>

            </DashboardWrap>
        </div>
    )
    
}