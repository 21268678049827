import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import AlertStyle from './Alert.module.css';
import { Button, CloseButton, Modal } from "react-bootstrap";
import i18n from "locales/i18n.ts";
import 'asset/css/modal.css'

export function confirm(titleLabel, message, confirmAlertCallback) {
    confirmAlert({
        customUI: ({ onClose }) => (
            <Modal show onHide={onClose} centered className={AlertStyle.modalContent} backdropClassName="alertBackdrop">
                <Modal.Header className={AlertStyle.modalHeader}>
                    <CloseButton onClick={onClose} className={AlertStyle.modalBtn}/>
                </Modal.Header>
                <Modal.Body className={AlertStyle.modalBody}>
                    <h3 className={AlertStyle.modalH3}>{message}</h3>
                </Modal.Body>
                <Modal.Footer className={AlertStyle.modalConfirmFooter}>
                    <Button
                        variant={null}
                        className={`btn btn-danger ${AlertStyle.modalRound}`}
                        onClick={() => {
                            confirmAlertCallback();
                            onClose();
                        }}
                    >
                        {i18n.t("common.okLable")}
                    </Button>
                    <Button
                        variant={null}
                        className={`btn btn-outline-light ${AlertStyle.modalCancel}`}
                        onClick={() => {onClose()}}
                    >
                        {i18n.t("common.cancelLable")}
                    </Button>
                </Modal.Footer>
            </Modal>
          
            // return (
            //     <div className='custom-ui'>              
            //     <p>{titleLabel}</p>
            //     <button
            //         onClick={() => {
            //             confirmAlertCallback();
            //             onClose();
            //         }}
            //     >
            //         예
            //     </button>
            //     <button onClick={onClose}>아니오</button>

            //     </div>
            // );
        )
        // title: titleLabel,
        // message: message,
        // buttons: [
        //     {
        //         label: '예',
        //         onClick: () => confirmAlertCallback()
        //     },
        //     {
        //         label: '아니오',
        //         //onClick: () => {}
        //     }
        // ]
    });

}

export function confirmCancel(titleLabel, message, confirmAlertCallback, confirmCancelCallBack) {
    // console.log(titleLabel)
    // console.log(message)
    confirmAlert({
        // customUI: ({ onClose }) => {
        //   return (
        //     <div className='custom-ui'>              
        //       <p>{title}</p>
        //       <button
        //         onClick={() => {
        //             fnCallBack();
        //             onClose();
        //         }}
        //       >
        //         예
        //       </button>
        //       <button onClick={onClose}>아니오</button>

        //     </div>
        //   );
        // }
        title: titleLabel,
        message: message,
        buttons: [
            {
                label: '예',
                onClick: () => confirmAlertCallback()
            },
            {
                label: '아니오',
                onClick: () => {

                    confirmCancelCallBack();


                }
            }
        ]
    });

}

/* web alert */
export function webAlert(titleLabel,message,alertCallback){
    confirmAlert({
        title: titleLabel,
        message: message,
        buttons: [
            {
            label: '확인',
            onClick: () => alertCallback()
            }
        ]
      });

}

/* mobile alert */
export function alert(titleLabel, message, alertCallback) {
    confirmAlert({
        customUI: ({ onClose }) => (
            <Modal show onHide={() => {alertCallback(); onClose();}} centered className={AlertStyle.modalContent} backdropClassName="alertBackdrop">
                <Modal.Header className={AlertStyle.modalHeader}>
                    <CloseButton 
                        onClick={() => {
                            alertCallback(); 
                            onClose();
                        }} 
                        className={AlertStyle.modalBtn}
                    />
                </Modal.Header>
                <Modal.Body className={AlertStyle.modalBody}>
                    <h3 className={AlertStyle.modalH3}>{message}</h3>
                </Modal.Body>
                <Modal.Footer className={AlertStyle.modalFooter}>
                    <Button
                        className={`btn btn-danger ${AlertStyle.modalRound}`}
                        onClick={() => {
                            alertCallback();
                            onClose();
                        }}
                    >
                        {i18n.t("common.okLable")}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    });
}
/* modal confirm */
export function modalConfirm(titleLabel, message, confirmAlertCallback) {
    confirmAlert({
        customUI: ({ onClose }) => (
            <Modal show onHide={() => {onClose()}} centered className={AlertStyle.modalContent} backdropClassName="alertBackdrop">
                <Modal.Header className={AlertStyle.modalHeader}>
                    <CloseButton onClick={onClose} className={AlertStyle.modalBtn}/>
                </Modal.Header>
                <Modal.Body className={AlertStyle.modalBody}>
                    <h3 className={AlertStyle.modalH3}>{message}</h3>
                </Modal.Body>
                <Modal.Footer className={AlertStyle.modalConfirmFooter}>
                    <Button
                        variant={null}
                        className={`btn btn-danger ${AlertStyle.modalRound}`}
                        onClick={() => {
                            onClose();
                            confirmAlertCallback();
                        }}
                    >
                        {i18n.t("common.okLable")}
                    </Button>
                    <Button
                        variant={null}
                        className={`btn btn-outline-light ${AlertStyle.modalCancel}`}
                        onClick={onClose}
                    >
                        {i18n.t("common.cancelLable")}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    });
}