import React, { useState,useEffect } from 'react';

const CustomCheckboxRenderer = ({ colDef, name, value, onChange, data, api }) => {
  const [checked, setChecked] = useState(value === 'Y');
  const [newValue, setNewValue] = useState(value);
  
  // const onCheckboxChange = () => {
  //   const newValue = !checked;
  //   setChecked(newValue);

  //   // 선택된 체크박스 여부에 따라 값을 'Y' 또는 'N'으로 설정
  //   data[colDef.field] = newValue ? 'Y' : 'N';

  //   api.applyTransaction({ update: [data] });
  // };

  // 노드 검색
  // api.forEachNode((node) => {
  //   const rowData = node.data;
  //   console.log(rowData);
  // })
  
  useEffect(() => {
     //setChecked(checked);
     
  },[value]);

  const onCheckboxChange = (e) => {
    const newChecked = !checked;
    setChecked(newChecked);
    onChange({newValue:newChecked ? 'Y' : 'N',name:name});
  }

  return (
    <input
      type="checkbox"
      name={name}
      checked={checked}
      value = {newValue}
      onChange={onCheckboxChange}
    />
  );
  
};

export default CustomCheckboxRenderer;