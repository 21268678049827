
import styled from "styled-components";

const CheckBoxWrap = styled.li`
    display: flex;
    align-items: center;
    &:first-child {
        ${props => props.$isMobile ? "margin-right: 38px" : "margin-right: 69px"};
    }
    input[type="checkbox"] {
        width: 15px;
        height: 15px;
        margin-right: 10px;
        &:checked {
            accent-color: ${({ theme }) => theme.colors.red};
            background-color: ${({ theme }) => theme.colors.red};
            border-color: ${({ theme }) => theme.colors.red};
        }
    }
    label {
        ${props => props.$isMobile ? "font-size: 14px" : "font-size: 16px"};
    }
`;

function MobileCheckBox({ id, label, checked, disabled, onChange, onClick, className, isMobile }) {

    return (
        <CheckBoxWrap $isMobile={isMobile}>
            <input type="checkbox"
                id={id}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                onClick={onClick}
                className="form-check-input"
            />
            <label className="form-check-label" htmlFor={id}>{label}</label>
        </CheckBoxWrap>
    )

}
export default MobileCheckBox;