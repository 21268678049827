import { format } from "date-fns";
import moment from 'moment';

export const formatDateUtils = {
  addDays: (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  },

  subDays: (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  },

  addMonths: (date, months) => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  },

  subMonths: (date, months) => {
    const result = new Date(date);
    result.setMonth(result.getMonth() - months);
    return result;
  },

  startOfMonth: (date) => {
    const result = new Date(date);
    result.setDate(1);
    result.setHours(0, 0, 0, 0);
    return result;
  },

  endOfMonth: (date) => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + 1);
    result.setDate(1);
    result.setDate(result.getDate() - 1);
    result.setHours(23, 59, 59, 999);
    return result;
  },
};

export const getFormattedDate = (date, givenFormat = 'yyyy-MM-dd') => {
  return format(new Date(date), givenFormat)
}

export const getDateRangeForButton = (buttonName, t) => {
  const now = moment();

  let formatStartDate;
  let formatEndDate;

  if (buttonName === t("saleHistPage.today")) {
    formatStartDate = now.clone().startOf('day').format("YYYY-MM-DD");
    formatEndDate = now.clone().endOf('day').format("YYYY-MM-DD");
  }

  if (buttonName === t("saleHistPage.yesterday")) {
    const yesterday = now.clone().subtract(1, 'day');
    formatStartDate = yesterday.startOf('day').format('YYYY-MM-DD');
    formatEndDate = yesterday.endOf('day').format('YYYY-MM-DD');
  }

  if (buttonName === t("saleHistPage.thisWeek")) {
    const startOfWeek = now.clone().startOf('week').startOf('day');
    const endOfWeek = now.clone().endOf('week').startOf('day');
    formatStartDate = startOfWeek.format('YYYY-MM-DD');
    formatEndDate = endOfWeek.format('YYYY-MM-DD');
  }

  if (buttonName === t("saleHistPage.lastWeek")) {
    const startOfLastWeek = now.clone().subtract(1, 'week').startOf('week').startOf('day');
    const endOfLastWeek = now.clone().subtract(1, 'week').endOf('week').startOf('day');
    formatStartDate = startOfLastWeek.format('YYYY-MM-DD');
    formatEndDate = endOfLastWeek.format('YYYY-MM-DD');
  }

  if (buttonName === t("saleHistPage.lastWeekend")) { // 토~일
    const endOfLastWeek = now.clone().subtract(1, 'week').endOf('week').startOf('day');
    const startOfLastWeekend = endOfLastWeek.clone().subtract(1, 'day').startOf('day').add(1, 'day');
    const endOfLastWeekend = endOfLastWeek.clone().startOf('day').add(1, 'day');

    formatStartDate = startOfLastWeekend.format('YYYY-MM-DD');
    formatEndDate = endOfLastWeekend.format('YYYY-MM-DD');
  }

  if (buttonName === t("saleHistPage.thisMonth")) {
    const startOfMonth = now.clone().startOf('month').startOf('day');
    const endOfMonth = now.clone().endOf('month').startOf('day').subtract(1, 'day');
    formatStartDate = startOfMonth.format('YYYY-MM-DD');
    formatEndDate = endOfMonth.add(1, 'day').format('YYYY-MM-DD');
  }

  if (buttonName === t("saleHistPage.lastMonth")) {
    const startOfLastMonth = now.clone().subtract(1, 'month').startOf('month').startOf('day');
    const endOfLastMonth = now.clone().subtract(1, 'month').endOf('month').startOf('day');
    formatStartDate = startOfLastMonth.format('YYYY-MM-DD');
    formatEndDate = endOfLastMonth.format('YYYY-MM-DD');
  }

  return { startDate: formatStartDate, endDate: formatEndDate };
};
