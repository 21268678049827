import axios from 'axios';
import { alert } from 'components/web/cmmn/CmmnConfirm';
import i18n from "locales/i18n.ts";

export default async function DeleteToken(userSeq, isMobile) {

    const baseApiUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchData = async () => {
        try{
            await axios.post(`${baseApiUrl}/web/api/auth/logout?userSeq=${userSeq}`)
            .then((response)=>{
                if(response.status === 200) {
                    console.log("token 삭제 확인");

                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("userSqno");
                    localStorage.removeItem("userNm");
                    localStorage.removeItem("brchId");
                    localStorage.removeItem("brchClsfCd");
                    localStorage.removeItem("changeBrchId");
                    
                    alert("", i18n.t("alert.LogoutMsg"), () => {
                        window.location.replace(isMobile ? "/mobile/login" : "/login");
                    });

                }else{
                    console.log("token 삭제 실패");
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    fetchData();

    return (
        <>
        </>
    );
}