'use client'

import styled from "styled-components";
import titleLogo from "asset/photomag_web/img/icon/filled/developer-board.svg"
import deviceLogo from "asset/mobile_web/img/device.png"
import cameraImg from "asset/mobile_web/img/icon/filled/icon-btn/camera-alt.svg"
import coinImg from "asset/mobile_web/img/icon/filled/icon-btn/style.svg"
import prgmImg from "asset/mobile_web/img/icon/filled/icon-btn/blur-on.svg"
import connectFailImg from "asset/photomag_web/img/연결실패.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faSquare } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { callApi } from "util/HttpService";
import { alert } from "components/web/cmmn/CmmnConfirm";
import moment from "moment";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import CmmnRestartIcon from "components/mobile/cmmn/CmmnRestartIcon";
import InputBox from "components/mobile/UI/InputBox";
import CmmnRadio from "components/mobile/cmmn/CmmnRadio";
import CmmnCrdHistModal from "components/web/cmmn/CmmnCrdHistModal";
import { useTranslation } from "react-i18next";
import { useBrch } from "components/web/auth/BrchProvider";
import CmmnSysCnfModal from "components/web/cmmn/CmmnSysCnfModal";


const DashBoardWrap = styled.div`
	display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
	.dashboard-box {
		background-color: #fff;
		border-radius: 16px;
		width: 100%;
		margin-bottom: 20px;
		box-shadow: 0 0 10px rgba(0,0,0,.1);
	}
    .round-filled-small-btn:hover {
        color: var(--bs-btn-hover-color) !important;
        background-color: var(--bs-btn-hover-bg) !important;
        border-color: var(--bs-btn-hover-border-color) !important;
    }
`;
const MonitoringBox = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    .device-monitoring {
        width: 30%;
        .device-img {
           height: 280px;
        }
    }
    .device-text-info {
        width: 68.5%;
    }
`;
const DeviceMonitoring = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 !important;
    .device-img {
        width: 100% !important;
        height: 150px;
        position: relative;
        // background-image: url(${deviceLogo});
        background-position: center;
        background-size: cover;
        border-radius: 16px;
        padding: 20px;
        border: 1px solid #EEEEEE;
        .state-box {
            padding: 5px 10px;
            color: #fff;
            font-size: 14px;
            border-radius: 100px;
            width: fit-content;
        }
        .use {
            background-color: #00d45e;
        }
        .wating {
            background-color: #1f93ed;
        }
        .connect-fail {
            background-color: ${({ theme }) => theme.colors.gray8};
        }
    }
`;
const DeviceTextInfo = styled.div`
    width: 68.5%;
    border-bottom: 1px solid #eeeeee;
    .device-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 5px;
        border-bottom: 2px solid #EF2929;
        font-size: 16px;
        color: #3b3b3b;
        font-weight: bold;
        margin-bottom: 10px;
        // ${props => props.$brchClsfCd === "KSC" ? "margin-bottom: 10px" : "margin-bottom: 20px"};
        h2 {
            // ${props => props.$brchClsfCd === "KSC" ? "margin-bottom: 0px" : "margin-bottom: 5px"};
            // margin-bottom: 6px;
        }
    }
    .device-sub-title {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        svg {
            font-size: 14px;
            color: #EF2929;
            margin-right: 10px;
        }
    }
    .info-text-list-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        ul {
            width: 49%;
            li {
                display: flex;
                align-items: center;
                padding: 15px 0;
                height: 50.6px; // 설정버튼 없을 땐 48px;
                border-bottom: 1px solid rgba(0,0,0,.1);
                &:last-child {
                    border-bottom: none;
                }
                h3 {
                    width: 50%;
                    font-size: 16px;
                    color: #3b3b3b;
                    font-weight: 500;
                }
                h5 {
                    width: 50%;
                    font-size: 16px;
                    color: #3b3b3b;
                    font-weight: 400;
                }
            }
        }
    }
    .w-60 {
        width: 60px;
    }
`;
const PointWrap = styled.div`
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
        width: fit-content !important;
    }
`;
const PointInfoBox = styled.div`
    display: flex;
    align-items: center;
    .btn {
        padding: 3px 8px;
        font-size: 12px;
        min-width: fit-content;
        &:first-child {
            margin-right: 5px;
        }
    }
`;
const MtrBoxContentWrap = styled.div`
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .monitoring-box-content {
        width: 49%;
        .dashboard-box {
            margin-top: 20px;
            padding: 20px;
        }
        .device-sub-title {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #3b3b3b;
            font-weight: 500;
            svg {
                font-size: 14px;
                color: #EF2929;
                margin-right: 10px;
            }
        }
    }
`;
const RadioPlusMinusWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
`;
const PlusMinusBtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    .circle-icon-btn {
        padding: 10px;
        border-radius: 100px;
        background-color: #fff;
        border: 1.5px solid ${({ theme }) => theme.colors.red};
        transition: all .3s ease-in-out;
        &:hover {
            background-color: ${({ theme }) => theme.colors.red};
        }
        &:hover svg {
            color: #fff;
        }
        svg {
            font-size: 18px;
            color: ${({ theme }) => theme.colors.red};
        }
    }
    .plus-minus-input {
        margin: 0 10px;
        input {
            text-align: center;
            padding: 10px;
        }
        .input_box:last-of-type {
            margin: 0 !important;
        }
    }
`;
const SmallBtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .btn {
        font-size: 14px;
        padding: 7px 20px;
        // width: 49%;
        width: 100%;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
`;

const POLLING_INTERVAL = 10000;

export default function DvcMgt() {

    const { t } = useTranslation();
    library.add(faSquare, faPlus, faMinus);
    const { brchId } = useBrch();
    const brchClsfCd = localStorage.getItem("brchClsfCd");
    const defaultSearchData = {
        brchId: brchId,
        issuPnt: 500, //발급 포인트
        issuQty: 0, //발급 수량
        frcgPtgyClsfCd: "", //무료촬영구분코드
    }
    const defaultRestartData = {
        eqmtId: "",
        controlDeviceClsfCd: "",
    }
    const pointFreeArr = [
        { label: t("deviceMgtPage.pnt"), value: "PNT" },
        { label: t("deviceMgtPage.free"), value: "QTY" },
    ];
    const controlArr = [
        { label: t("deviceMgtPage.camera"), value: "CAMERA", img: cameraImg },
        { label: t("deviceMgtPage.paymentMachine"), value: "COIND", img: coinImg },
        { label: t("deviceMgtPage.program"), value: "PRGM", img: prgmImg },
    ];
    const [dvcList, setDvcList] = useState([]);
    const [searchData, setSearchData] = useState(defaultSearchData);
    const [restartData, setRestartData] = useState(defaultRestartData);
    const [applyingDevices, setApplyingDevices] = useState({});

    const [modalTitle, setModalTitle] = useState("");
    const [frcgPtgyClsfCd, setFrcgPtgyClsfCd] = useState("");

    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    const [sysModalOpen, setSysModalOpen] = useState(false);
    const [sysModalData, setSysModalData] = useState(null);

    const openModal = (data, frcg) => {
        setModalOpen(true);
        setModalData(data);
        setFrcgPtgyClsfCd(frcg);
    }
    const openSysModal = (data) => {
        setSysModalOpen(true);
        setSysModalData(data);
    }
    const getDvcList = () => {
        const now = moment();
        callApi("GET", { brchId: brchId }, "/web/api/dvc-mgt/edm/list")
            .then(res => {
                console.log(now.format("HH:mm:ss") + " 장치 재랜더링");
                setDvcList(prevList => {
                    const updatedList = res.map(dvc => {
                        const chngDttm = moment(dvc.chngDttm);
                        const isConnectionFailed = now.diff(chngDttm, 'seconds') > 20;
                        // console.log("eqmtId:", dvc.eqmtId, "isConnectionFail:", isConnectionFailed);

                        const prevDvc = prevList.find(prevDvc => prevDvc.eqmtId === dvc.eqmtId) || {};
                        // applyingDevices === true면 비활성화 false면 활성화
                        const shouldDisableApplying =
                            dvc.prgmStatCd !== "WAITING" ||
                            dvc.slblPnt > 0 ||
                            dvc.slblFrcgPtgyQty > 0 ||
                            isConnectionFailed;

                        setApplyingDevices(prev => ({ ...prev, [dvc.eqmtId]: shouldDisableApplying }));

                        return {
                            ...dvc,
                            pntSelected: prevDvc.pntSelected || 'PNT',
                            pnt: prevDvc.pnt || 500,
                            qty: prevDvc.qty || 2,
                            isConnectionFailed: isConnectionFailed,
                        };
                    });
                    // console.log(updatedList);
                    return updatedList;
                });
            })
            .catch(error => {
                console.log(error);
            });
    };
    useEffect(() => {
        // console.log("장치관리 갱신");
        getDvcList();
        const interval = setInterval(getDvcList, POLLING_INTERVAL);
        return () => clearInterval(interval);
    }, []);

    const handlePntDataChange = (e, idx) => {
        const { value } = e.target;
        setDvcList(prevList => {
            const newList = [...prevList];
            newList[idx] = {
                ...newList[idx],
                pntSelected: value,
                pnt: value === "PNT" ? 500 : newList[idx].pnt,
                qty: value === "PNT" ? 2 : newList[idx].qty,
            };
            return newList;
        });
        setSearchData((prevData) => ({
            ...prevData,
            issuPnt: value === "PNT" ? 500 : 0,
            issuQty: value === "PNT" ? 0 : 2
        }));
    };

    const handleIncrease = (idx) => {
        setDvcList(prevList => {
            return prevList.map((item, index) => {
                if (index !== idx) return item;
                const updateItme = {
                    ...item,
                    pnt: item.pntSelected === "PNT" ? item.pnt + 100 : item.pnt,
                    qty: item.pntSelected === "PNT" ? item.qty : Math.min(item.qty + 2, 10)
                }
                setSearchData((prevData) => ({
                    ...prevData,
                    issuPnt: updateItme.pntSelected === "PNT" ? updateItme.pnt : prevData.issuPnt,
                    issuQty: updateItme.pntSelected === "PNT" ? prevData.issuQty : updateItme.qty
                }));
                return updateItme;
            });
        });
    };

    const handleDecrease = (idx) => {
        setDvcList(prevList => {
            return prevList.map((item, index) => {
                if (index !== idx) return item;
                const updateItme = {
                    ...item,
                    pnt: item.pntSelected === "PNT" ? Math.max(item.pnt - 100, 100) : item.pnt,
                    qty: item.pntSelected === "PNT" ? item.qty : Math.max(item.qty - 2, 2)
                }
                setSearchData((prevData) => ({
                    ...prevData,
                    issuPnt: updateItme.pntSelected === "PNT" ? updateItme.pnt : prevData.issuPnt,
                    issuQty: updateItme.pntSelected === "PNT" ? prevData.issuQty : updateItme.qty
                }));
                return updateItme;
            })
        })
    };

    const handleCmmnBtnClick = (btnNm, dvc, controlDeviceClsfCd) => {
        const searchParam = { ...searchData, eqmtNo: dvc.eqmtNo, frcgPtgyClsfCd: dvc.pntSelected };
        const restartParam = { ...restartData, eqmtNo: dvc.eqmtNo, controlDeviceClsfCd: controlDeviceClsfCd };

        // if (applyingDevices[dvc.eqmtId]) {
        //     alert("", "아직 실행중입니다.", () => { return; });
        // }
        // setApplyingDevices(prev => ({ ...prev, [dvc.eqmtId]: true }));

        switch (btnNm) {
            case "cmmnInsertBtn":
                // setApplyingDevices(prev => ({ ...prev, [dvc.eqmtId]: true }));
                if (searchParam.frcgPtgyClsfCd === "PNT") {
                    console.log(searchParam);
                    callApi("POST", searchParam, "/web/api/dvc-mgt/edm/pnt-update")
                        .then(res => {
                            alert("", t("alert.pntInsert"), () => { getDvcList() });
                        })
                        .catch(error => {
                            alert("", t("alert.pntInsertError"), () => { });
                        })
                } else {
                    callApi("POST", searchParam, "/web/api/dvc-mgt/edm/pnt-update")
                        .then(res => {
                            alert("", t("alert.qtyInsert"), () => { getDvcList() });
                        })
                        .catch(error => {
                            alert("", t("alert.qtyInsertError"), () => { });
                        })
                }
                break;
            case "cmmnDeleteBtn":
                if (searchParam.frcgPtgyClsfCd === "PNT") {
                    callApi("POST", searchParam, "/web/api/dvc-mgt/edm/pnt-delete")
                        .then(res => {
                            alert("", t("alert.pntDelete"), () => { getDvcList() });
                        })
                        .catch(error => {
                            alert("", t("alert.pntDeleteError"), () => { });
                        })
                } else {
                    callApi("POST", searchParam, "/web/api/dvc-mgt/edm/pnt-delete")
                        .then(res => {
                            alert("", t("alert.qtyDelete"), () => { getDvcList() });
                        })
                        .catch(error => {
                            alert("", t("alert.qtyDeleteError"), () => { });
                        })
                }
                break;
            case "cmmnRestartBtn":
                const eqmt = `${controlDeviceClsfCd}`;
                console.log(eqmt);
                let eqmtNm;
                if (eqmt === "PRGM") {
                    eqmtNm = t("deviceMgtPage.restartProgram");
                } else if (eqmt === "COIND") {
                    eqmtNm = t("deviceMgtPage.restartPaymentDvc");
                } else if (eqmt === "CAMERA") {
                    eqmtNm = t("deviceMgtPage.restartCamera");
                }
                callApi("GET", restartParam, "/web/api/mqtt/send/control-device-restart")
                    .then(res => {
                        alert("", `${eqmtNm}`, () => { });
                    })
                    .catch(error => {
                        alert("", `${t("alert.dvcRestartError")}`, () => { });
                    })
                break;
            default: break;
        }
    };

    // useEffect(() => {
    //     console.log(applyingDevices);
    // }, [applyingDevices])

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title">
                    <div className="alignset">
                        <img src={titleLogo} alt={t("deviceMgtPage.dvcMgt")} />
                        <h2>{t("deviceMgtPage.dvcMgt")}</h2>
                    </div>
                    <p>Home {">"} {t("deviceMgtPage.dvcMgt")}</p>
                </div>
            </div>
            <DashBoardWrap>
                {dvcList.map((dvc, idx) => (
                    <div className="dashboard-box" key={idx}>
                        <MonitoringBox>
                            <DeviceMonitoring className="device-monitoring">
                                {!dvc.isConnectionFailed ?
                                    <div className="device-img" style={{ backgroundImage: `url(${dvc.prgmCptrImgCn})` }}>
                                        <div className={`state-box ${dvc.prgmStatCd === "INUSE" ? "use" : "wating"}`}>
                                            {dvc.prgmStatCd === "INUSE" ? t("deviceMgtPage.inuse") : t("deviceMgtPage.waiting")}
                                        </div>
                                    </div>
                                    :
                                    <div className="device-img" style={{ backgroundImage: `url(${connectFailImg})` }}>
                                        <div className={"state-box connect-fail"} >
                                            {dvc.isConnectionFailed && t("deviceMgtPage.connectFailed")}
                                        </div>
                                    </div>
                                }
                            </DeviceMonitoring>
                            <DeviceTextInfo className="device-text-info" $brchClsfCd={brchClsfCd}>
                                <div className="device-title">
                                    <h2>{dvc.eqmtNm}</h2>
                                    <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn w-60"
                                        value={t("deviceMgtPage.config")}
                                        onClick={() => { openSysModal(dvc) }}
                                    />
                                </div>
                                <div>
                                    <h3 className="device-sub-title">
                                        <FontAwesomeIcon icon={faSquare} />
                                        {t("deviceMgtPage.dvcInfo")}
                                    </h3>
                                    <div className="info-text-list-wrap">
                                        <ul>
                                            <li>
                                                <h3>{t("deviceMgtPage.prgmStat")}</h3>
                                                {!dvc.isConnectionFailed ?
                                                    <h5>{dvc.prgmStatCd === "INUSE" ? t("deviceMgtPage.inuse") : t("deviceMgtPage.waiting")}</h5>
                                                    :
                                                    <h5>{dvc.isConnectionFailed && t("deviceMgtPage.connectFailed")}</h5>
                                                }
                                            </li>
                                            <li>
                                                <h3>{t("deviceMgtPage.prntStat")}</h3>
                                                <h5>{(dvc.prntStatMsgCn == null || dvc.prntStatMsgCn == undefined) ? "-" : dvc.prntStatMsgCn}</h5>
                                            </li>
                                            <li>
                                                <h3>{t("deviceMgtPage.remPapr")}</h3>
                                                <h5>{(dvc.remPaprQty == null || dvc.remPaprQty == undefined) ? '0' : dvc.remPaprQty.toLocaleString()}{t("deviceMgtPage.sheet")}</h5>
                                            </li>
                                            <li>
                                                <h3>{t("deviceMgtPage.pntAndFrcgPtgy")}</h3>
                                                <h5>
                                                    {(dvc.slblPnt == null || dvc.slblPnt == undefined) ? '0' : dvc.slblPnt.toLocaleString()}
                                                    P/
                                                    {(dvc.slblFrcgPtgyQty == null || dvc.slblFrcgPtgyQty == undefined) ? '0' : dvc.slblFrcgPtgyQty.toLocaleString()}{t("deviceMgtPage.page")}
                                                </h5>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <h3>{t("deviceMgtPage.slblPnt")}</h3>
                                                <PointWrap>
                                                    <h5>{(dvc.slblPnt == null || dvc.slblPnt == undefined) ? '0' : dvc.slblPnt.toLocaleString()}P</h5>
                                                    {/*<PointInfoBox>
                                                        <ButtonUtil className="btn round-solid-small-btn btn-outline-secondary"
                                                            value={t("deviceMgtPage.issuHistory")}
                                                            onClick={() => { openModal(dvc, "PNT"); setModalTitle("포인트 발급 내역"); }}
                                                        />
                                                        <ButtonUtil className="btn round-solid-small-btn btn-outline-secondary"
                                                            value={t("deviceMgtPage.useHistory")}
                                                            onClick={() => { openModal(dvc); setModalTitle("포인트 사용 내역"); }}
                                                        />
                                                    </PointInfoBox>*/}
                                                </PointWrap>
                                            </li>
                                            <li>
                                                <h3>{t("deviceMgtPage.slblFrcgPtgy")}</h3>
                                                <PointWrap>
                                                    <h5>{(dvc.slblFrcgPtgyQty == null || dvc.slblFrcgPtgyQty == undefined) ? '0' : dvc.slblFrcgPtgyQty.toLocaleString()}{t("deviceMgtPage.page")}</h5>
                                                    {/*<PointInfoBox>
                                                        <ButtonUtil className="btn round-solid-small-btn btn-outline-secondary"
                                                            value={t("deviceMgtPage.issuHistory")}
                                                            onClick={() => { openModal(dvc, "QTY"); setModalTitle("무료 발급 내역"); }}
                                                        />
                                                        <ButtonUtil className="btn round-solid-small-btn btn-outline-secondary"
                                                            value={t("deviceMgtPage.useHistory")}
                                                            onClick={() => { openModal(dvc); setModalTitle("무료 사용 내역"); }}
                                                        />
                                                    </PointInfoBox>*/}
                                                </PointWrap>
                                            </li>
                                            <li>
                                                <h3>{t("deviceMgtPage.rcUseAmt")}</h3>
                                                <h5>¥ {(dvc.rcUseAmt == null || dvc.rcUseAmt == undefined) ? 0 : dvc.rcUseAmt}</h5>
                                            </li>
                                            <li>
                                                <h3>{t("deviceMgtPage.rcUseDttm")}</h3>
                                                <h5>{(dvc.convRcUseDttm == null || dvc.convRcUseDttm == undefined) ? "-" : dvc.convRcUseDttm}</h5>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </DeviceTextInfo>
                        </MonitoringBox>
                        <MtrBoxContentWrap>
                            <div className="monitoring-box-content">
                                <h3 className="device-sub-title">
                                    <FontAwesomeIcon icon={faSquare} />
                                    {t("deviceMgtPage.control")}
                                </h3>
                                <div className="dashboard-box">
                                    <CmmnRestartIcon
                                        dataArray={controlArr}
                                        handleClick={(controlDeviceClsfCd) => handleCmmnBtnClick("cmmnRestartBtn", dvc, controlDeviceClsfCd)}
                                    />
                                </div>
                            </div>
                            <div className="monitoring-box-content">
                                <h3 className="device-sub-title">
                                    <FontAwesomeIcon icon={faSquare} />
                                    {t("deviceMgtPage.pntAndFrcg")}
                                </h3>
                                <div className="dashboard-box">
                                    <RadioPlusMinusWrap>
                                        <CmmnRadio
                                            id={`frcgPtgyClsfCd${idx}`}
                                            dataArray={pointFreeArr}
                                            defaultVal={dvc.pntSelected}
                                            tagName={`frcgPtgyClsfCd${idx}`}
                                            onSelectChange={(e) => { handlePntDataChange(e, idx) }}
                                        />
                                        <PlusMinusBtnWrap>
                                            <ButtonUtil className="circle-icon-btn"
                                                onClick={() => { handleDecrease(idx) }}
                                            >
                                                <FontAwesomeIcon icon={faMinus} />
                                            </ButtonUtil>
                                            <div className="plus-minus-input">
                                                <InputBox
                                                    type="text"
                                                    id={`input${idx}`}
                                                    name={`input${idx}`}
                                                    className="input_box"
                                                    value={dvc.pntSelected === "PNT" ? `${dvc.pnt}P` : `${dvc.qty}${t("deviceMgtPage.page")}`}
                                                    disabled={true}
                                                />
                                            </div>
                                            <ButtonUtil className="circle-icon-btn"
                                                onClick={() => { handleIncrease(idx) }}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </ButtonUtil>
                                        </PlusMinusBtnWrap>
                                    </RadioPlusMinusWrap>
                                    <SmallBtnWrap>
                                        <ButtonUtil
                                            className="btn btn-danger round-filled-btn"
                                            value={t("deviceMgtPage.apply")}
                                            onClick={() => { handleCmmnBtnClick("cmmnInsertBtn", dvc) }}
                                            disabled={applyingDevices[dvc.eqmtId]}
                                        />
                                        {/* <ButtonUtil
                                            className="btn btn-light round-filled-gray-btn bottom-modal-close-btn"
                                            value={t("deviceMgtPage.clear")}
                                            onClick={() => { handleCmmnBtnClick("cmmnDeleteBtn", dvc) }}
                                        /> */}
                                    </SmallBtnWrap>
                                </div>
                            </div>
                        </MtrBoxContentWrap>
                    </div>
                ))}
                {/* <CmmnCrdHistModal
                    show={modalOpen}
                    data={modalData}
                    onHide={() => setModalOpen(false)}
                    title={modalTitle}
                    frcgPtgyClsfCd={frcgPtgyClsfCd}
                /> */}
                <CmmnSysCnfModal
                    show={sysModalOpen}
                    data={sysModalData}
                    onHide={() => setSysModalOpen(false)}
                    brchClsfCd={brchClsfCd}
                    t={t}
                />
            </DashBoardWrap >
        </div >
    )
}