import styled from "styled-components";
import { CloseButton, Modal } from "react-bootstrap";

const ModalStyle = styled(Modal)`
    .modal-header {
        border-bottom: none;
        border-bottom: 1px solid rgba(0,0,0,.1);
        h2 {
            font-size: 18px;
            color: #3b3b3b;
            font-weight: 500;
        }
    }
    .modal-body {
        padding: 30px 0;
        h3 {
            font-size: 16px;
            color: #3b3b3b;
            text-align: center;
            line-height: 150%;
        }
        .pr-20{
            padding-right: 20px;
        }
        .pl-20{
            padding-left: 20px;
        }
        .pb-0{
            padding-bottom: 0 !important;
        }
    }
`;

function ModalPage({ children, show, onHide, title }) {

    return (
        <ModalStyle show={show} onHide={onHide} centered>
            <Modal.Header>
                <h2>{title}</h2>
                <CloseButton onClick={onHide} />
            </Modal.Header>
            <Modal.Body>
                <div className="pr-20 pl-20">
                    {children}
                </div>
            </Modal.Body>
        </ModalStyle>
    );
}
export default ModalPage;