import React from 'react';
import Login from './pages/web/login/Login';
import { Routes, Route, Navigate } from 'react-router-dom';
import EqmtPtgyHst from './pages/web/sal-hist/sal-hist';
import PrivateRoute from 'components/web/auth/PrivateRoute';
import MobileMainLayoutComponents from 'components/mobile/layout/MainLayoutComponents';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import AuthMgt from 'pages/web/sys-mgt/auth-mgt/auth-mgt';
import UserMgtDetail from 'pages/web/svc-mgt/user-mgt/user-mgt-detail';
import BrchMgt from 'pages/web/svc-mgt/brch-mgt/brch-mgt';
import BrchMgtDetail from 'pages/web/svc-mgt/brch-mgt/brch-mgt-detail';
import EqmtMgt from 'pages/web/svc-mgt/eqmt-mgt/eqmt-mgt';
import EqmtMgtDetail from 'pages/web/svc-mgt/eqmt-mgt/eqmt-mgt-detail';
import UserMgt from 'pages/web/svc-mgt/user-mgt/user-mgt';
import DshBrd from 'pages/web/dsh-brd/dsh-brd';
import DvcMgt from 'pages/web/dvc-mgt/dvc-mgt';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import MobileLogin from 'pages/mobile/login/Login';

import FrameUpload from 'pages/web/frame-upload/frame_upload';

import MobileDshBrd from "pages/mobile/dsh-brd/dsh-brd";
import MobileSalHist from 'pages/mobile/sal-hist/sal-hist';
import MobileDvcMgt from 'pages/mobile/dvc-mgt/dvc-mgt';
import MobileCpnMgt from 'pages/mobile/cpn-mgt/cpn-mgt';
import MainLayoutComponents from 'components/web/layout/MainLayoutComponents';
import { useWindowSize } from 'context/WindowSizeContext';
import SmtMgt from 'pages/web/svc-mgt/smt-mgt/smt-mgt';


function App() {

  const queryClient = new QueryClient();
  const isMobile = useWindowSize();
  // const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <ThemeProvider theme={theme}>
      {isMobile ?
        <MobileMainLayoutComponents>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/mobile/login" element={<MobileLogin />} />
              <Route path="/" element={<Navigate to="/mobile/dsh-brd/dsh-brd" />} />

            <Route element={<PrivateRoute isMobile={isMobile} />}>
              <Route path='/mobile/dsh-brd/dsh-brd' element={<MobileDshBrd />} />
              <Route path='/mobile/sal-hist/sal-hist' element={<MobileSalHist />} />
              <Route path='/mobile/dvc-mgt/dvc-mgt' element={<MobileDvcMgt />} />
              <Route path='/mobile/cpn-mgt/cpn-mgt' element={<MobileCpnMgt />} />
            </Route>
          </Routes>
          </QueryClientProvider>
        </MobileMainLayoutComponents>
        :
        <MainLayoutComponents>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Navigate to="/dsh-brd/dsh-brd" />} />

              <Route element={<PrivateRoute isMobile={isMobile} />}>
                <Route path='/dsh-brd/dsh-brd' element={<DshBrd />} />
                <Route path='/dsh-brd/m-dsh-brd' element={<MobileDshBrd />} />
                <Route path='/dvc-mgt/dvc-mgt' element={<DvcMgt />} />
                <Route path='/sal-hist/sal-hist' element={<EqmtPtgyHst />} />
                <Route path='/svc-mgt/brch-mgt' element={<BrchMgt />} />
                <Route path='/svc-mgt/brch-mgt/:brchId' element={<BrchMgtDetail />} />
                <Route path='/svc-mgt/eqmt-mgt' element={<EqmtMgt />} />
                <Route path='/svc-mgt/eqmt-mgt/:eqmtId' element={<EqmtMgtDetail />} />
                <Route path='/svc-mgt/user-mgt' element={<UserMgt />} />
                <Route path='/svc-mgt/smt-mgt' element={<SmtMgt />} />
                <Route path='/svc-mgt/user-mgt/:userSqno' element={<UserMgtDetail />} />
                <Route path='/sys-mgt/auth-mgt' element={<AuthMgt />} />
                <Route path='/svc-mgt/frame-upload' element={<FrameUpload />} />
              </Route>
            </Routes>
          </QueryClientProvider>
        </MainLayoutComponents>
      }
    </ThemeProvider>

  );
}

export default App;
