import { callApi } from "util/HttpService";

export async function GetCmmnCdList({cmmnCdId}) {
    const reqBody = {
        "cmmnCdId" : cmmnCdId
    }
    const resData = await callApi("POST",reqBody,"/web/api/cmmn/cmmn-cd-list")
                            .then((res) => {
                                
                                    // console.log("res : ",res);
                                    return res;
                                }

                            );
    return resData;
}

export async function GetBrchList({ brchId, brchClsfCd, frncId }) {
    const reqBody = {
        "brchId": brchId,
        "brchClsfCd" : brchClsfCd,
        "frncId": frncId,
    }
    const resData = await callApi("POST",reqBody,"/web/api/cmmn/brch-list")
                            .then((res) => {
                                
                                    //console.log("res : ",res);
                                    return res;
                                }

                            );
    return resData;
}

export async function GetBrchTelNoList({ brchId }) {
    const reqBody = {
        "brchId": brchId,
    }
    const resData = await callApi("POST",reqBody,"/web/api/cmmn/brch-tel-list")
                            .then((res) => {
                                
                                    //console.log("res : ",res);
                                    return res;
                                }

                            );
    return resData;
}

export async function GetAthrAllList() {
    
    
    const resData = await callApi("POST","","/web/api/cmmn/athr-list")
                            .then((res) => {
                                
                                    //console.log("res : ",res);
                                    return res;
                                }

                            );
    return resData;
}

export async function GetEqmtAllList({brchId}) {
    const reqBody = {
        "brchId" : brchId
    }
    
    const resData = await callApi("POST",reqBody,"/web/api/cmmn/eqmt-list")
                            .then((res) => {
                                
                                    //console.log("res : ",res);
                                    return res;
                                }

                            );
    return resData;
}

export async function GETHeaderBrchList({brchId, brchClsfCd}) {
    const reqBody = {
        "brchId" : brchId,
        "brchClsfCd": brchClsfCd
    }
    
    const resData = await callApi("POST",reqBody,"/web/api/cmmn/header")
                            .then((res) => {
                                
                                    //console.log("res : ",res);
                                    return res;
                                }

                            );
    return resData;
}
export async function GetSmtList() {
    
    const resData = await callApi("POST","","/web/api/cmmn/smt-list")
                            .then((res) => {
                                
                                    //console.log("res : ",res);
                                    return res;
                                }

                            );
    return resData;
}