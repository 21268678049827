import styled from "styled-components";
//import { usePathname, useRouter } from 'react/navigation';
import { useState , useEffect} from "react";
import { useTranslation } from 'react-i18next';

const Btn = styled.button`
        height: 2rem;
        line-height: 1.9rem;
        display: inline-block;
        padding: 0 1rem;
        min-width: 6.6rem;
        font-size: 1.1rem;
        font-weight: 400;
        border: 1px solid ${({ theme }) => theme.colors.border};
        background: #fff;
        cursor: pointer;
        border-radius: 0.5rem;
        outline: none;
        font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
            Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
            "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
            "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
        &:hover {
            border-color: ${({ theme }) => theme.colors.black};
        }
        &:disabled {
            cursor: default;
            background: #f9f9f9;
            border-color: #e1e1e1;
        }
        &.login,
        &.xl {
            width: 100%;
            height: 6rem;
            border-radius: 3rem;
            background: ${({ theme }) => theme.colors.primary};
            color: #fff;
            border-color: ${({ theme }) => theme.colors.primary};
            font-size: 2.4rem;
            font-weight: 500;
            &:hover {
                border-color: ${({ theme }) => theme.colors.primary};
            }
        }
        &.xl {
            font-size: 2rem;
            font-weight: 600;
            width: 24rem;
            margin: 0 auto;
        }
        &.primary {
            background: ${({ theme }) => theme.colors.primaryLight};
            border-color: ${({ theme }) => theme.colors.primaryLight};
            color: #fff;
            &:hover {
                background: #11b2ed;
            }
            &:disabled {
                cursor: default;
                background: #b5b5b5;
                border-color: #b5b5b5;
            }
        }
        &.secondary {
            background: ${({ theme }) => theme.colors.gray2};
            border-color: ${({ theme }) => theme.colors.gray2};
            color: #fff;
            &:hover {
                background: ${({ theme }) => theme.colors.gray1};
                border-color: ${({ theme }) => theme.colors.gray1};
            }
            &:disabled {
                cursor: default;
                background: #b5b5b5;
                border-color: #b5b5b5;
            }
        }
        &.file {
            font-size: 1.2rem;
            color: ${({ theme }) => theme.colors.black};
            min-width: 7.2rem;
            background: #f2f2f2;
        }
        &.btnUp {
            width: 4rem;
            min-width: 4rem;
            background: url('/assets/images/btn_down.svg') no-repeat center;
        }
        &.btnDown {
            width: 4rem;
            min-width: 4rem;
            background: url(/assets/images/btn_down.svg) no-repeat center;
            transform: rotate(180deg);
        }
        &.btnLeft {
            width: 4.4rem;
            min-width: 4.4rem;
            background: url(/assets/images/Ico_24_2px_send.svg) no-repeat center;
        }
        &.btnRight {
            width: 4.4rem;
            min-width: 4.4rem;
            background: url(/assets/images/Ico_24_2px_send.svg) no-repeat center;
            transform: rotate(180deg);
        }
        &.btnPrev {
            width: 4.4rem;
            min-width: 4.4rem;
            background: url(/assets/images/Ico_24_2px_send_double.svg) no-repeat center;
        }
        &.btnNext {
            width: 4.4rem;
            min-width: 4.4rem;
            background: url(/assets/images/Ico_24_2px_send_double.svg) no-repeat center;
            transform: rotate(180deg);
        }
        &.w80 {
            width: 8rem;
            min-width: 4rem;
        }
    `;
async function fetchData() {
}
export default function CmmnBtnContainer({showBtnArr,onCmmnBtnClick,className}){
    //console.log("showBtnArr : " ,showBtnArr);
    //const pathname = usePathname(); //화면 url 확인
    //console.log("pathname : " ,pathname);

    const {t} = useTranslation();
    
    const [iqryAthrYn, setIqryAthrYn] = useState('Y');
    const [regAthrYn, setRegAthrYn] = useState('Y');
    const [updtAthrYn, setUpdtAthrYn] = useState('Y');
    const [delAthrYn, setDelAthrYn] = useState('Y');
    // useEffect(() => {
    //     fetchData().then(data => {
    //         //console.log("Breadcrumb Session data : ",data);
    //         if(data!== null){
    //             const res = data.user.userPrgmAthrInfoList;
    //             //console.log("res : " ,res);
    //             const sessionPrgmInfo = res.filter(prgm => prgm.prgmPathAddr !=='' && pathname.includes(prgm.prgmPathAddr));
    //             //console.log("prgmInfo : ", sessionPrgmInfo);
    //             if(sessionPrgmInfo.length>0) {         
    //                 setIqryAthrYn(sessionPrgmInfo[0].iqryAthrYn);
    //                 setRegAthrYn(sessionPrgmInfo[0].regAthrYn);
    //                 setUpdtAthrYn(sessionPrgmInfo[0].updtAthrYn);
    //                 setDelAthrYn(sessionPrgmInfo[0].delAthrYn);
                    
    //             }
    
    //         }
    //     }); // Correct usage of .then

    // }, [pathname]);
    
    const handleClick =(e) => {
        const btnNm = e.target.name;
        //console.log(btnNm);
        onCmmnBtnClick(btnNm);
    };
    
    return(
        <>
            
            {/* {showBtnArr.includes("orderSave")?<Btn name="cmmnOrderSaveBtn" onClick={handleClick}>순서저장</Btn>:""} */}
            {/* {showBtnArr.includes("reset")&&regAthrYn==='Y'?<Btn type="button"  className="secondary" name="cmmnResetBtn" onClick={handleClick}>초기화</Btn>:""} */}
            {/* {showBtnArr.includes("reg")&&regAthrYn==='Y'?<Btn name="cmmnRegBtn" type="button" className="primary" onClick={handleClick}>등록</Btn>:""} */}
            
            {/* {showBtnArr.includes("updt")&&updtAthrYn==='Y'?<Btn name="cmmnUpdtBtn" type="button" className="primary" onClick={handleClick}>저장</Btn>:""} */}
            {/* {showBtnArr.includes("insert")&&regAthrYn=='Y'?<Btn name="cmmnInsertBtn" type="button" className="primary" onClick={handleClick}>저장</Btn>:""} */}
            {/* {showBtnArr.includes("del")&&delAthrYn==='Y'?<Btn name="cmmnDelBtn" className="secondary" type="button" onClick={handleClick}>삭제</Btn>:""} */}
            
            {/* {showBtnArr.includes("insert")&&regAthrYn=='Y'?<Btn name="cmmnInsertBtn" type="button" className="primary" onClick={handleClick}>환경설정</Btn>:""} */}
            {/* {showBtnArr.includes("xslDwn")&&iqryAthrYn==='Y'?<Btn name="cmmnXslDwnBtn" onClick={handleClick}>엑셀다운로드</Btn>:""} */}
            {/* {showBtnArr.includes("xslUpdt")&&regAthrYn==='Y'?<Btn name="cmmnXslUpdtBtn" onClick={handleClick}>엑셀일괄업로드</Btn>:""} */}
            {/* {showBtnArr.includes("envUpdt")&&updtAthrYn==='Y'?<Btn name="cmmnEnvUpdtBtn" onClick={handleClick}>환경설정</Btn>:""} */}
            
            {showBtnArr.includes("del")&&delAthrYn==='Y'?<button className={className} name="cmmnDelBtn" onClick={handleClick}>{t("common.delLable")}</button>:""}
            {showBtnArr.includes("reg")&&regAthrYn==='Y'?<button className={className} name="cmmnRegBtn" onClick={handleClick}>{t("common.regLable")}</button>:""}
            {showBtnArr.includes("updt")&&updtAthrYn==='Y'?<button className={className} name="cmmnUpdtBtn" onClick={handleClick}>{t("common.saveLable")}</button>:""}
        </>
        
    )
}
