import TextFields from "components/web/UI/TextFields";
import styled from "styled-components";
import ButtonUtil from "../UI/ButtonUtil";
import { Fragment, useEffect, useState } from "react";
import SelectBox from "components/web/UI/SelectBox";
import { alert, modalConfirm } from "components/web/cmmn/CmmnConfirm";
import { callApi } from "util/HttpService";

const ListInputWrap = styled.ul`
    border-top: 2px solid #EF2929;
    min-height: 100vh;
    .basic-input {
        padding: 10px;
        font-size: 16px;
        color: #3b3b3b;
        border: 1px solid #D3D3D3;
        border-radius: 4px;
        height: 35px;
    }
    &:focus {
        border-color: #FFA500 !important;
    }
    .frame-input {
        div {
            width: 50% !important;
            input {
                width: 100% !important;
            }
        }
        select {
            width: 50% !important;
        }
    }
`;
const InputTitle = styled.div`
    height: 54px;
    padding: 15px 20px;
    background-color: #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h2 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
    }
}
    .modal-title {
        margin-bottom: 10px;
        background-color: #fff;
        padding: 15px 20px 0px 0px;
        h2 {
            font-size: 17px;
            font-weight: 600;
        }
`;
const InputContent = styled.div`
    background-color: #fff;
    padding: 10px 20px 10px 20px;
    & > div {
        margin-bottom: 10px;
    }
    h3 {
        font-size: 20px;
        color: #3b3b3b;
        font-weight: 500;
        margin-right: 10px;
    }
    h2 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        width: 50%;
    }
    h5 {
        margin-left: 7px;
    }
    input[type="text"]:focus {
        border-color: #FFA500;
    }
`;
const BtnWrap = styled.div`
    width: 100%;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
        width: 32%;
    }
`;
export default function MbProgramConfig({
    t,
    brchClsfCd,
    systemMode,
    onHide,
    kiskInfo,
    data,
    getKiskInfo,
}) {

    const [timeLimitSettings, setTimeLimitSettings] = useState({});
    const [printMarginSettings, setPrintMarginSettings] = useState({});
    const [framePaymentCntSettings, setFramePaymentCntSettings] = useState([]);
    const [filteredFrameArr, setFilteredFrameArr] = useState([]);

    useEffect(() => {
        if (kiskInfo && kiskInfo.eltmLmtnStupCn && kiskInfo.prntMrgnStupCn) {
            setTimeLimitSettings(kiskInfo.eltmLmtnStupCn);
            setPrintMarginSettings(kiskInfo.prntMrgnStupCn);
        }
    }, [kiskInfo]);

    // 기본 제한 시간, 결제 대기 시간
    const basicLimitArray = Array.from({ length: 60 }, (_, i) => ({
        key: (i + 1) * 10,
        value: (i + 1) * 10,
    }));
    // 촬영 준비 시간
    const takeReadyLimitArray = Array.from({ length: 60 }, (_, i) => ({
        key: i + 1,
        value: i + 1,
    }));
    // 촬영 시간
    const takeLimitArray = Array.from({ length: 11 }, (_, i) => ({
        key: i + 1,
        value: i + 1,
    }));
    const frameTagArray = [
        { key: "HOT", value: "hot" },
        { key: "RECOMMEND", value: "recommend" },
    ];

    const handleTimeLimitChange = (e, key) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setTimeLimitSettings(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handlePringMarginChange = (e, key) => {
        const value = e.target.value;
        setPrintMarginSettings(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleFrameSettingsChange = (e, frameCd) => {
        const { name, value } = e.target;
        setFilteredFrameArr(prevState => {
            const newState = prevState.map(frame => {
                if (frame.frameCd === frameCd) {
                    return { ...frame, [name]: value };
                }
                return frame;
            });
            return newState;
        });
    };

    const handleCmmnBtnClick = async (btnNm) => {
        // timeLimitSettings, printMarginSettings, framePaymentCntSettings 각각 호출
        switch (btnNm) {
            case "cmmnInsertBtn":
                try {
                    const commonParams = { brchId: data.brchId, eqmtId: data.eqmtId, eqmtNo: data.eqmtNo, delYn: 'N' };

                    // timeLimitSettings
                    const timeLimitParams = { ...commonParams, eltmLmtnStupCn: timeLimitSettings };
                    await callApi("POST", timeLimitParams, "/web/api/dvc-mgt/edm/kisk-insert");
                    console.log("전송된 timeLimitSettings : ", timeLimitParams);

                    // printMarginSettings
                    const printMarginParams = { ...commonParams, prntMrgnStupCn: printMarginSettings };
                    await callApi("POST", printMarginParams, "/web/api/dvc-mgt/edm/kisk-insert");
                    console.log("전송된 printMarginSettings : ", printMarginParams);

                    // framePaymentCntSettings
                    const updatedFramePaymentCntSettings = framePaymentCntSettings.map(payment => {
                        const updatedFrame = filteredFrameArr.find(frame => frame.frameCd === payment.frameCd);
                        return updatedFrame ? { ...payment, ...updatedFrame } : payment;
                    });
                    const framePaymentParams = { ...commonParams, frmeStlmCn: updatedFramePaymentCntSettings };
                    await callApi("POST", framePaymentParams, "/web/api/dvc-mgt/edm/kisk-insert");
                    console.log("전송된 framePaymentCntSettings : ", framePaymentParams);

                    alert("", t("alert.save"), () => { getKiskInfo() });

                } catch (error) {
                    console.log(error);
                    alert("", t("alert.saveError"), () => { return });
                }
                break;
            case "cmmnResetBtn":
                try {
                    modalConfirm("", t("alert.resetConfirm"), () => {
                        setTimeLimitSettings(kiskInfo.eltmLmtnStupCn);
                        setPrintMarginSettings(kiskInfo.prntMrgnStupCn);
                        fetchData();
                        alert("", t("alert.initSuccessAlert"), () => { });
                    })
                } catch (error) {
                    console.log(error);
                    alert("", t("alert.initFailAlert"), () => { });
                }
                break;
            default: break;
        };
    };

    const fetchData = async () => {
        if (kiskInfo && kiskInfo.frmeStupCn && kiskInfo.frmeStlmCn) {
            const frameSettings = kiskInfo.frmeStupCn;
            const tmpFramePaymentCntSettings = kiskInfo.frmeStlmCn;
            setFramePaymentCntSettings(tmpFramePaymentCntSettings);

            const filteredFrames = frameSettings.filter(frame =>
                Array.isArray(frame.systemModeArr)
                && frame.systemModeArr.includes(systemMode.value)
            );

            // framePaymentCntSettings에서 filteredFrames의 frameCd가 있는 항목만 추출하여 filteredFrames에 추가
            const frameCdSet = new Set(filteredFrames.map(frame => frame.frameCd));
            const filteredPaymentSettings = tmpFramePaymentCntSettings.filter(payment => frameCdSet.has(payment.frameCd));
            // console.log("filteredPaymentSettings : ", filteredPaymentSettings);

            // filteredFrames에 amtVal 등의 정보 추가
            const mergedFrames = await Promise.all(filteredFrames.map(async frame => {
                const paymentInfo = filteredPaymentSettings.find(payment => payment.frameCd === frame.frameCd);
                return { ...frame, ...paymentInfo };
            }));

            // console.log("mergedFrames : ", mergedFrames);
            const finalFrames = mergedFrames.flat();
            // console.log("finalFrames : ", finalFrames);
            setFilteredFrameArr(finalFrames);
        }
    };

    useEffect(() => {
        fetchData();
    }, [kiskInfo]);

    useEffect(() => {
        console.log("filteredFrameArr : ", filteredFrameArr);
        console.log("kiskInfo.prntMrgnStupCn : ", kiskInfo.prntMrgnStupCn);
    }, [filteredFrameArr, kiskInfo]);

    return (
        <>
            <ListInputWrap>
                <li>
                    <InputTitle className="modal-title">
                        <h2>1. {t("prgmLimitTimeSetting.title")}</h2>
                    </InputTitle>
                    <InputTitle>
                        <h2>{t("prgmLimitTimeSetting.lblDefLimitTimeSet")}</h2>
                    </InputTitle>
                    <InputContent>
                        <div className="dp-flex align-cen w-100">
                            <SelectBox
                                defaultOptTxt={t("saleHistPage.entire")}
                                dataArray={basicLimitArray}
                                onSelectChange={(e) => handleTimeLimitChange(e, 'basicLimit')}
                                tagName="basicLimit"
                                defaultVal={timeLimitSettings.basicLimit || 60}
                            />
                            <h5>{t("common.seconds")}</h5>
                        </div>
                    </InputContent>
                </li>
                <li>
                    <InputTitle>
                        <h2>{t("prgmLimitTimeSetting.lblPaymentLimitTimeSet")}</h2>
                    </InputTitle>
                    <InputContent>
                        <div className="dp-flex align-cen w-100">
                            <SelectBox
                                defaultOptTxt={t("saleHistPage.entire")}
                                dataArray={basicLimitArray}
                                onSelectChange={(e) => handleTimeLimitChange(e, 'paymentLimit')}
                                tagName="paymentLimit"
                                defaultVal={timeLimitSettings.paymentLimit || 60}
                            />
                            <h5>{t("common.seconds")}</h5>
                        </div>
                    </InputContent>
                </li>
                <li>
                    <InputTitle>
                        <h2>{t("prgmLimitTimeSetting.lblTakeReadyLimitTimeSet")}</h2>
                    </InputTitle>
                    <InputContent>
                        <div className="dp-flex align-cen w-100">
                            <SelectBox
                                defaultOptTxt={t("saleHistPage.entire")}
                                dataArray={takeReadyLimitArray}
                                onSelectChange={(e) => handleTimeLimitChange(e, 'takeReadyLimit')}
                                tagName="takeReadyLimit"
                                defaultVal={timeLimitSettings.takeReadyLimit || 60}
                            />
                            <h5>{t("common.seconds")}</h5>
                        </div>
                    </InputContent>
                </li>
                <li>
                    <InputTitle>
                        <h2>{t("prgmLimitTimeSetting.lblTakePhotoLimitTimeSet")}</h2>
                    </InputTitle>
                    <InputContent>
                        <div className="dp-flex align-cen w-100">
                            <SelectBox
                                defaultOptTxt={t("saleHistPage.entire")}
                                dataArray={takeLimitArray}
                                onSelectChange={(e) => handleTimeLimitChange(e, 'takeLimit')}
                                tagName="takeLimit"
                                defaultVal={timeLimitSettings.takeLimit || 60}
                            />
                            <h5>{t("common.seconds")}</h5>
                        </div>
                    </InputContent>
                </li>
                {brchClsfCd === "KSC" &&
                    <>
                        <li>
                            <InputTitle className="modal-title">
                                <h2>2. {t("prgmPrintWhiteSpaceSetting.title")}</h2>
                            </InputTitle>
                            <InputTitle>
                                <h2>{t("prgmPrintWhiteSpaceSetting.lblVFrameTopWs")}</h2>
                            </InputTitle>
                            <InputContent>
                                <div className="dp-flex align-cen w-100">
                                    <TextFields
                                        type="number"
                                        name="vTopMargin"
                                        onChangeValue={(e) => handlePringMarginChange(e, 'vTopMargin')}
                                        value={printMarginSettings.vTopMargin || ""}
                                        className="w-100"
                                        pattern="\d*"
                                    />
                                    <h5>px</h5>
                                </div>
                            </InputContent>
                        </li>
                        <li>
                            <InputTitle>
                                <h2>{t("prgmPrintWhiteSpaceSetting.lblVFrameLeftWs")}</h2>
                            </InputTitle>
                            <InputContent>
                                <div className="dp-flex align-cen w-100">
                                    <TextFields
                                        type="number"
                                        name="vLeftMargin"
                                        onChangeValue={(e) => handlePringMarginChange(e, 'vLeftMargin')}
                                        value={printMarginSettings.vLeftMargin || ""}
                                        className="w-100"
                                        pattern="\d*"
                                    />
                                    <h5>px</h5>
                                </div>
                            </InputContent>
                        </li>
                        <li>
                            <InputTitle>
                                <h2>{t("prgmPrintWhiteSpaceSetting.lblHFrameTopWs")}</h2>
                            </InputTitle>
                            <InputContent>
                                <div className="dp-flex align-cen w-100">
                                    <TextFields
                                        type="number"
                                        name="hTopMargin"
                                        onChangeValue={(e) => handlePringMarginChange(e, 'hTopMargin')}
                                        value={printMarginSettings.hTopMargin || ""}
                                        className="w-100"
                                        pattern="\d*"
                                    />
                                    <h5>px</h5>
                                </div>
                            </InputContent>
                        </li>
                        <li>
                            <InputTitle>
                                <h2>{t("prgmPrintWhiteSpaceSetting.lblHFrameLeftWs")}</h2>
                            </InputTitle>
                            <InputContent>
                                <div className="dp-flex align-cen w-100">
                                    <TextFields
                                        type="number"
                                        name="hLeftMargin"
                                        onChangeValue={(e) => handlePringMarginChange(e, 'hLeftMargin')}
                                        value={printMarginSettings.hLeftMargin || ""}
                                        className="w-100"
                                        pattern="\d*"
                                    />
                                    <h5>px</h5>
                                </div>
                            </InputContent>
                        </li>
                    </>
                }
                <li>
                    <InputTitle className="modal-title">
                        {brchClsfCd === "KSC" ? <h2>3. {t("prgmFrameSetting.title")}</h2> : <h2>2. {t("prgmFrameSetting.title")}</h2>}
                    </InputTitle>
                    {filteredFrameArr.map((frame, index) => (
                        <Fragment key={`fragment_key_${index}`}>
                            <InputTitle>
                                <h2>{t(`prgmFrameSetting.lbl${frame.frameCd}FrameExpl`)}</h2>
                            </InputTitle>
                            <InputContent>
                                <div className="dp-flex align-cen space-between w-100 frame-input">
                                    <h2>{t("prgmFrameSetting.lblBaseAmt")}</h2>
                                    <TextFields
                                        key={`inputKey_0_${index}`}
                                        type="number"
                                        name="amtVal"
                                        value={parseInt(frame.amtVal)}
                                        onChangeValue={(e) => handleFrameSettingsChange(e, frame.frameCd)}
                                        className="w-100"
                                        step="100"
                                        pattern="\d*"
                                    />
                                </div>
                                <div className="dp-flex align-cen space-between w-100 frame-input">
                                    <h2>{t("prgmFrameSetting.lblAddAmt")}</h2>
                                    <TextFields
                                        key={`inputKey_1_${index}`}
                                        type="number"
                                        name="addAmtVal"
                                        value={parseInt(frame.addAmtVal)}
                                        onChangeValue={(e) => handleFrameSettingsChange(e, frame.frameCd)}
                                        className="w-100"
                                        step="100"
                                        pattern="\d*"
                                    />
                                </div>
                                <div className="dp-flex align-cen space-between w-100 frame-input">
                                    <h2>{t("prgmFrameSetting.lblPrintPhotCnt")}</h2>
                                    <TextFields
                                        key={`inputKey_2_${index}`}
                                        type="number"
                                        name="basePhotoCnt"
                                        value={parseInt(frame.basePhotoCnt)}
                                        step={frame.frameInfo.cutYn === 'Y' ? 2 : 1}
                                        min={frame.frameInfo.cutYn === 'Y' ? 2 : 1}
                                        onChangeValue={(e) => handleFrameSettingsChange(e, frame.frameCd)}
                                        className="w-100"
                                        pattern="\d*"
                                    />
                                </div>
                                <div className="dp-flex align-cen space-between w-100 frame-input">
                                    <h2>{t("prgmFrameSetting.lblPhotoTakeCnt")}</h2>
                                    <TextFields
                                        key={`inputKey_3_${index}`}
                                        type="number"
                                        name="takePhotoCnt"
                                        value={parseInt(frame.takePhotoCnt)}
                                        onChangeValue={(e) => handleFrameSettingsChange(e, frame.frameCd)}
                                        className="w-100"
                                        pattern="\d*"
                                    />
                                </div>
                                <div className="dp-flex align-cen space-between w-100 frame-input">
                                    <h2>{t("prgmFrameSetting.lblFrameTag")}</h2>
                                    <SelectBox
                                        defaultOptTxt={t("saleHistPage.entire")}
                                        dataArray={frameTagArray}
                                        onSelectChange={(e) => handleFrameSettingsChange(e, frame.frameCd)}
                                        tagName="frameTag"
                                        defaultVal={frame.frameTag || ""}
                                    />
                                </div>
                            </InputContent>
                        </Fragment>
                    ))}

                </li>
            </ListInputWrap>
            <BtnWrap>
                <ButtonUtil className="btn btn-danger round-filled-small-btn"
                    value={t("common.lblInitBtn")}
                    onClick={() => { handleCmmnBtnClick("cmmnResetBtn") }}
                />
                <ButtonUtil className="btn btn-outline-light round-solid-medium-btn"
                    value={t("common.cancelLable")}
                    onClick={() => { onHide() }}
                />
                <ButtonUtil className="btn btn-danger round-filled-small-btn"
                    value={t("common.saveLable")}
                    onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                />
            </BtnWrap>
        </>
    )
}