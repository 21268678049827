'use client'
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function SelectBox({ 
    defaultOptTxt,
    defaultVal,
    tagName,
    onSelectChange,
    className,
    disabled,
    dataArray,
}) {

    const [selectedVal, setSelectedVal] = useState(defaultVal || '');

    useEffect(() => {
        setSelectedVal(defaultVal || '');
    }, [defaultVal]);

    const handleSelectChange = (e) => {
        const selectedVal = e.target.value;
        setSelectedVal(selectedVal);
        const event = { target: { name: tagName, value: selectedVal } };
        onSelectChange(event);
    };

    return (
        <Form.Select
            name={tagName}
            value={selectedVal}
            onChange={handleSelectChange}
            disabled={disabled}
            className={className}
        >
            <option value="">{defaultOptTxt}</option>
            {dataArray.map((data) => (
                <option key={data.value} value={data.value}>
                    {data.key}
                </option>
            ))}

        </Form.Select>
    );
}
export default SelectBox;