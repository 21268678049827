import styled, { css, keyframes } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import dshBrdLogo from "asset/photomag_web/img/icon/dashboard.svg"
import SalesLogo from "asset/photomag_web/img/icon/monetization-on.svg"
import DvcLogo from "asset/photomag_web/img/icon/developer-board.svg"
import svcMgtLogo from "asset/photomag_web/img/icon/insert-emoticon.svg"
import sysMgtLogo from "asset/photomag_web/img/icon/local-activity.svg"
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SideMenu = styled.ul`
    li {
        display: flex;
        align-items: center;
        padding: 18px 20px !important;
        border-radius: 8px;
        margin-bottom: 10px !important; 
        cursor: pointer;
        transition: all .3s ease-in-out;
        &:not(.service-sub-menu-list a li):hover {
            background-color: #EF2929;
        }
        h2 {
            font-size: 16px;
            color: #fff;
            margin-left: 15px;
        }
    }
    a:first-child li img {
        width: 15px;
        margin: 2px;
    }
    .select {
        background-color: #EF2929;
    }
    .service-menu-list {
        a li {
            padding: 10px 20px;
            transition: all .3s ease-in-out;
            &:hover {
                background-color: #2D2D2A;
            }
        }
        button {
            background-color: transparent;
            svg {
                color: #fff;
                transition: all .3s ease-in-out;
            }
        }
    }
    .flexset {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .alignset {
        display: flex;
        align-items: center;
    } 
    .rotate {
        transition: transform 0.4s;
    }
    .rotate.down {
        transform: rotate(180deg);
    }
`;
const slideDown = (props) => keyframes`
    from {
        max-height: 0;
    }
    to {
         max-height: ${(props.$brchClsfCd === 'KSC' && props.$label === 'sysMgt') ? '55px' : (props.$brchClsfCd === 'KSC' ? '310px' : '180px')};
        // max-height: ${props.$brchClsfCd === 'KSC' ? '250px' : '180px'};
    }
`;
const slideUp = (props) => keyframes`
    from {
        max-height: ${(props.$brchClsfCd === 'KSC' && props.$label === 'sysMgt') ? '55px' : (props.$brchClsfCd === 'KSC' ? '310px' : '180px')};
        // max-height: ${props.$brchClsfCd === 'KSC' ? '250px' : '180px'};
    }
    to {
        max-height: 0;
    }
`;
const SubMenuList = styled.div`
    overflow: hidden;
    animation: ${props => props.$isSubMenu
        ? css`${slideDown(props)} 0.4s ease-in-out`
        : css`${slideUp(props)} 0.4s ease-in-out`};
    max-height: ${props => props.$isSubMenu
        ? (props.$brchClsfCd === 'KSC' && props.$label === 'sysMgt') ? '55px' : (props.$brchClsfCd === 'KSC' ? '310px' : '180px')
        : '0'};
`;

export default function LeftMenu() {
    library.add(faChevronDown);
    const { t } = useTranslation();
    const brchClsfCd = localStorage.getItem("brchClsfCd");

    const links = [
        {
            href: "/dsh-brd/dsh-brd",
            key: t("leftMenu.dshBrd"),
            label: "dshBrd",
            img: dshBrdLogo,
        },
        {
            href: "/sal-hist/sal-hist",
            key: t("leftMenu.salHist"),
            label: "salHist",
            img: SalesLogo,
        },
        {
            href: "/dvc-mgt/dvc-mgt",
            key: t("leftMenu.dvcMgt"),
            label: "dvcMgt",
            img: DvcLogo,
        },
        {
            key: t("leftMenu.svcMng"),
            img: svcMgtLogo,
            label: "svcMng",
            subMenu: [
                {
                    href: "/svc-mgt/brch-mgt",
                    key: t("leftMenu.brchMgt"),
                    label: "brchMgt",
                },
                ...(brchClsfCd === "KSC" ? [
                    {
                        href: "/svc-mgt/eqmt-mgt",
                        key: t("leftMenu.eqmtMgt"),
                        label: "eqmtMgt",
                    },
                ] : []),
                {
                    href: "/svc-mgt/user-mgt",
                    key: t("leftMenu.userMgt"),
                    label: "userMgt",
                },
                // {
                //     href: "/svc-mgt/cpn-mgt",
                //     key: t("leftMenu.cpnMgt"),
                //     label: "cpnMgt",
                // },
                ...(brchClsfCd === "KSC" ? [
                    {
                        href: "/svc-mgt/frame-upload",
                        key: t("leftMenu.frameUpdt"),
                        //key: "프레임배경변경",
                        label: "frameUpload",
                    },
                ] : []),
                {
                    href: "/svc-mgt/smt-mgt",
                    key: t("leftMenu.smtMgt"),
                    label: "smtMgt",
                },
            ],
        },
        ...(brchClsfCd === "KSC" ? [
            {
                key: t("leftMenu.sysMgt"),
                img: sysMgtLogo,
                label: "sysMgt",
                subMenu: [
                    {
                        href: "/sys-mgt/auth-mgt",
                        key: t("leftMenu.authMgt"),
                        label: "authMgt",
                    }
                ]
            }
        ] : []),
    ];

    const location = useLocation();
    const [selected, setSelected] = useState(location.pathname);
    const subMenuState = JSON.parse(sessionStorage.getItem('isSubMenu')) || {};
    const [isSubMenu, setIsSubMenu] = useState(subMenuState);

    useEffect(() => {
        setSelected(location.pathname)
    }, [location.pathname]);

    const handleClick = (href) => {
        setSelected(href);
    };

    useEffect(() => {
        sessionStorage.setItem('isSubMenu', JSON.stringify(isSubMenu));
    }, [isSubMenu]);

    const sideMenuToggle = (key) => {
        setIsSubMenu(prevState => {
            const newState = {
                ...prevState,
                [key]: !prevState[key]
            };
            sessionStorage.setItem('isSubMenu', JSON.stringify(isSubMenu));
            return newState;
        })
    }

    const isPathSelected = (path) => {
        return location.pathname === path || location.pathname.startsWith(path);
    };

    return (
        <SideMenu>
            {links.map((val, idx) => (
                <Fragment key={idx}>
                    {val.subMenu ? (
                        <div className="service-menu-list">
                            <div onClick={() => sideMenuToggle(val.key)}>
                                <li className="flexset">
                                    <div className="alignset">
                                        <img src={val.img} alt={`${val.key}_logo`} />
                                        <h2>{val.key}</h2>
                                    </div>
                                    <button>
                                        <FontAwesomeIcon icon={faChevronDown}
                                            className={`rotate ${isSubMenu[val.key] ? 'down' : ''}`}
                                        />
                                    </button>
                                </li>
                            </div>
                            <SubMenuList className="service-sub-menu-list" $isSubMenu={isSubMenu[val.key]} $brchClsfCd={brchClsfCd} $label={val.label}>
                                {val.subMenu.map((subVal, subIdx) => (
                                    <Link to={subVal.href} key={subIdx} onClick={() => handleClick(subVal.href)}>
                                        <li className={isPathSelected(subVal.href) ? "select" : ""}>
                                            <h2>{subVal.key}</h2>
                                        </li>
                                    </Link>
                                ))}
                            </SubMenuList>
                        </div>
                    ) : (
                        <Link to={val.href} key={val.key} onClick={() => handleClick(val.href)}>
                            <li className={isPathSelected(val.href) ? "select" : ""}>
                                <img src={val.img} alt={`${val.key}_logo`} />
                                <h2>{val.key}</h2>
                            </li>
                        </Link>
                    )}
                </Fragment>
            ))}
        </SideMenu>
    );
};