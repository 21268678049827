import i18n from "i18next"
import { initReactI18next } from "react-i18next";
import translationKO from "locales/ko/translation.json";
import translationJP from "locales/jp/translation.json";
import translationEn from "locales/en/translation.json";



const resources = {
    ko: {
        translation: translationKO
    },
    jp: {
        translation: translationJP
    },
    en: {
        translation: translationEn
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: "ko",
    fallbackLng: "ko", //번역파일에서 찾을 수 없는 경우 기본 언어
    interpolation: {
        escapeValue: false
    }
});

export default i18n;