'use client'

import { useEffect, useState } from "react";
import * as apiSvc from "api/components/cmmn/CmmnApiCallService"
import { Form } from "react-bootstrap";

export default function BrchTelNoSelectBox(
    {
        defaultOptTxt
        , defaultVal
        , tagName
        , onSelectChange
        , readonly
        , className
        , disabled
        , brchId
    }
) {

    const [selectedVal, setSelectedVal] = useState(defaultOptTxt);
    const [brchAllList, setBrchAllList] = useState([]);

    useEffect(() => {
        apiSvc.GetBrchTelNoList({ brchId })
            .then(res => {
                setBrchAllList(res);
                if (defaultVal === 0 || defaultVal === '') {
                    setSelectedVal('');
                } else {
                    const selectedCd = res.find(data => data.brchId == defaultVal);
                    if (selectedCd) {
                        setSelectedVal(selectedCd.brchId);
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }, [defaultVal]);

    const handleSelectChange = (e) => {
        const selectedVal = e.target.value;
        console.log(selectedVal);
        setSelectedVal(selectedVal);
        const event = { target: { name: tagName, value: selectedVal } };
        onSelectChange(event);
    };

    return (
        <Form.Select
            name={tagName}
            value={selectedVal}
            onChange={handleSelectChange}
            disabled={disabled}
            className={className}
        >
            <option value="">{defaultOptTxt}</option>
            {brchAllList.map((brch, idx) => (
                <option key={idx} value={brch.brchCrgrTelNo}>
                    {brch.brchCrgrTelNo}
                </option>
            ))}
        </Form.Select>
    );

}