import styled from "styled-components";

const InputBoxStyle = styled.div`
    margin-bottom: 20px;
    width: 100%;
    .round-input {
        font-size: 16px;
        padding: 15px 20px;
        border-radius: 100px;
    }
    input::placeholder {
        color: #868e96;
    }
`;
// label이 없고 input만 있을 때 사용
function InputBox(
    {
        name,
        type,
        className,
        value,
        placeholder,
        readonly,
        disabled,
        onChangeValue,
        id
    }
) {

    return (
        <InputBoxStyle className={className}>
            <input
                type={type}
                placeholder={placeholder}
                name={name}
                value={value}
                readOnly={readonly}
                disabled={disabled}
                onChange={onChangeValue}
                id={id}
                className="form-control round-input"
            />
        </InputBoxStyle>
    )

}
export default InputBox;