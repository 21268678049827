import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import * as httpService from "util/HttpService";
import { todayDate, nowMonth, prvMonth, thisYear } from "util/DshBrdDateUtil";
import { Nav, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useBrch } from "components/web/auth/BrchProvider";

//img
import device from "asset/img/device.png";
import connectFailed from "asset/img/connect_failed.png";
//img/icon/filled
import dashboardB from "asset/img/icon/filled/dashboard_b.svg";

//component
import MainTimer from "./component/MainTimer";
import { MemorizedDayMonthSaleChart } from "pages/mobile/dsh-brd/component/DayMonthSaleChart";
import SaleByEqmtChart from "pages/mobile/dsh-brd/component/SaleByEqmtChart";
import CrdStatChart from "./component/CrdStatChart";
import SaleStat from "./component/SaleStat";
import CmmnGraphBox from "components/mobile/cmmn/CmmnGraphBox";
import EqmtHourlyChart from "pages/web/sal-hist/EqmtHourlyChart";
import EqmtAccuHourlyChart from "pages/web/sal-hist/EqmtAccuHourlyChart";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import CmmnCreditModal from "components/mobile/cmmn/CmmnCreditModal";

//css
const MainSection = styled.section`

  body {
    line-height: 1;
    font-size: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 10px;
  }

  padding: 100px 20px 100px 20px;
  
  .graph-box {
    width: 100%;
    height: 250px;
    //background-color: #8b8b8b;
    h4{
      text-align:center;
      font-weight:bold;
      font-size:15px;
    }
  }
  .w-100 {
    width: 100%;
  }
  .mt-50 {
    margin-top: 50px;
  }
  body {
    background-color: #f7f7f7;
  }
  .nav-tabs {
    border: none;
  }
  .nav-tabs .nav-link {
    border: none;
    font-size: 14px;
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;
    color: #8b8b8b;
    border-radius: 100px;
  }
  .nav-tabs li {
    margin-right: 5px;
  }
  .nav-tabs li:last-child {
    margin: 0;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border: 1px solid #ef2929;
    border-radius: 100px;
    color: #ef2929;
    font-size: 14px;
    background-color: #fff;
  }
  
  .device-box {
    margin-bottom: 20px;
  }
  
  .device-box .device-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .device-box .device-title h3 {
    font-size: 16px;
    color: #3b3b3b;
    font-weight: 600;
  }
  .device-box .device-title h5 {
    font-size: 14px;
    color: #8b8b8b;
  }
  .device-monitoring {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }
  .device-monitoring .device-img {
    width: 75%;
    border-radius: 16px;
    position: relative;
    background-image: url(${device});
    background-position: center;
    background-size: cover;
    height: 150px;
    padding: 20px;
    border: 1px solid #eeeeee;
  }
  .state-box {
    padding: 5px 10px;
    color: #fff;
    font-size: 14px;
    border-radius: 100px;
    width: fit-content;
  }
  .wating {
    background-color: #1f93ed;
  }
  .disconnected {
    background-color: #A6A6A6;
  }
  .use {
    background-color: #00d45e;
  }

  .device-gauge-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 12px;
    width: 20%;
    height: 150px;
  }
  .device-gauge-bar .gauge {
    height: 13%;
    width: 100%;
    margin-bottom: 5px;
    background-color: #d9d9d9;
  }

  .green-gauge .filled {
    background-color: #00d45e;
  }
  .yellow-gauge .filled {
    background-color: #feb907;
  }
  .red-gauge .filled {
    background-color: red;
    animation: blink-effect 1s step-end infinite;
  }
  
  .charge-name-box h3 {
    font-size: 16px;
    color: #3b3b3b;
    font-weight: bold;
    width: 30%;
  }
  .charge-name-box > div {
    margin-bottom: 20px;
  }
  .charge-name-box > div:last-of-type {
    margin: 0;
  }
  .charge-name-box h4 {
    font-size: 16px;
    color: #3b3b3b;
    font-weight: 400;
    width: 70%;
  }
  .charge-name-box h5 {
    font-size: 14px;
    color: #3b3b3b;
    margin-left: 5px;
  }
  .filled {
    width: 100%;
    height: 100%;
  }

  @keyframes blink-effect {
    50% {
      opacity: 0;
    }
  }
`;

const Btn = styled.button`
  border: none;
  font-size: 14px;
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  color: #8b8b8b;
  border-radius: 100px;
  &.clicked {
    border: 1px solid #ef2929;
    border-radius: 100px;
    color: #ef2929;
    font-size: 14px;
    background-color: #fff;
    font-weight: bold;
  }
`;

const TitleWrap = styled.div`
  .title {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #d3d3d3;
    width: 100%;
  }
  .title img {
    width: 15px;
    margin-right: 10px;
  }
  .title h2 {
    font-size: 18px;
    color: #3b3b3b;
    font-weight: 500;
  }
`;

const DashboardWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0;
`;

const DashboardBox = styled.div`
  background-color: #fff;
  border-radius: 16px;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const ContentWrap = styled.div`
  padding: 20px;
  .device-box:last-child {
    margin: 0;
  }
`;

const MenuTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #eeeeee;
  width: 100%;

  h3 {
    font-size: 16px;
    color: #3b3b3b;
    font-weight: 500;
  }
  .round-filled-small-btn:hover {
    color: var(--bs-btn-hover-color) !important;
    background-color: var(--bs-btn-hover-bg) !important;
    border-color: var(--bs-btn-hover-border-color) !important;
  }
`;

// const POLLING_INTERVAL = 10000;

export default function DshBrd() {
  useEffect(() => { }, []);

  const { t } = useTranslation();
  const { brchId, crdInfo, getBrchCrdInfo, getCrdStat, crdStat, POLLING_INTERVAL, stat, setStat } = useBrch();

  /* 충전하기 팝업 */
  const [isCrdModal, setIsCrdModal] = useState(false);
  const openCrdModal = () => {
    setIsCrdModal(true);
  }
  useEffect(() => {
    getBrchCrdInfo();
  }, []);

  /* 매출현황 */
  const defaultDate = new Date();
  const defaultNextDate = new Date(
    defaultDate.getFullYear(),
    defaultDate.getMonth(),
    defaultDate.getDate() + 1
  );
  const defaultSrchSalStat = {
    brchId: brchId,
    startDate: `${defaultDate.getFullYear()}-${String(defaultDate.getMonth() + 1).padStart(2, "0")}-${String(defaultDate.getDate()).padStart(2, "0")} 00:00:00`,
    endDate: `${defaultNextDate.getFullYear()}-${String(defaultNextDate.getMonth() + 1).padStart(2, "0")}-${String(defaultNextDate.getDate()).padStart(2, "0")} 00:00:00`,
  };
  const [srchSalStat, setSrchSalStat] = useState(defaultSrchSalStat);
  const defaultSalStat = {
    totStlmCnt: 0,
    totPhtQty: 0,
    totStlmAmt: 0,
  };
  const [salStat, setSalStat] = useState(defaultSalStat);

  useEffect(() => {
    getSalStat();
  }, [srchSalStat]);

  const getSalStat = () => {
    const searchData = srchSalStat;
    httpService
      .callApi("POST", searchData, "/web/api/dsh-brd/get-sale-stat")
      .then((response) => {
        setSalStat(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [saleStatClicked, setSaleStatClicked] = useState("today");
  const statHandleClick = (e) => {
    const btnNm = e.target.name;
    setSaleStatClicked(btnNm);
    const now = new Date();
    var srchDate;
    switch (btnNm) {
      case "today":
        srchDate = todayDate(now);
        setSrchSalStat((prv) => ({
          ...prv,
          startDate: srchDate[0],
          endDate: srchDate[1],
        }));
        break;
      case "nowMonth":
        srchDate = nowMonth(now);
        setSrchSalStat((prv) => ({
          ...prv,
          startDate: srchDate[0],
          endDate: srchDate[1],
        }));
        break;
      case "prvMonth":
        srchDate = prvMonth(now);
        setSrchSalStat((prv) => ({
          ...prv,
          startDate: srchDate[0],
          endDate: srchDate[1],
        }));
        break;

      default:
        break;
    }
  };

  const saleTabs = [{ "ko": t("m_dashboardPage.today"), "en": "today" }, { "ko": t("m_dashboardPage.thisMonth"), "en": "nowMonth" }, { "ko": t("m_dashboardPage.prvMonth"), "en": "prvMonth" }];
  const [saleActivekey, setActivekey] = useState("saleTab1");
  const [source, setSource] = useState("");
  const saleHandleSelect = (key) => {
    setActivekey(key);
    setSource('click');
  };
  /* 매출현황 끝 */

  /* 시간 매출 */
  const hourlyArray = () => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      const hour = i < 10 ? `0${i}` : `${i}`;
      hours.push(`${hour}`);
    }
    return hours;
  }
  const hourList = hourlyArray();
  const hourTab = [t("m_dashboardPage.time"), t("m_dashboardPage.timeAcc")];
  const [timeArray] = useState(hourlyArray);
  const [hourlySalesList, setHourlySalesList] = useState([[], [], [], []]);

  const defaultHourlySalesSearchData = {
    brchId: brchId,
    eqmtId: "",
    startDate: `${defaultDate.getFullYear()}-${String(defaultDate.getMonth() + 1).padStart(2, '0')}-${String(defaultDate.getDate()).padStart(2, '0')}`,
    endDate: `${defaultDate.getFullYear()}-${String(defaultDate.getMonth() + 1).padStart(2, '0')}-${String(defaultDate.getDate()).padStart(2, '0')}`,
    hourList: hourList,
    division: "DSH",
  };
  const [hourlySalesSearchData, setHourlySalesSearchData] = useState(defaultHourlySalesSearchData);

  const getHourlySalesList = () => {
    httpService.callApi("POST", hourlySalesSearchData, `/web/api/sal-hist/eph/hour-sales`)
      .then(res => {
        setHourlySalesList(res);
      })
      .catch(error => {
        console.log(error);
      })
  };

  useEffect(() => {
    getHourlySalesList();
  }, [hourlySalesSearchData]);


  const [hourlyTypeTabs, setHourlyTypeTabs] = useState([]);

  useEffect(() => {
    // 비동기 작업 등을 통해 변수를 생성합니다
    setHourlyTypeTabs([
      { tabTitle: "시간", graphComponent: <EqmtHourlyChart salesData={hourlySalesList} dateArray={timeArray} searchData={hourlySalesSearchData} /> },
      { tabTitle: "시간 누적", graphComponent: <EqmtAccuHourlyChart salesData={hourlySalesList} dateArray={timeArray} searchData={hourlySalesSearchData} /> }
    ]);
  }, [hourlySalesList]);
  // 이 부분이 첫렌더링시에 생성되는데 hourlySaleList, timeArray가 아직 빈배열인데 참조할려해서 문제가 생기는듯?


  /* 시간 매출 끝 */



  /* 일월매출 */
  const dayArray = () => {
    const today = new Date();
    const result = [];
    for (let i = 0; i < 30; i++) {
      const currentDate = new Date();
      currentDate.setDate(today.getDate() - i);
      const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // 월을 2자리로 변환
      const day = ('0' + currentDate.getDate()).slice(-2); // 일을 2자리로 변환
      result.push(`${month}-${day}`);
    }
    return result.reverse();
  };
  const monthArray = () => {
    var result = [];
    var month = defaultDate.getMonth() + 1;
    for (var i = 0; i < 12; i++) {
      var year = defaultDate.getFullYear();
      var newMonth = month - i;
      if (newMonth <= 0) {
        newMonth += 12;
        year -= 1;
      }
      result.push(`${year}-${String(newMonth).padStart(2, '0')}`);
    }
    result = result.reverse();
    return result;
  }

  const defaultDateData = dayArray();
  const [dateData, setDateData] = useState(defaultDateData);
  const [saleData, setSaleData] = useState([]);

  const defaultDaySaleSearchData = {
    brchId: brchId,
    dayList: dateData.map((data) => defaultDate.getFullYear() + "-" + data),
    startDate: dateData.map((data) => defaultDate.getFullYear() + "-" + data)[0] + " 00:00:00",
    endDate: `${defaultNextDate.getFullYear()}-${String(defaultNextDate.getMonth() + 1).padStart(2, '0')}-${String(defaultNextDate.getDate()).padStart(2, '0')} 00:00:00`,
  };
  const [daySaleSearchData] = useState(defaultDaySaleSearchData);

  const defaultMonthSaleSearchData = {
    brchId: brchId,
    monthList: monthArray().map(data => data.replace("-", "")),
  };
  const [monthSaleSearchData] = useState(defaultMonthSaleSearchData);

  useEffect(() => {
    getDaySaleData();
  }, []);

  // 일월별 매출액 조회
  const getDaySaleData = () => {
    httpService.callApi("POST", daySaleSearchData, "/web/api/dsh-brd/get-daily-sale")
      .then(res => {
        setSaleData(res);
      })
      .catch(error => {
        console.error(error);
      })
  };
  const getMonthSaleData = () => {
    httpService.callApi("POST", monthSaleSearchData, "/web/api/dsh-brd/get-monthly-sale")
      .then(res => {
        setSaleData(res);
      })
      .catch(error => {
        console.error(error);
      })
  };

  const dayMonthTabs = [{ "ko": t("m_dashboardPage.daily"), "en": "day" }, { "ko": t("m_dashboardPage.monthly"), "en": "month" },];
  const [dayMonthClicked, setDayMonthClicked] = useState('day');
  const hanldeDayMonthChartClick = (e) => {
    const btnNm = e.target.name;
    setDayMonthClicked(btnNm);
    switch (btnNm) {
      case 'day':
        setDateData(dayArray());
        getDaySaleData();
        break;
      case 'month':
        setDateData(monthArray());
        getMonthSaleData();
        break;
      default:
        break;
    }
  };
  const [dayMonthActiveKey, setDayMonthActiveKey] = useState("dayMonthTab1");
  const dayMonthHandleSelect = (key) => {
    setDayMonthActiveKey(key);
  };
  /* 일월매출 끝 */

  /* 장치매출 */
  const defaultSaleByEqmtSrchData = {
    srchTag: "eph", //조회 구분 태그 (오늘,이달 / 올해)
    brchId: brchId,
    startDate: `${defaultDate.getFullYear()}-${String(defaultDate.getMonth() + 1).padStart(2, '0')}-${String(defaultDate.getDate()).padStart(2, '0')} 00:00:00`,
    endDate: `${defaultNextDate.getFullYear()}-${String(defaultNextDate.getMonth() + 1).padStart(2, '0')}-${String(defaultNextDate.getDate()).padStart(2, '0')} 00:00:00`,
  }
  const [saleByEqmtSrchData, setSaleByEqmtSrchData] = useState(defaultSaleByEqmtSrchData);
  const defaultEqmtData = [];
  const [eqmtData, setEqmtData] = useState(defaultEqmtData);

  useEffect(() => {
    if (saleByEqmtSrchData.srchTag === 'eph') {
      getSaleByEqmt();
    } else {
      getSaleByEqmtYear();
    }
  }, [saleByEqmtSrchData])

  const getSaleByEqmt = () => {
    httpService.callApi("POST", saleByEqmtSrchData, "/web/api/dsh-brd/get-sale-by-eqmt")
      .then(res => {
        if (res.length !== 0 && res[0].totalAmt !== 0) {
          setEqmtData(res);
          setTotalSaleByEqmt(res[0].totalAmt);
        } else {
          //매출데이터 조회 실패시 기본 리스트 불러오기
          httpService.callApi("POST", saleByEqmtSrchData, "/web/api/dsh-brd/get-eqmt-list")
            .then(res => {
              console.log(res);
              setEqmtData(res);
              setTotalSaleByEqmt(0);
            })
            .catch(error => {
              console.error(error);
            })
        }
      })
      .catch(error => {
        console.error(error);
      })
  };
  const getSaleByEqmtYear = () => {
    httpService.callApi("POST", saleByEqmtSrchData, "/web/api/dsh-brd/get-sale-by-eqmt-year")
      .then(res => {
        if (res.length !== 0 && res[0].totalAmt !== 0) {
          setEqmtData(res);
          setTotalSaleByEqmt(res[0].totalAmt);
        } else {
          httpService.callApi("POST", saleByEqmtSrchData, "/web/api/dsh-brd/get-eqmt-list")
            .then(res => {
              console.log(res);
              setEqmtData(res);
              setTotalSaleByEqmt(0);
            })
            .catch(error => {
              console.error(error);
            })
        }
      })
      .catch(error => {
        console.error(error);

      })
  };

  const [saleByEqmtTitle, setSaleByEqmtTitle] = useState("");
  const [totalSaleByEqmt, setTotalSaleByEqmt] = useState(0);
  const [eqmtSaleClicked, setEqmtSaleClicked] = useState("today");
  useEffect(() => {
    setSaleByEqmtTitle(t(eqmtSaleClicked === "today" ? "m_dashboardPage.today" : eqmtSaleClicked === "thisMonth" ? "m_dashboardPage.month" : "m_dashboardPage.thisYear"));
  }, [t]);
  const eqmtSaleHandleClick = (e) => {
    const btnNm = e.target.name;
    setEqmtSaleClicked(btnNm);
    const now = new Date();
    var srchDate;
    switch (btnNm) {
      case 'today':
        setSaleByEqmtTitle(t("m_dashboardPage.today"));
        srchDate = todayDate(now);
        setSaleByEqmtSrchData((prv) => ({
          ...prv,
          srchTag: "eph",
          startDate: srchDate[0],
          endDate: srchDate[1],
        }));
        break;
      case 'thisMonth':
        setSaleByEqmtTitle(t("m_dashboardPage.month"));
        srchDate = nowMonth(now);
        setSaleByEqmtSrchData((prv) => ({
          ...prv,
          srchTag: "eph",
          startDate: srchDate[0],
          endDate: srchDate[1],
        }));
        break;
      case 'thisYear':
        setSaleByEqmtTitle(t("m_dashboardPage.thisYear"));
        srchDate = thisYear(now);
        setSaleByEqmtSrchData((prv) => ({
          ...prv,
          srchTag: "bmsa",
          startDate: srchDate[0],
          endDate: srchDate[1],
        }));
        break;
      default:
        break;
    }
  };

  const eqmtSaleTabs = [{ "ko": t("m_dashboardPage.today"), "en": "today" }, { "ko": t("m_dashboardPage.month"), "en": "thisMonth" }, { "ko": t("m_dashboardPage.thisYear"), "en": "thisYear" },];
  const [eqmtSaleActiveKey, setEqmtSaleActiveKey] = useState("eqmtSaleTab1");
  const eqmtSaleHandleSelect = (key) => {
    setEqmtSaleActiveKey(key);
  };
  /* 장치매출 끝 */

  /* 크레딧현황 */
  // const [crdStat, setCrdStat] = useState([]);

  useEffect(() => {
    getCrdStat();
  }, [])
  // const getCrdStat = () => {
  //   httpService.callApi("GET", { brchId: brchId }, "/web/api/dsh-brd/get-crd-stat")
  //     .then(res => {
  //       const crdStatValueArr = [res.possCrdCnt, res.tmnUseCrdCnt];
  //       setCrdStat(crdStatValueArr);
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     })
  // }
  /* 크레딧현황 끝 */

  /* 모니터링 */
  const [eqmtList, setEqmtList] = useState([]);
  useEffect(() => {
    getEqmtList();
  }, [])
  useEffect(() => {
    getEqmtList();
  }, [t]);
  const getEqmtList = () => {
    httpService.callApi("GET", { brchId: brchId }, "/web/api/dsh-brd/get-eqmt-list")
      .then(res => {
        const currentDate = new Date();
        res = res.map(data => {
          const localDate = new Date(data.chngDttm);
          const diffMillSec = Math.floor((currentDate - localDate) / 1000);
          if (diffMillSec > 20) {
            data.prgmStatCd = "CONNECTFAIL";
            data.prgmCptrImgCn = "DISCONNECTED";
          }
          return data;
        });
        res = res.map((data) => ({
          ...data,
          prgmStatCdNm: t(data.prgmStatCd === "CONNECTFAIL" ? "dashboardPage.connectFailed" : data.prgmStatCd === "INUSE" ? "dashboardPage.inuse" : "dashboardPage.waiting")
        }));
        setEqmtList(res);
      })
      .catch(error => {
        console.error(error);
      })
  };
  /* 모니터링 끝 */


  /* 실시간 데이터 갱신 POLLING 방식*/
  useEffect(() => {
    const interval = setInterval(getSalStat, POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, [srchSalStat,dayMonthClicked,eqmtSaleClicked]);

  useEffect(() => {
    const interval = setInterval(getHourlySalesList, POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, [srchSalStat,dayMonthClicked,eqmtSaleClicked]); // polling interval의 시작시점을 맞추기 위해 매출현황의 srchSalStat으로 설정


  useEffect(() => {
    var interval;
    if (dayMonthClicked === 'day') {
      interval = setInterval(getDaySaleData, POLLING_INTERVAL);
    } else if (dayMonthClicked === 'month') {
      interval = setInterval(getMonthSaleData, POLLING_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [srchSalStat,dayMonthClicked,eqmtSaleClicked]);


  useEffect(() => {
    var interval;
    if (eqmtSaleClicked === 'today' || eqmtSaleClicked === 'thisMonth') {
      interval = setInterval(getSaleByEqmt, POLLING_INTERVAL);

    } else if (eqmtSaleClicked === 'thisYear') {
      interval = setInterval(getSaleByEqmtYear, POLLING_INTERVAL);

    }

    return () => clearInterval(interval);
  }, [srchSalStat,dayMonthClicked,eqmtSaleClicked]);

  useEffect(() => {
    const interval = setInterval(getCrdStat, POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, [srchSalStat,dayMonthClicked,eqmtSaleClicked]);

  useEffect(() => {
    const interval = setInterval(() => {
      getEqmtList();
      setSource("polling");
    }, POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  /* 실시간 데이터 갱신 끝 */


  const testOutput = () => {
    console.log(eqmtList);
  }


  return (
    <>
      <MainSection>
        <TitleWrap>
          {/* <button onClick={testOutput}>test</button> */}
          <div className="title">
            <img src={dashboardB} alt="dashboard_b" />
            <h2>{t("m_dashboardPage.dshBrd")}</h2>
          </div>

          {/* <h3 className="data-text">현재일시 : 2024-05-27(월) 16:24:15</h3> */}
          <MainTimer />
        </TitleWrap>

        <DashboardWrap>
          <DashboardBox>
            <MenuTitleWrap>
              <h3>{t("m_dashboardPage.saleStat")}</h3>
              <Tab.Container activeKey={saleActivekey} onSelect={saleHandleSelect}>
                <Nav variant="tabs" as="ul">
                  {saleTabs.map((tab, idx) => (
                    <Nav.Item as="li" key={idx}>
                      <Nav.Link eventKey={`saleTab${idx + 1}`} as="button" className={saleStatClicked === tab.en ? 'clicked' : ''} name={tab.en} onClick={statHandleClick}>
                        {tab.ko}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Tab.Container>
            </MenuTitleWrap>

            <ContentWrap>
              {/* Tab으로 바꾸기 */}
              <Tab.Container activeKey={saleActivekey} onSelect={saleHandleSelect}>
                <Tab.Content>
                  {saleTabs.map((tab, index) => (
                    <Tab.Pane key={index} eventKey={`saleTab${index + 1}`}>
                      <SaleStat salStat={salStat} source={source} />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>

            </ContentWrap>
          </DashboardBox>

          {/*시간매출부분*/}
          {hourlySalesList.every(data => data.length === 0) ? <></>
            :
            <CmmnGraphBox
              title={t("m_dashboardPage.salesByHour")}
              tabs={hourTab}
              graphComponents={hourlyTypeTabs.map(tab => tab.graphComponent)}
            />}


          <DashboardBox>
            <MenuTitleWrap>
              <h3>{t("m_dashboardPage.dailyMonthlySale")}</h3>

              <Tab.Container activeKey={dayMonthActiveKey} onSelect={dayMonthHandleSelect}>
                <Nav variant="tabs" as="ul">
                  {dayMonthTabs.map((tab, idx) => (
                    <Nav.Item as="li" key={idx}>
                      <Nav.Link eventKey={`dayMonthTab${idx + 1}`} as="button" className={dayMonthClicked === tab.en ? 'clicked' : ''} name={tab.en} onClick={hanldeDayMonthChartClick}>
                        {tab.ko}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Tab.Container>
            </MenuTitleWrap>

            <ContentWrap>
              {/* 탭으로 전환하는 경우 */}
              <Tab.Container activeKey={dayMonthActiveKey} onSelect={saleHandleSelect}>
                <Tab.Content>
                  {dayMonthTabs.map((tab, index) => (
                    <Tab.Pane key={index} eventKey={`dayMonthTab${index + 1}`}>
                      <div className="tab-pane fade show active" id="tab1_content1" role="tabpanel" aria-labelledby="tab1_content1">

                        <div className="graph-box"><MemorizedDayMonthSaleChart dateData={dateData} saleData={saleData} /></div>
                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </ContentWrap>
          </DashboardBox>

          <DashboardBox>
            <MenuTitleWrap>
              <h3>{t("m_dashboardPage.salesByEqmt")}</h3>
              <Tab.Container activeKey={eqmtSaleActiveKey} onSelect={eqmtSaleHandleSelect}>
                <Nav variant="tabs" as="ul">
                  {eqmtSaleTabs.map((tab, idx) => (
                    <Nav.Item as="li" key={idx}>
                      <Nav.Link eventKey={`eqmtSaleTab${idx + 1}`} as="button" className={eqmtSaleClicked === tab.en ? 'clicked' : ''} name={tab.en} onClick={eqmtSaleHandleClick}>
                        {tab.ko}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Tab.Container>
            </MenuTitleWrap>

            <ContentWrap>
              {/* 탭으로 전환하는 경우 */}
              <Tab.Container activeKey={eqmtSaleActiveKey} onSelect={saleHandleSelect}>
                <Tab.Content>
                  {eqmtSaleTabs.map((tab, index) => (
                    <Tab.Pane key={index} eventKey={`eqmtSaleTab${index + 1}`}>
                      <div className="tab-pane fade show active" id="tab1_content1" role="tabpanel" aria-labelledby="tab1_content1">

                        <div className="graph-box">
                          <h4>{`${saleByEqmtTitle} ${t("m_dashboardPage.sale")}(￥ ${Intl.NumberFormat("ko-KR", {}).format(totalSaleByEqmt)})`}</h4>
                          <SaleByEqmtChart salesData={eqmtData} text={"¥"} />
                        </div>
                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </ContentWrap>
          </DashboardBox>

          <DashboardBox>
            <MenuTitleWrap>
              <h3>{t("m_dashboardPage.creditStat")}</h3>
              <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn max-content"
                value={t("m_dashboardPage.chargeIt")}
                onClick={() => { openCrdModal() }}
                disabled={true}
              />
            </MenuTitleWrap>
            <ContentWrap>
              <div className="graph-box"><CrdStatChart crdData={crdStat} /></div>
            </ContentWrap>
          </DashboardBox>

          <DashboardBox>
            <MenuTitleWrap>
              <h3>{t("m_dashboardPage.deviceMonitoring")}</h3>
            </MenuTitleWrap>
            <ContentWrap>

              {/* 리스트 map */}
              {eqmtList.map((eqmt) => (
                <div className="device-box" key={eqmt.eqmtId}>
                  <div className="device-title">
                    <h3>{eqmt.eqmtNm}</h3>
                    <h5>{t("m_dashboardPage.paper")} : {eqmt.remPaprQty}{t("m_dashboardPage.sheet")}</h5>
                  </div>

                  <div className="device-monitoring">
                    <div className="device-img" style={eqmt.prgmCptrImgCn === "DISCONNECTED" ? { backgroundImage: `url(${connectFailed})` } : { backgroundImage: `url(${eqmt.prgmCptrImgCn})` }}>
                      <div className={eqmt.prgmStatCd === 'INUSE' ? "state-box use" : eqmt.prgmStatCd === 'WAITING' ? "state-box wating" : "state-box disconnected"}>{eqmt.prgmStatCdNm}</div>
                    </div>

                    <ul className={eqmt.remPaprQty > 400 ? "device-gauge-bar green-gauge" : eqmt.remPaprQty > 100 ? "device-gauge-bar yellow-gauge" : "device-gauge-bar red-gauge"}>


                      <li className="gauge">
                        <div className={eqmt.remPaprQty > 600 ? "filled" : ""}></div>
                      </li>
                      <li className="gauge">
                        <div className={eqmt.remPaprQty > 500 ? "filled" : ""}></div>
                      </li>
                      <li className="gauge">
                        <div className={eqmt.remPaprQty > 400 ? "filled" : ""}></div>
                      </li>
                      <li className="gauge">
                        <div className={eqmt.remPaprQty > 300 ? "filled" : ""}></div>
                      </li>
                      <li className="gauge">
                        <div className={eqmt.remPaprQty > 200 ? "filled" : ""}></div>
                      </li>
                      <li className="gauge">
                        <div className={eqmt.remPaprQty > 100 ? "filled" : ""}></div>
                      </li>
                      <li className="gauge">
                        <div className={eqmt.remPaprQty >= 0 ? "filled" : ""}></div>
                      </li>
                    </ul>
                  </div>

                </div>
              ))}

            </ContentWrap>
          </DashboardBox>
        </DashboardWrap>
      </MainSection>
      {isCrdModal &&
        <CmmnCreditModal
          setIsCrdModal={setIsCrdModal}
          crdInfo={crdInfo}
          crdStat={crdStat}
          t={t}
          getBrchCrdInfo={getBrchCrdInfo}
          stat={stat}
          setStat={setStat}
        />
      }
    </>
  );
}
