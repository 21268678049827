import CmmnRadioBox from "components/mobile/cmmn/CmmnRadio";
import { useEffect, useState } from "react";
import styled from "styled-components";
import SelectBox from "../UI/SelectBox";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import { callApi } from "util/HttpService";
import { alert, modalConfirm } from "./CmmnConfirm";

const TableWrap = styled.div`
    @media (max-width: 1200px) {
        overflow: scroll;
    }
    &.mt-40 {
        margin-top: 40px;
    }
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        margin-bottom: 15px;
    } 
    .mg-0 {
        margin: 0;
    }
`;
const InputTable = styled.div`
    margin-bottom: 20px;
    border-top: 2px solid #EF2929;
    .input-table-th-small{
        max-width: 151px;
        min-width: 151px;
    }
    .input-table-wrap{
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
    .col-2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
`;
const InputTableHead = styled.div`
    background-color: #F3F3F3;
    padding: 20px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 78px;
    // max-width: 151px;
    min-width: 151px;
    h3 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
    }
`;
const InputTableBody = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    h4 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 400;
    }
    h5 {
        font-size: 16px;
        color: #3b3b3b;
        font-weight: 500;
        margin-left: 10px;
    }
    .form-select {
        max-width: 250px;
    }
    input[type="text"] {
        max-width: 250px;
        width: 250px;
    }
`;
const BtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate(-51%);
    .btn {
        width: 150px;
        max-width: 150px;
        &:first-child {
            margin-right: 10px;
        }
        &:nth-child(2) {
            margin-right: 10px;
            &:hover {
                color: var(--bs-btn-hover-color);
                background-color: var(--bs-btn-hover-bg);
                border-color: var(--bs-btn-hover-border-color);
            }
        }
        &:first-child:hover,
        &:last-child:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
    }
`;
export default function SmartCorrectionConfig({
    t,
    brchClsfCd,
    onHide,
    kiskInfo,
    data,
    getKiskInfo,
}) {
    const [aiLabSettings, setAiLabSettings] = useState({});

    useEffect(() => {
        if (kiskInfo && kiskInfo.ailbStupCn) {
            setAiLabSettings(kiskInfo.ailbStupCn);
        }
    }, [kiskInfo]);

    // useEffect(() => {
    //     console.log(aiLabSettings);
    // }, [aiLabSettings]);

    const handleSelectChange = (e, section, key) => {
        const value = e.target.value;
        setAiLabSettings(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [key]: value
            }
        }));
    };

    const handleRadioChange = (value) => {
        setAiLabSettings(prevState => ({
            ...prevState,
            useYn: value
        }));
    };

    // 사용여부
    const useYnDataArr = [
        { value: "Y", label: t("userMgtPage.use") },
        { value: "N", label: t("userMgtPage.unused") },
    ];
    // Sharp 파라미터, Smooth 파라미터, White 파라미터
    const parameterArray = Array.from({ length: 10 }, (_, i) => {
        const value = (i + 1) / 10;
        return {
            key: value === 1 ? "1.0" : value,
            value: value === 1 ? "1.0" : value,
        };
    });

    const handleCmmnBtnClick = async (btnNm) => {
        switch (btnNm) {
            case "cmmnInsertBtn":
                try {
                    const commonParams = { brchId: data.brchId, eqmtId: data.eqmtId, eqmtNo: data.eqmtNo, delYn: 'N' };

                    // aiLabSettings
                    const aiLabParams = { ...commonParams, ailbStupCn: aiLabSettings };
                    await callApi("POST", aiLabParams, "/web/api/dvc-mgt/edm/kisk-insert");
                    console.log("전송된 aiLabSettings : ", aiLabParams);

                    alert("", t("alert.save"), () => { getKiskInfo() });

                } catch (error) {
                    console.log(error);
                    alert("", t("alert.saveError"), () => { return });
                }
                break;
            case "cmmnResetBtn":
                try {
                    modalConfirm("", t("alert.resetConfirm"), () => {
                        setAiLabSettings(kiskInfo.ailbStupCn);
                        alert("", t("alert.initSuccessAlert"), () => { });
                    })      
                } catch (error) {
                    console.log(error);
                    alert("", t("alert.initFailAlert"), () => { });
                }
                break;
            default: break;
        };
    };

    return (
        <>
            <div className="p-30">
                <TableWrap>
                    <h2>{t("aiLabBaseSetting.title")}</h2>
                    <InputTable>
                        <div className="input-table-wrap">
                            <InputTableHead>
                                <h3>{t("aiLabBaseSetting.lblUseYn")}</h3>
                            </InputTableHead>
                            <InputTableBody>
                                <div className="dp-flex align-cen w-100">
                                    <CmmnRadioBox
                                        dataArray={useYnDataArr}
                                        defaultVal={aiLabSettings?.useYn || "N"}
                                        tagName={"useYn"}
                                        onSelectChange={(e) => handleRadioChange(e.target.value)}
                                    />
                                </div>
                            </InputTableBody>
                        </div>
                    </InputTable>
                </TableWrap>
                <TableWrap>
                    <h2>{t("aiLabFaceBeautySetting.title")}</h2>
                    <InputTable className="mg-0">
                        <div className="input-table-wrap">
                            <InputTableHead>
                                <h3>{t("aiLabFaceBeautySetting.lblSharp")}</h3>
                            </InputTableHead>
                            <InputTableBody>
                                <SelectBox
                                    defaultOptTxt={t("saleHistPage.entire")}
                                    dataArray={parameterArray}
                                    onSelectChange={(e) => handleSelectChange(e, 'faceBeauty', 'sharp')}
                                    tagName="sharp"
                                    defaultVal={aiLabSettings?.faceBeauty?.sharp || 0}
                                />
                            </InputTableBody>
                        </div>
                        <div className="input-table-wrap">
                            <InputTableHead>
                                <h3>{t("aiLabFaceBeautySetting.lblSmooth")}</h3>
                            </InputTableHead>
                            <InputTableBody>
                                <SelectBox
                                    defaultOptTxt={t("saleHistPage.entire")}
                                    dataArray={parameterArray}
                                    onSelectChange={(e) => handleSelectChange(e, 'faceBeauty', 'smooth')}
                                    tagName="smooth"
                                    defaultVal={aiLabSettings?.faceBeauty?.smooth || 0}
                                />
                            </InputTableBody>
                        </div>
                        <div className="input-table-wrap">
                            <InputTableHead>
                                <h3>{t("aiLabFaceBeautySetting.lblWhite")}</h3>
                            </InputTableHead>
                            <InputTableBody>
                                <SelectBox
                                    defaultOptTxt={t("saleHistPage.entire")}
                                    dataArray={parameterArray}
                                    onSelectChange={(e) => handleSelectChange(e, 'faceBeauty', 'white')}
                                    tagName="white"
                                    defaultVal={aiLabSettings?.faceBeauty?.white || 0}
                                />
                            </InputTableBody>
                        </div>
                    </InputTable>
                </TableWrap>
            </div>
            <BtnWrap>
                <ButtonUtil className="btn btn-danger round-filled-small-btn"
                    value={t("common.lblInitBtn")}
                    onClick={() => { handleCmmnBtnClick("cmmnResetBtn") }}
                />
                <ButtonUtil className="btn btn-outline-light round-solid-medium-btn"
                    value={t("common.cancelLable")}
                    onClick={() => { onHide() }}
                />
                <ButtonUtil className="btn btn-danger round-filled-small-btn"
                    value={t("common.saveLable")}
                    onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                />
            </BtnWrap>
        </>
    )
}