'use client'
import titleLogo from "asset/mobile_web/img/icon/filled/monetization-on_b.svg";
import ExcelImg from "asset/mobile_web/img/icon/insert-chart-outlined.svg";
import ButtonUtil from "components/mobile/UI/ButtonUtil";
import CmmnGraphBox from "components/mobile/cmmn/CmmnGraphBox";
import moment from "moment";
import * as ExcelUtil from 'util/ExcelUtil';
import { useEffect, useState } from "react";
import styled from "styled-components"
import { callApi } from "util/HttpService";
import { getDateRangeForButton, getFormattedDate } from "util/DateSearchUtils";
import CmmnRadioBtn from "components/mobile/cmmn/CmmnRadioBtn";
import CustomDatePicker from "components/mobile/UI/DatePicker";
import EqmtSelectBox from "components/mobile/cmmn/EqmtSelectBox";
import EqmtByTypeChart from "pages/web/sal-hist/EqmtByTypeChart";
import EqmtHourlyChart from "pages/web/sal-hist/EqmtHourlyChart";
import EqmtAccuHourlyChart from "pages/web/sal-hist/EqmtAccuHourlyChart";
import EqmtDayMonthChart from "pages/web/sal-hist/EqmtDayMonthChart";
import CmmnMenuTitleWrap from "components/mobile/cmmn/CmmnMenuTitleWrap";
import { useWindowSize } from "context/WindowSizeContext";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useBrch } from "components/web/auth/BrchProvider";

const SearchWrap = styled.ul`
    li {
        &:not(.radio-btn-wrap) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;
            border-bottom: 1px solid ${({ theme }) => theme.colors.white1};
            h3 {
                font-size: 16px;
                color: ${({ theme }) => theme.colors.dark2};
                font-weight: 500;
                min-width: 80px;
            }
        }
        @media (max-width: 450px) {
            &:last-child {
                border-bottom: none;
            }
        &:nth-child(2) {
            flex-direction: column;
            align-items: flex-start;
                & > h3 {
                    margin-bottom: 10px;
                }
            }
        }
    }
`;
const DashboardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
    }
`;
const ExcelBoxWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100% !important;
`;
// mobile
export default function SalHist() {

    const { t } = useTranslation();
    const dayButtons = [
        { label: t("saleHistPage.today"), value: "saleHistPage.today" },
        { label: t("saleHistPage.yesterday"), value: "saleHistPage.yesterday" },
        { label: t("saleHistPage.thisWeek"), value: "saleHistPage.thisWeek" },
        { label: t("saleHistPage.lastWeek"), value: "saleHistPage.lastWeek" },
        { label: t("saleHistPage.lastWeekend"), value: "saleHistPage.lastWeekend" },
        { label: t("saleHistPage.thisMonth"), value: "saleHistPage.thisMonth" },
        { label: t("saleHistPage.lastMonth"), value: "saleHistPage.lastMonth" }
    ];
    const hourTab = [t("saleHistPage.time"), t("saleHistPage.timeAcc")];
    const paymentTypeTab = [t("saleHistPage.sale"), t("saleHistPage.numberOfOccur")];
    const frameTab = [t("saleHistPage.frame")];
    const colorTab = [t("saleHistPage.colorBackground")];
    const eventTab = [t("saleHistPage.eventBackground")]
    const dayMonthTab = [t("saleHistPage.daily"), t("saleHistPage.monthly")];

    const { brchId } = useBrch();
    const isMobile = useWindowSize();

    /* 시간별 매출 */
    const hourlyArray = () => {
        const hours = [];
        for (let i = 0; i < 24; i++) {
            const hour = i < 10 ? `0${i}` : `${i}`;
            hours.push(`${hour}`);
        }
        return hours;
    };
    /* 일월별 매출 */
    const dayArray = () => {
        const today = moment();
        const result = {
            thisMonth: [],
            lastMonth: []
        };
        // 오늘까지의 날짜 배열 생성
        const startOfMonth = today.clone().startOf('month');
        for (let i = 0; i < today.date(); i++) {
            const currentDate = startOfMonth.clone().date(i + 1);
            const formattedDate = currentDate.format('YYYY-MM-DD');
            result.thisMonth.push(formattedDate);
        }
        // 저번 달 날짜 배열 생성
        const lastMonth = today.clone().subtract(1, 'month');
        for (let i = 0; i < lastMonth.daysInMonth(); i++) {
            const currentDate = lastMonth.clone().date(i + 1);
            const formattedDate = currentDate.format('YYYY-MM-DD');
            result.lastMonth.push(formattedDate);
        }
        return result;
    };
    const monthArray = () => {
        const today = moment();
        const result = {
            thisYearMonth: [],
            lastYearMonth: [],
        };
        for (let i = 11; i >= 0; i--) {
            const currentMonth = today.clone().subtract(i, 'months').format('YYYYMM');
            result.thisYearMonth.push(currentMonth);
        }
        for (let i = 12; i >= 1; i--) {
            const lastMonth = today.clone().subtract(i, 'months').format('YYYYMM');
            result.lastYearMonth.push(lastMonth);
        }
        return result;
    };

    const defaultSearchData = {
        brchId: brchId,
        eqmtId: "",
        startDate: "",
        endDate: "",
        division: "HST",
    };

    const [searchData, setSearchData] = useState(defaultSearchData);

    const [sumInfo, setSumInfo] = useState({});
    const [totSalesInfo, setTotSalesInfo] = useState({});
    const [totCntInfo, setTotCntInfo] = useState({});
    const [totFremCntInfo, setTotFremCntInfo] = useState({});
    // const [totBgrdCntInfo, setTotBgrdCntInfo] = useState({});
    const [totColorBgCntInfo, setTotColorBgCntInfo] = useState({});
    const [totEventBgCntInfo, setTotEventBgCntInfo] = useState({});
    const [hourlySalesList, setHourlySalesList] = useState([]);
    const [dailySalesList, setDailySalesList] = useState([]);
    const [monthSalesList, setMonthSalesList] = useState([]);

    const [timeArray, setTimeArray] = useState([]);
    const [dayDateArray, setDayDateArray] = useState([]);
    const [monthDateArray, setMonthDateArray] = useState([]);
    const [radioValue, setRadioValue] = useState("saleHistPage.today");

    useEffect(() => {
        handleButtonClick(t("saleHistPage.today"));
    }, []);

    useEffect(() => {
		const handleLanguageChange = () => {
			setRadioValue(prevValue => prevValue); // radioValue를 키 값으로 유지
		};
		i18next.on('languageChanged', handleLanguageChange);
		return () => {
			i18next.off('languageChanged', handleLanguageChange);
		};
	}, []);

    const getSalesList = async () => {
        setHourlySalesList([]);
		setDailySalesList([]);
		setMonthSalesList([]);

        try {
            const now = moment();
            const startOfToday = now.clone().startOf('day').format("YYYY-MM-DD");
            const startOfYesterday = now.clone().subtract(1, 'day').startOf('day').format("YYYY-MM-DD");
            const startOfMonth = now.clone().startOf('month').startOf('day').format("YYYY-MM-DD");
            const endOfMonth = now.clone().endOf('month').startOf('day').format("YYYY-MM-DD");
            const startOfLastMonth = now.clone().subtract(1, 'month').startOf('month').startOf('day').format("YYYY-MM-DD");
            const endOfLastMonth = now.clone().subtract(1, 'month').endOf('month').startOf('day').format("YYYY-MM-DD");

            const dates = dayArray();
            const months = monthArray();
            const isThisMonth = searchData.startDate === startOfMonth && searchData.endDate === endOfMonth;
            const isLastMonth = searchData.startDate === startOfLastMonth && searchData.endDate === endOfLastMonth;

            const totalCountInfoPromise = callApi("POST", searchData, "/web/api/sal-hist/eph/total");
            const sumListPromise = callApi("POST", searchData, "/web/api/sal-hist/eph/sum");

            let hourlySalesPromise = Promise.resolve([]);
            const isToday = searchData.startDate === startOfToday;
            const isYesterday = searchData.startDate === startOfYesterday;

            if (isToday || isYesterday) {
                const hourList = hourlyArray();
                setTimeArray(hourList);
                const hourParams = { ...searchData, hourList: hourList, division: "HST" };
                hourlySalesPromise = callApi("POST", hourParams, "/web/api/sal-hist/eph/hour-sales");
            } else {
                setHourlySalesList([]);
            }

            let dailySalesPromise = Promise.resolve([]);
            let monthlySalesPromise = Promise.resolve([]);
            if (!isThisMonth && !isLastMonth) {
                setDailySalesList([]);
            } else if (isThisMonth || isLastMonth) {
                const dayList = isThisMonth ? dates.thisMonth : dates.lastMonth;
                setDayDateArray(dayList);
                const params = { ...searchData, dayList: dayList };
                dailySalesPromise = callApi("POST", params, "/web/api/sal-hist/eph/daily-sales");

                const monthList = isThisMonth ? months.thisYearMonth : months.lastYearMonth;
                setMonthDateArray(monthList);
                const monthParams = { ...searchData, monthList: monthList };
                monthlySalesPromise = callApi("POST", monthParams, "/web/api/sal-hist/eph/month-sales");
            }

            const [totalCountInfo, hourlySales, sumList, dailySales, monthSales] = await Promise.all([
                totalCountInfoPromise,
                hourlySalesPromise,
                sumListPromise,
                dailySalesPromise,
                monthlySalesPromise
            ]);
            // 시간별 매출 - 배열안의 값이 0인 배열은 빈 배열로 처리
            hourlySales.forEach((data, index) => {
                if (data.every(value => value === 0)) {
                    hourlySales[index] = [];
                }
            });

            setSumInfo(sumList);
            setTotSalesInfo(totalCountInfo.stlmTotalSales);
            setTotCntInfo(totalCountInfo.stlmTotalCnt);
            setTotFremCntInfo(totalCountInfo.fremTotalCnt);
            // setTotBgrdCntInfo(totalCountInfo.bgrdTotalCnt);
            setTotColorBgCntInfo(totalCountInfo.colorBgTotalCnt);
            setTotEventBgCntInfo(totalCountInfo.eventBgTotalCnt);
            setHourlySalesList(hourlySales);

            if (dailySales.length) {
                setDailySalesList(dailySales);
            }
            if (dailySales.length) {
                setMonthSalesList(monthSales);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }

    /* 기간 - 버튼을 클릭할 때 조회 */
    const handleButtonClick = async (btnNm) => {
        let { startDate, endDate } = getDateRangeForButton(btnNm, t);
        setSearchData((prevData) => ({
            ...prevData,
            startDate: startDate,
            endDate: endDate,
        }));

        let params = { ...searchData, startDate: startDate, endDate: endDate };
        let totalCountInfoPromise, hourlySalesPromise, sumListPromise, dailySalesPromise, monthlySalesPromise;

        const now = moment();
        const startOfToday = now.clone().startOf('day').format("YYYY-MM-DD");
        const startOfYesterday = now.clone().subtract(1, 'day').startOf('day').format("YYYY-MM-DD");
        const startOfMonth = now.clone().startOf('month').startOf('day').format("YYYY-MM-DD");
        const endOfMonth = now.clone().endOf('month').startOf('day').format("YYYY-MM-DD");
        const startOfLastMonth = now.clone().subtract(1, 'month').startOf('month').startOf('day').format("YYYY-MM-DD");
        const endOfLastMonth = now.clone().subtract(1, 'month').endOf('month').startOf('day').format("YYYY-MM-DD");
        const dates = dayArray();
        const months = monthArray();

        switch (btnNm) {
            case t("saleHistPage.today"):
                console.log(`${btnNm} searchData`, params);

                const isToday = params.startDate === startOfToday;

                if (!isToday) {
                    setHourlySalesList([]);
                    hourlySalesPromise = Promise.resolve([]);
                } else {
                    const hourList = hourlyArray();
                    setTimeArray(hourList);
                    const hourParams = { ...params, hourList: hourList, division: "HST" };
                    // console.log(hourParams);
                    hourlySalesPromise = callApi("POST", hourParams, "/web/api/sal-hist/eph/hour-sales");
                }
                break;

            case t("saleHistPage.yesterday"):
                console.log(`${btnNm} searchData`, params);

                const isYesterday = params.startDate === startOfYesterday;

                if (!isYesterday) {
                    setHourlySalesList([]);
                    hourlySalesPromise = Promise.resolve([]);
                } else {
                    const hourList = hourlyArray();
                    setTimeArray(hourList);
                    const hourParams = { ...params, hourList: hourList, division: "HST" };
                    hourlySalesPromise = callApi("POST", hourParams, "/web/api/sal-hist/eph/hour-sales");
                }
                break;
            case t("saleHistPage.thisWeek"):
            case t("saleHistPage.lastWeek"):
            case t("saleHistPage.lastWeekend"):
                console.log(`${btnNm} searchData`, params);
                break;

            case t("saleHistPage.thisMonth"):
            case t("saleHistPage.lastMonth"):
                console.log(`${btnNm} searchData`, params);

                const isThisMonth = params.startDate === startOfMonth && params.endDate === endOfMonth;
                const isLastMonth = params.startDate === startOfLastMonth && params.endDate === endOfLastMonth;

                if (!isThisMonth && !isLastMonth) {
                    setDailySalesList([]);
                    setMonthSalesList([]);
                    dailySalesPromise = Promise.resolve([]);
                    monthlySalesPromise = Promise.resolve([]);
                } else {
                    const dayList = isThisMonth ? dates.thisMonth : dates.lastMonth;
                    setDayDateArray(dayList);
                    const newParams = { ...params, dayList: dayList };
                    dailySalesPromise = callApi("POST", newParams, "/web/api/sal-hist/eph/daily-sales");

                    const monthList = isThisMonth ? months.thisYearMonth : months.lastYearMonth;
                    setMonthDateArray(monthList);
                    const monthParams = { ...searchData, monthList: monthList };
                    monthlySalesPromise = callApi("POST", monthParams, "/web/api/sal-hist/eph/month-sales");
                }
                break;

            default: break;
        }

        totalCountInfoPromise = callApi("POST", params, "/web/api/sal-hist/eph/total");
        sumListPromise = callApi("POST", params, "/web/api/sal-hist/eph/sum");

        try {
            const [totalCountInfo, hourlySales, sumList, dailySales, monthSales] = await Promise.all([
                totalCountInfoPromise,
                hourlySalesPromise || Promise.resolve([]),
                sumListPromise,
                dailySalesPromise || Promise.resolve([]),
                monthlySalesPromise || Promise.resolve([]),
            ]);

            // 시간별 매출 - 배열안의 값이 0인 배열은 빈 배열로 처리
            hourlySales.forEach((data, index) => {
                if (data.every(value => value === 0)) {
                    hourlySales[index] = [];
                }
            });

            setTotSalesInfo(totalCountInfo.stlmTotalSales);
            setTotCntInfo(totalCountInfo.stlmTotalCnt);
            setTotFremCntInfo(totalCountInfo.fremTotalCnt);
            // setTotBgrdCntInfo(totalCountInfo.bgrdTotalCnt);
            setTotColorBgCntInfo(totalCountInfo.colorBgTotalCnt);
            setTotEventBgCntInfo(totalCountInfo.eventBgTotalCnt);
            setHourlySalesList(hourlySales);
            setSumInfo(sumList);

            if (dailySales.length) {
				const updatedDailySales = dailySales.map(data => data === null ? 0 : data);
				setDailySalesList(updatedDailySales);
            } else {
                setDailySalesList([]);
            }
            if (monthSales.length) {
                const updatedMonthSales = monthSales.map(data => data === null ? 0 : data);
				setMonthSalesList(updatedMonthSales);
            } else {
                setMonthSalesList([]);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handelDatePickerChange = (date, name) => {
        setSearchData((prevData) => ({
            ...prevData,
            [name]: getFormattedDate(date, "yyyy-MM-dd"),
        }));
        setRadioValue('');
    };

	const handleButtonClickWrapper = (value) => {
		handleButtonClick(t(value));
		setRadioValue(value);
	};

    const handelFormDataChange = (e) => {
        const { name, value } = e.target;
        setSearchData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const excelDownloadBtn = async () => {
        ExcelUtil.downloadExcelFromServer(`/web/api/sal-hist/eph/excel-down`, searchData, t("m_saleHistPage.saleStat"));
    }

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnSearchBtn":
                getSalesList();
                break;
            case "cmmnExcelDownloadBtn":
                excelDownloadBtn();
                break;
            default: break;
        }
    }

    const salesData = [
        { label: "CARD", amount: totSalesInfo.cardAmt, ratio: totSalesInfo.cardRto, backgroundColor: "#e50200" },
        { label: "CASH", amount: totSalesInfo.cashAmt, ratio: totSalesInfo.cashRto, backgroundColor: "#fcc355" },
        { label: "CUPON", amount: totSalesInfo.cupnAmt, ratio: totSalesInfo.cupnRto, backgroundColor: "#37d1cc" },
        { label: "POINT", amount: totSalesInfo.pontAmt, ratio: totSalesInfo.pontRto, backgroundColor: "#1a89e3" },
        { label: "FREE", amount: totSalesInfo.freeAmt, ratio: totSalesInfo.freeRto, backgroundColor: "#a231d5" },
        { label: "COIN", amount: totSalesInfo.evciAmt, ratio: totSalesInfo.evciRto, backgroundColor: "#ff6347" },
		{ label: "CASH+COIN", amount: totSalesInfo.csecAmt, ratio: totSalesInfo.csecRto, backgroundColor: "#32cd32" },
    ];
    const salesCntData = [
        { label: "CARD", amount: totCntInfo.cardCnt, ratio: totCntInfo.cardRto, backgroundColor: "#e50200" },
        { label: "CASH", amount: totCntInfo.cashCnt, ratio: totCntInfo.cashRto, backgroundColor: "#fcc355" },
        { label: "CUPON", amount: totCntInfo.cupnCnt, ratio: totCntInfo.cupnRto, backgroundColor: "#37d1cc" },
        { label: "POINT", amount: totCntInfo.pontCnt, ratio: totCntInfo.pontRto, backgroundColor: "#1a89e3" },
        { label: "FREE", amount: totCntInfo.freeCnt, ratio: totCntInfo.freeRto, backgroundColor: "#a231d5" },
        { label: "COIN", amount: totCntInfo.evciCnt, ratio: totCntInfo.evciRto, backgroundColor: "#ff6347" },
		{ label: "CASH+COIN", amount: totCntInfo.csecCnt, ratio: totCntInfo.csecRto, backgroundColor: "#32cd32" },
    ];
    const frmeData = [
        { label: "CBH4C", amount: totFremCntInfo.cbh4cCnt, ratio: totFremCntInfo.cbh4cRto, backgroundColor: "#e50200" },
        { label: "CSH6C", amount: totFremCntInfo.csh6cCnt, ratio: totFremCntInfo.csh6cRto, backgroundColor: "#fcc355" },
        { label: "HSH4C", amount: totFremCntInfo.hsh4cCnt, ratio: totFremCntInfo.hsh4cRto, backgroundColor: "#37d1cc" },
        { label: "HSW5C", amount: totFremCntInfo.hsw5cCnt, ratio: totFremCntInfo.hsw5cRto, backgroundColor: "#1a89e3" },
        { label: "SSH4C", amount: totFremCntInfo.ssh4cCnt, ratio: totFremCntInfo.ssh4cRto, backgroundColor: "#9A2EFE" },
        { label: "SSW4C", amount: totFremCntInfo.ssw4cCnt, ratio: totFremCntInfo.ssw4cRto, backgroundColor: "#3A01DF" },
    ];
    const colorBgData = [
        { label: "BLACK", amount: totColorBgCntInfo.cblackCnt, ratio: totColorBgCntInfo.cblackRto, backgroundColor: "#e50200" },
        { label: "WHITE", amount: totColorBgCntInfo.cwhiteCnt, ratio: totColorBgCntInfo.cwhiteRto, backgroundColor: "#fcc355" },
        { label: "GRAY", amount: totColorBgCntInfo.cgrayCnt, ratio: totColorBgCntInfo.cgrayRto, backgroundColor: "#37d1cc" },
        { label: "BLUE", amount: totColorBgCntInfo.cblueCnt, ratio: totColorBgCntInfo.cblueRto, backgroundColor: "#1a89e3" },
        { label: "GREEN", amount: totColorBgCntInfo.cgreenCnt, ratio: totColorBgCntInfo.cgreenRto, backgroundColor: "#8B4513" },
        { label: "PURPLE", amount: totColorBgCntInfo.cpurpleCnt, ratio: totColorBgCntInfo.cpurpleRto, backgroundColor: "#FFA500" },
        { label: "PURPLEMIX", amount: totColorBgCntInfo.cpurplemCnt, ratio: totColorBgCntInfo.cpurplemRto, backgroundColor: "#FFDEAD" },
        { label: "PURPLEFOIL", amount: totColorBgCntInfo.cpurplefCnt, ratio: totColorBgCntInfo.cpurplefRto, backgroundColor: "#7CFC00" },
        { label: "GREENMIX", amount: totColorBgCntInfo.cgreenmCnt, ratio: totColorBgCntInfo.cgreenmRto, backgroundColor: "#6B8E23" },
    ];
    const eventBgData = [
        { label: "MAGAZINE", amount: totEventBgCntInfo.ecmagzeCnt, ratio: totEventBgCntInfo.ecmagzeRto, backgroundColor: "#66CDAA" },
        { label: "RAIN", amount: totEventBgCntInfo.esrainCnt, ratio: totEventBgCntInfo.esrainRto, backgroundColor: "#B0C4DE" },
        { label: "ELEVATOR", amount: totEventBgCntInfo.ehelvtrCnt, ratio: totEventBgCntInfo.ehelvtrRto, backgroundColor: "#EE82EE" },
        { label: "SEASON", amount: totEventBgCntInfo.ecsesnCnt, ratio: totEventBgCntInfo.ecsesnRto, backgroundColor: "#FFD700" },
        { label: "TEEN1", amount: totEventBgCntInfo.ecteen1Cnt, ratio: totEventBgCntInfo.ecteen1Rto, backgroundColor: "#FFEBCD" },
        { label: "TEEN2", amount: totEventBgCntInfo.ecteen2Cnt, ratio: totEventBgCntInfo.ecteen2Rto, backgroundColor: "#ff1493" },
        { label: "LOCAL", amount: totEventBgCntInfo.eclocalCnt, ratio: totEventBgCntInfo.eclocalRto, backgroundColor: "#00ced1" },
    ];

    const paymentTypeTabs = [
        { tabTitle: t("saleHistPage.sale"), graphComponent: <EqmtByTypeChart salesData={salesData} unit="¥" label={t("saleHistPage.sale")} /> },
        { tabTitle: t("saleHistPage.numberOfOccur"), graphComponent: <EqmtByTypeChart salesData={salesCntData} text={t("m_dashboardPage.occur")} label={t("saleHistPage.payment")} /> }
    ];
    const fremTypeTabs = [
        { tabTitle: t("saleHistPage.frame"), graphComponent: <EqmtByTypeChart salesData={frmeData} text={t("m_dashboardPage.occur")} label={t("m_common.selectLable")} /> }
    ]
    const colorBgTabs = [
        { tabTitle: t("saleHistPage.colorBackground"), graphComponent: <EqmtByTypeChart salesData={colorBgData} text={t("m_dashboardPage.occur")} label={t("m_common.selectLable")} /> }
    ]
    const eventBgTabs = [
        { tabTitle: t("saleHistPage.eventBackground"), graphComponent: <EqmtByTypeChart salesData={eventBgData} text={t("m_dashboardPage.occur")} label={t("m_common.selectLable")} /> }
    ]
    const hourlyTypeTabs = [
        { tabTitle: t("saleHistPage.time"), graphComponent: <EqmtHourlyChart salesData={hourlySalesList} dateArray={timeArray} searchData={searchData} /> },
        { tabTitle: t("saleHistPage.hourlyAccuSale"), graphComponent: <EqmtAccuHourlyChart salesData={hourlySalesList} dateArray={timeArray} searchData={searchData} /> }
    ]
    const dayMonthTabs = [
        { tabTitle: t("saleHistPage.daily"), graphComponent: <EqmtDayMonthChart salesData={dailySalesList} dateArray={dayDateArray} title={t("saleHistPage.daily")} label={t("saleHistPage.sale")} /> },
        { tabTitle: t("saleHistPage.monthly"), graphComponent: <EqmtDayMonthChart salesData={monthSalesList} dateArray={monthDateArray} title={t("saleHistPage.monthly")} label={t("saleHistPage.sale")} /> }
    ]

    return (
        <section>
            <div className="title-wrap">
                <div className="title">
                    <img src={titleLogo} alt={t("m_saleHistPage.saleStat")} />
                    <h2>{t("m_saleHistPage.saleStat")}</h2>
                </div>
                <SearchWrap>
                    <li>
                        <h3>{t("saleHistPage.eqmtNm")}</h3>
                        <EqmtSelectBox
                            defaultVal={searchData.eqmtId}
                            tagName={"eqmtId"}
                            defaultOptTxt={t("m_common.selectLable")}
                            brchId={searchData.brchId}
                            onSelectChange={handelFormDataChange}
                        />
                    </li>
                    <li>
                        <h3>{t("m_common.periodLable")}</h3>
                        <CustomDatePicker
                            defaultStartDate={searchData.startDate}
                            defaultEndDate={searchData.endDate}
                            onSelectChange={handelDatePickerChange}
                            isMobile={isMobile}
                        />
                    </li>
                    <CmmnRadioBtn
                        dataArray={dayButtons.map(button => ({ ...button, label: t(button.value) }))}
                        onSelectChange={(e) => { handleButtonClickWrapper(e.target.value); }}
                        className="radio-btn-wrap"
                        defaultVal={radioValue}
                        isMobile={isMobile}
                    />
                    <ButtonUtil
                        value={t("m_common.searchLable")}
                        className="btn btn-danger round-filled-small-btn"
                        name="cmmnSearchBtn"
                        onClick={() => { handleCmmnBtnClick("cmmnSearchBtn") }}
                    />
                </SearchWrap>
            </div>
            <DashboardWrap>
                <CmmnMenuTitleWrap
                    title={t("saleHistPage.sale")}
                    searchData={searchData}
                    dataInfo={sumInfo}
                    isMobile={isMobile}
                />
                <ExcelBoxWrap>
                    <ButtonUtil
                        className="btn btn-success round-filled-small-green-btn excel-mb-btn"
                        name="cmmnExcelDownloadBtn"
                        onClick={() => { handleCmmnBtnClick("cmmnExcelDownloadBtn") }}
                    >
                        <img src={ExcelImg} alt={t("saleHistPage.excelDownload")} />
                        {t("saleHistPage.excelDownload")}
                    </ButtonUtil>
                </ExcelBoxWrap>
                <CmmnGraphBox
                    title={t("saleHistPage.paymentType")}
                    tabs={paymentTypeTab}
                    graphComponents={paymentTypeTabs.map(tab => tab.graphComponent)}
                />
                <CmmnGraphBox
                    title={t("saleHistPage.frameSelectStat")}
                    tabs={frameTab}
                    graphComponents={fremTypeTabs.map(tab => tab.graphComponent)}
                />
                <CmmnGraphBox
                    title={t("m_saleHistPage.colorBackgroundSelectStat")}
                    tabs={colorTab}
                    graphComponents={colorBgTabs.map(tab => tab.graphComponent)}
                />
                <CmmnGraphBox
                    title={t("m_saleHistPage.eventBackgroundSelectStat")}
                    tabs={eventTab}
                    graphComponents={eventBgTabs.map(tab => tab.graphComponent)}
                />
                {hourlySalesList.every(data => data.length === 0) ?
                    <></>
                    :
                    <CmmnGraphBox
                        title={t("saleHistPage.salesByHour")}
                        tabs={hourTab}
                        graphComponents={hourlyTypeTabs.map(tab => tab.graphComponent)}
                    />
                }
                {(dailySalesList.every(data => data === 0) && monthSalesList.every(data => data === 0)) ?
                    <></>
                    :
                    <CmmnGraphBox
                        title={t("saleHistPage.dailyMonthlySale")}
                        tabs={dayMonthTab}
                        graphComponents={dayMonthTabs.map(tab => tab.graphComponent)}
                    />
                }
            </DashboardWrap>
        </section>
    )
}