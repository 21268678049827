import styled from "styled-components";
import { useEffect, useState } from 'react';
// import deselectedIcon from "../../assets/images/check-box.svg";
// import selectedIcon from "../../assets/images/check-box-selected.svg";
// import disabledIcon from "../../assets/images/check-box-disabled.svg";

const CheckBoxStyle = styled.div`
        display: inline-block;
        input {
            display: none;
            &:checked {
                & + label {
                    background-image: url(/assets/images/check-box-selected.svg);
                }
            }
            &:disabled {
                & + label {
                    cursor: default;
                    background-image: url(/assets/images/check-box-disabled.svg);
                }
            }
        }
        label {
            display: inline-block;
            cursor: pointer;
            height: 2.2rem;
            line-height: 2.2rem;
            padding-left: ${props =>(props.label ? "2.8rem" : "2rem")};        
            background-image: url(/assets/images/check-box.svg);
            background-repeat: no-repeat;
            background-position: left center;
            color: ${({ theme }) => theme.colors.gray1};
            text-align: left;
        }
    `;

function DefaultAthrCheckbox({ id, name, tagName, value, label, defaultChecked, disabled, onChange, onClick , onSelectChange }) {

    const handleSelectChange = (e) => {
        console.log(e.target.checked);
        console.log(e.target);
        var target;
        if(e.target.checked){
            target = {name:tagName,value:"ADMN"};
        } else {
            target = {name:tagName,value:""};
        }
        const event = {target:target};
        
        onSelectChange(event);
    }

    return (
        <>
            <input
                type="checkbox"
                id={id}
                name={name}
                value={value}
                defaultChecked={defaultChecked}
                disabled={disabled}
                onChange={onChange}
                onClick={(e) => {handleSelectChange(e)}}

            />
            <label htmlFor={id}>{label}</label>
        </>
    );
}
export default DefaultAthrCheckbox;
