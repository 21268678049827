var nextDate;
var startDate;
var endDate;

// YYYY-MM-DD 00:00:00
export const todayDate = (date) => {
    const now = new Date(date);
    nextDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()+1);
    startDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2,'0')}-${String(now.getDate()).padStart(2,'0')} 00:00:00`;
    endDate = `${nextDate.getFullYear()}-${String(nextDate.getMonth()+1).padStart(2,'0')}-${String(nextDate.getDate()).padStart(2,'0')} 00:00:00`;

    const result = [startDate,endDate];
    return result;
}

// YYYY-MM-DD 00:00:00
export const nowMonth = (date) => {
    const now = new Date(date);
    nextDate = new Date(now.getFullYear(), now.getMonth()+1);
    startDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-01 00:00:00`;
    endDate = `${nextDate.getFullYear()}-${String(nextDate.getMonth()+1).padStart(2, '0')}-01 00:00:00`;

    return [startDate,endDate];
}

// YYYY-MM-DD 00:00:00
export const prvMonth = (date) => {
    const now = new Date(date);
    const firstDay = new Date(now.getFullYear(), now.getMonth()-1);
    const lastDay = new Date(now.getFullYear(), now.getMonth()); 
    startDate = `${firstDay.getFullYear()}-${String(firstDay.getMonth()+1).padStart(2,'0')}-01 00:00:00`;
    endDate = `${lastDay.getFullYear()}-${String(lastDay.getMonth()+1).padStart(2, '0')}-01 00:00:00`;
    
    return [startDate,endDate];
}

// YYYYMM
export const thisYear = (date) => {
    const now = new Date(date);
    startDate = `${now.getFullYear()}01`;
    endDate = `${now.getFullYear()+1}01`;

    return [startDate,endDate];

}

export const nextDay = () => {
    
}