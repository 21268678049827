import styled from "styled-components";

const TextField = styled.div`
    display: flex;
    align-items: center;
    input {
        padding: 10px;
        font-size: 16px;
        color: #3b3b3b;
        border: 1px solid #D3D3D3;
        border-radius: 4px;
        height: 35px;
    }
    .readonly {
        background-color: var(--bs-secondary-bg);
    }
    input::placeholder {
        color: #868e96;
    }
    .w-100 {
        width: 100%;
    }
`;
const ErrorMsg = styled.p`
    margin-left: 1rem;
    font-size: 0.9rem;
    color: #ef2929;
`;
function TextFields({
    type,
    className,
    name,
    value,
    placeholder,
    readonly,
    disabled,
    errorTxt,
    onChangeValue,
    onKeyDown,
    autoComplete,
    id,
    step,
    min,
    pattern,
}) {
    let classNames = [];
    if (className) {
        classNames = classNames.concat(className.split(" "));
    }
    if (errorTxt) {
        classNames.push("error");
    }
    const combinedClassName = classNames.join(" ");

    return (
        <>
            <TextField className={combinedClassName}>
                <input
                    type={type}
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    readOnly={readonly}
                    disabled={disabled}
                    onChange={onChangeValue}
                    className={className}
                    onKeyDown={onKeyDown}
                    autoComplete={autoComplete}
                    id={id}
                    step={step}
                    min={min}
                    pattern={pattern}
                />
            </TextField>
            {errorTxt && <ErrorMsg>{errorTxt}</ErrorMsg>}
        </>
    )
}
export default TextFields;