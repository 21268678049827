'use client'
import TextFields from "components/web/UI/TextFields"
import { alert } from "components/web/cmmn/CmmnConfirm"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { callApi } from "util/HttpService"
import TitleLogo from "asset/photomag_web/img/icon/filled/insert-emoticon.svg";
import { useTranslation } from "react-i18next";
import ButtonUtil from "components/mobile/UI/ButtonUtil"

const DashBoardWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 0 0;
    .dashboard-box {
        background-color: #fff;
        border-radius: 16px;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
    }
    .round-filled-small-btn {
        background-color: ${({ theme }) => theme.colors.red} !important;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
    input[type="checkbox"] {
        accent-color: #EF2929;
    }
    @media (max-width: 1600px) {
        .th, .tb {
            font-size: 14px !important;
        }
    }
    @media (min-width: 1601px) {
        .th, .tb {
            font-size: 16px !important;
        }
    }
`;
const InputOptionWrap = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    .wrap {
        display: flex;
        align-items: center;
        li {
            display: flex;
            align-items: center;
            h3 {
                font-size: 16px;
                color: #3b3b3b;
                font-weight: 500;
                min-width: 100px;
            }
            label {
                font-size: 16px;
                color: #3b3b3b;
                font-weight: 400;
                margin-left: 10px;
            }
        }
    }
    & > ul {
        width: fit-content;
        display: flex;
        align-items: center;
    }
    .btn {
        max-width: 150px;
        &:hover {
            color: var(--bs-btn-hover-color) !important;
            background-color: var(--bs-btn-hover-bg) !important;
            border-color: var(--bs-btn-hover-border-color) !important;
        }
    }
`;
const TableTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
    }
    .table-title-btn-wrap {
        display: flex;
        align-items: center;
        .btn {
            width: 60px !important;
            &:first-child {
                margin-right: 10px;
            }
        }
    }
`;
const TableWrap = styled.div`
    h2 {
        font-size: 18px;
        color: #3b3b3b;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .round-filled-gray-btn:hover {
        color: var(--bs-btn-hover-color) !important;
    }
    .round-filled-gray-btn {
        font-size: 14px !important;
    }
    .tbody:hover .tb {
        background-color: #fafafa !important;
    }
    .tbody:active .tb, .tbody:focus .tb {
        background-color: #f5f5f5 !important;
    }
    .th {
        padding: 15px 0px !important;
    }
    .tb {
        padding: 15px 0px !important;           
    }
`;
export default function EqmtMgt() {

    const { t } = useTranslation();
    const defaultSearchData = {
        eqmtNo: '',
        eqmtNm: '',
    };

    const [searchData, setSearchData] = useState(defaultSearchData);
    const [eqmtList, setEqmtList] = useState([]);
    const [selectedEqmtNo, setSelectedEqmtNo] = useState([]);
    const navigate = useNavigate();

    const getEqmtList = () => {
        callApi("GET", searchData, "/web/api/svc-mgt/ei/list")
            .then(res => {
                // console.log(res);
                setEqmtList(res);
            })
            .catch(error => {
                console.log(error);
            })
    }
    useEffect(() => {
        getEqmtList();
    }, []);

    const handleFormDataChange = (event) => {
        const { name, value } = event.target;
        setSearchData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleCmmnBtnClick = (btnNm) => {
        switch (btnNm) {
            case "cmmnSearchBtn":
                getEqmtList();
                break;
            case "cmmnInsertBtn":
                navigate(`/svc-mgt/eqmt-mgt/0`);
                break;
            case "cmmnDeleteBtn":
                if (selectedEqmtNo.length > 0) {
                    const eqmtNoList = selectedEqmtNo.join(',');
                    callApi("GET", { eqmtNo: eqmtNoList }, "/web/api/svc-mgt/ei/delete")
                        .then(res => {
                            alert("", t("alert.delete"), () => { 
                                getEqmtList(); 
                                setSelectedEqmtNo([]); 
                            });
                        })
                        .catch(error => {
                            console.log(error);
                        });
                } else {
                    alert("", t("alert.deleteNumCheck"), () => { });
                    return;
                }
                break;
            default: break;
        }
    }

    const onCheckedElement = useCallback((checked, eqmtNo) => {
        if (checked) {
            setSelectedEqmtNo(prevData => [...prevData, eqmtNo]);
        } else {
            setSelectedEqmtNo(prevData => prevData.filter((no) => no !== eqmtNo));
        }
    }, []);
    const onRowDoubleClicked = (eqmtNo, brchIdProps) => {
        navigate(`/svc-mgt/eqmt-mgt/${eqmtNo}`, { state: { brchIdProps } })
    }

    return (
        <div className="padding-box">
            <div className="title-wrap">
                <div className="title space-between">
                    <div className="dp-flex align-cen">
                        <img src={TitleLogo} alt={t("leftMenu.eqmtMgt")} />
                        <h2>{t("leftMenu.eqmtMgt")}</h2>
                    </div>
                    <p>Home {">"} {t("leftMenu.svcMng")} {">"} {t("leftMenu.eqmtMgt")}</p>
                </div>
            </div>
            <DashBoardWrap>
                <div className="dashboard-box p-30">
                    <InputOptionWrap>
                        <ul>
                            <ul className="wrap">
                                <li className="mr-50">
                                    <h3>{t("eqmtMgtPage.eqmtNo")}</h3>
                                    <TextFields
                                        type="text"
                                        value={searchData.eqmtNo}
                                        name="eqmtNo"
                                        onChangeValue={handleFormDataChange}
                                    />
                                </li>
                                <li>
                                    <h3>{t("eqmtMgtPage.eqmtNm")}</h3>
                                    <TextFields
                                        type="text"
                                        value={searchData.eqmtNm}
                                        name="eqmtNm"
                                        onChangeValue={handleFormDataChange}
                                    />
                                </li>
                            </ul>
                        </ul>
                        <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn"
                            value={t("common.searchLable")}
                            onClick={() => { handleCmmnBtnClick("cmmnSearchBtn") }}
                        />
                    </InputOptionWrap>
                </div>
                <div className="dashboard-box">
                    <TableTitle>
                        <h2>{t("brchMgtPage.total")} {eqmtList.length}{t("dashboardPage.occur")}</h2>
                        <div className="table-title-btn-wrap">
                            <ButtonUtil className="btn btn-outline-danger round-solid-small-red-btn small-btn"
                                value={t("common.regLable")}
                                onClick={() => { handleCmmnBtnClick("cmmnInsertBtn") }}
                            />
                            <ButtonUtil className="btn btn-danger round-filled-small-btn small-btn mr-5"
                                value={t("common.delLable")}
                                onClick={() => { handleCmmnBtnClick("cmmnDeleteBtn") }}
                            />
                        </div>
                    </TableTitle>
                    <div className="p-30">
                        <TableWrap>
                            <div className="table scroll-wrap h-10">
                                <div className="thead">
                                    <div className="th w-05"></div>
                                    <div className="th w-05">{t("userMgtPage.index")}</div>
                                    <div className="th w-2">{t("userMgtPage.brch")}</div>
                                    <div className="th w-2">{t("eqmtMgtPage.eqmtNm")}</div>
                                    <div className="th w-15">{t("eqmtMgtPage.eqmtNo")}</div>
                                    <div className="th w-2">{t("eqmtMgtPage.eqmtMacAddr")}</div>
                                    <div className="th w-1">{t("userMgtPage.regUserNm")}</div>
                                    <div className="th w-2">{t("userMgtPage.regDttm")}</div>
                                </div>
                                <div className="tbody-wrap">
                                    {eqmtList.map((data, idx) => (
                                        <div className="tbody" key={data.eqmtNo} onDoubleClick={() => { onRowDoubleClicked(data.eqmtNo, data.brchId) }}>
                                            <div className="tb w-05">
                                                <input type="checkbox"
                                                    name="eqmtNo"
                                                    value={data.eqmtNo}
                                                    onChange={(e) => onCheckedElement(e.target.checked, data.eqmtNo)}
                                                    checked={selectedEqmtNo.includes(data.eqmtNo) ? true : false}
                                                />
                                            </div>
                                            <div className="tb w-05">{idx + 1}</div>
                                            <div className="tb w-2">{data.brchNm}</div>
                                            <div className="tb w-2">{data.eqmtNm}</div>
                                            <div className="tb w-15">{data.eqmtNo}</div>
                                            <div className="tb w-2">{data.eqmtMacAddr}</div>
                                            <div className="tb w-1">{data.regUserNm}</div>
                                            <div className="tb w-2">{data.convRegDttm}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </TableWrap>
                    </div>
                </div>
            </DashBoardWrap>
        </div>
    )
}