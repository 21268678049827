import styled from "styled-components"

const Btn = styled.button`
    &.round-filled-btn {
        font-size: 16px;
        color: #fff;
        border-radius: 100px;
        background-color: ${({ theme }) => theme.colors.red};
        width: 100%;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.round-filled-small-btn {
        font-size: 14px;
        color: #fff;
        border-radius: 100px;
        background-color: ${({ theme }) => theme.colors.red};
        width: 100%;
        min-width: fit-content;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.round-filled-small-gray-btn {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.gray6};
        border-radius: 100px;
        background-color: ${({ theme }) => theme.colors.gray5} !important;
        width: 100%;
        min-width: fit-content;
        padding: 10px 20px;
        border-color: ${({ theme }) => theme.colors.gray5} !important;
        display: flex;
        align-items: center;
        justify-content: center;
    } 
    &.round-filled-gray-btn {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.gray6};
        border-radius: 100px;
        background-color: ${({ theme }) => theme.colors.gray5} !important;
        width: 100%;
        padding: 15px 20px;
        border-color: ${({ theme }) => theme.colors.gray5} !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.round-solid-medium-btn{
        font-size: 14px;
        color: #3b3b3b;
        border-radius: 100px;
        border: 1px solid ${({ theme }) => theme.colors.gray6};
        width: 100%;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.round-solid-medium-orange-btn{
        font-size: 14px;
        color: #fff;
        border-radius: 100px;
        background-color: ${({ theme }) => theme.colors.orange};
        width: 100%;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.round-solid-small-btn{
        font-size: 14px;
        // color: ${({ theme }) => theme.colors.gray6};
        border-radius: 100px;
        // border: 1px solid ${({ theme }) => theme.colors.gray6};
        width: 100%;
        padding: 7px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.round-solid-small-yellow-btn{
        font-size: 14px;
        color: ${({ theme }) => theme.colors.dark};
        border-radius: 100px;
        border: 1px solid ${({ theme }) => theme.colors.yellow};
        width: 100%;
        padding: 7px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.round-solid-small-mb-btn {
        font-size: 14px;
        color: #fff;
        border-radius: 100px;
        border: 1px solid ${({ theme }) => theme.colors.gray6};
        width: 100%;
        padding: 7px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.round-solid-small-red-btn{
        font-size: 14px;
        // color: ${({ theme }) => theme.colors.red};
        border-radius: 100px;
        border: 1px solid ${({ theme }) => theme.colors.red};
        // width: 100%;
        // padding: 7px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.round-filled-small-green-btn {
        font-size: 14px;
        color: #fff;
        border-radius: 100px;
        border-color: #00D45E;
        background-color: #00D45E;
        width: 100%;
        padding: 7px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.excel-btn {
        width: fit-content;
    }
    &.excel-mb-btn {
        width: fit-content;
        margin-bottom: 20px;
    }
    &.btn img {
        margin-right: 10px;
        width: 18px;
    }
    &.max-content{
        width: max-content !important;
    }
    &.small-btn{
        padding: 5px 10px;
    }
`;

function ButtonUtil({ type, value, children, className, name, disabled, login, onClick }) {

    const onCmmnBtnClick = (e) => {
        // console.log(e);
        const btnNm = e.target.name;
        // console.log(btnNm);
        onClick(btnNm);
    };
    return (
        <Btn
            className={`${className}`}
            type={type}
            name={name}
            disabled={disabled}
            onClick={(e) => { login == 'Y' ? onClick(e) : onCmmnBtnClick(e); }}
        >
            {children || value}
        </Btn>
    );

}
export default ButtonUtil;