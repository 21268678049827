'use client'

import { Link } from "react-router-dom";
import styled from "styled-components";
import dshbrdLogo from "asset/mobile_web/img/icon/filled/gray/icon1_g.svg";
import salesLogo from "asset/mobile_web/img/icon/filled/gray/icon2_g.svg";
import dvcLogo from "asset/mobile_web/img/icon/filled/gray/icon3_g.svg";

const BottomNavWrap = styled.nav`
    background-color: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    // z-index: 5;
    ul {
        display: flex;
        align-items: center;
        a {
            padding: 20px 0;
            width: 33.3%;
            li {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                h3 {
                    font-size: 14px;
                    color: ${({ theme }) => theme.colors.gray6};
                    font-weight: 400;
                    margin-top: 10px;
                }
            }
        }
    }
`;

function BtmNav() {

    return (
        <BottomNavWrap>
            <ul>
                <Link to="/mobile/dsh-brd/dsh-brd">
                    <li>
                        <img src={dshbrdLogo} alt="대시보드로고" />
                        <h3>대시보드</h3>
                    </li>
                </Link>
                <Link to="/mobile/sal-hist/sal-hist">
                    <li>
                        <img src={salesLogo} alt="매출현황로고" />
                        <h3>매출현황</h3>
                    </li>
                </Link>
                <Link to="/mobile/dvc-mgt/dvc-mgt">
                    <li>
                        <img src={dvcLogo} alt="장치관리로고" />
                        <h3>장치관리</h3>
                    </li>
                </Link>
            </ul>
        </BottomNavWrap>
    )

}
export default BtmNav;