import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userNm: '',
    userSqno: '',
    accessToken: '',
  },
  reducers: {
    login: (state, action) => {
      const { userNm, userSqno, accessToken } = action.payload;
      state.userNm = userNm;
      state.userSqno = userSqno;
      state.accessToken = accessToken;
    },
    logout: (state) => { 
      state.userNm = '';
      state.userSqno = '';
      state.accessToken = '';
    },
  },
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
