import React, { useEffect, useState, useRef , useMemo} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const TimeBox = styled.h3`
    text-align: center;
    font-size: 14.5px;
    color: #3b3b3b;
    font-weight: bold;
    margin: 15px 0;
    font-family: "Noto Sans KR", sans-serif;
`;

export default function MainTimer() {
    const {t} = useTranslation();
    const [timer, setTimer] = useState("00:00:00");
    const week = ['SUN','MON','TUE','WED','TUR','FRI','SAT'];
    const currentTimer = () => {
        const date = new Date();
        const year = String(date.getFullYear());
        const month = String(date.getMonth()+1).padStart(2,"0");
        const day = String(date.getDate()).padStart(2,"0");
        const dayOfWeek = String(date.getDay());
        const hours = String(date.getHours()).padStart(2,"0");
        const minutes = String(date.getMinutes()).padStart(2,"0");
        const seconds = String(date.getSeconds()).padStart(2,"0");

        setTimer(` ${t("m_dashboardPage.nowTime")} : ${year}-${month}-${day}(${week[dayOfWeek]}) ${hours}:${minutes}:${seconds} `);
    }
    
    // 최초시작
    useEffect(() => {
        currentTimer();
    },[]);

    const startTimer = () => {
        
        setInterval(currentTimer, 1000);
    }
    startTimer();

    return (
        <TimeBox>
            {timer}
        </TimeBox>
    )
}
